.signInBG{
    min-height: 100vh;
    background: linear-gradient(190deg, rgba(32,32,32,1) 40%, rgb(67, 118, 167) 100%);
}
.signInLogo{
    width: clamp(135px, calc(12vw + 0.1rem), 500px);
    height: clamp(60px, calc(5vw + 0.1rem), 200px);
}
.signinContent h2 ,.signinContent span{
    font-size: clamp(24px, calc(2.2vw + 0.1rem), 100px);
    font-weight: 700;
}
.txtPrtLogin{
    display: flex;
    flex-direction: column;
    gap: clamp(14px, calc( 1.1vw + 0.3rem ) , 70px);
    padding-top: clamp(90px, calc(6vw + 0.5rem),200px)
}
.signinContent p{
    font-size: clamp(10px, calc( 0.9vw + 0.1rem), 60px);
    font-weight: 400;
}
.signinFormContent{
    display: flex;
    flex-direction: column;
    gap: clamp(24px, calc(2.9vw + 0.3rem),100px);
}
.signInForm{
    padding-top: clamp(48px, calc(5.5vw + 0.3rem),180px);
    padding-bottom: clamp(48px, calc(5.5vw + 0.3rem),180px);
}
.signinContent{
    padding-left: clamp(10px, calc( 0.5vw + 0.5rem), 60px);
}
.formLabelsAndInputs{
    display: flex;
    flex-direction: column;
    gap: clamp(16px, calc( 1vw + 0.3rem ) , 50px);
}
.loginBtn{
    padding: 8px 32px !important;
    font-size: clamp(10px, calc( 0.5vw + 0.5rem), 60px) !important;
}
.formElements h3{
    font-size: clamp(20px, calc(1.5vw + 0.5rem), 80px);
    font-weight: 700;
}

.formBGSignIn{
    background: linear-gradient(265deg, rgb(23, 37, 49) 20%, rgb(48, 73, 97) 100%);
}

@media only screen and (max-width: 676px){
    .signinContent h2 ,.signinContent span{
        line-height: 2.25rem;
        font-weight: 700;
    }
    .formElements h3{
        line-height: 1.875rem;
        font-weight: 700;
    }
    .formBGSignIn{
        background: linear-gradient(203deg, rgb(44, 71, 95) 20%, rgb(66, 125, 180) 100%);
    }
    .formElements p{
        line-height: 1.3125rem;
        font-weight: 400;
    }
}
.formLabelsAndInputs label {
    font-size: clamp(20px, calc(1vw + 0.1rem), 80px);
    font-weight: 600;
}
.formLabelsAndInputs .error  {
    font-size: clamp(10px, calc(0.7vw + 0.1rem), 80px);
}
.formLabelsAndInputs ::placeholder {
    font-size: clamp(12px, calc(0.8vw + 0.1rem), 80px);
}