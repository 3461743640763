section.getStarted{
    padding: 0 5%;
}

.getStarted-container{
    max-width: 90%;
}

.getStarted-content h3{
    font-family: Poppins;
    font-size: clamp(22px, calc(3.5vw + 0.1rem) ,140px);;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
} 
.getStarted-content p{
    font-family: Poppins;
    font-size:  clamp(10px, calc(0.9vw + 0.1rem), 120px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
}
.getStarted-content a{
    padding: clamp(10px, calc(0.5vw + 0.1rem), 70px) clamp(16px, calc(2vw + 0.1rem), 100px);
    background: var(--Blue300);
    border-radius: 5px;
    margin: clamp(10px, calc(1vw + 0.1rem), 60px) 0;
    display: inline-block;
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 150px);
    transition: all 0.3S;
}
.getStarted-content a:hover{
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}

.getStarted-img {
    width: 100%;
}

.getStarted-img img{
    width: 100%;
}



@media only screen and (max-width: 1024px) {
    section.getStarted{
        padding: 0;
    }
    .getStarted-content a{
        margin: 0.5rem 1rem 0.5rem 0;
    }
    .getStarted-container {
        max-width: 100%;
    }
}