.githubBG{
  background: linear-gradient(297deg, rgb(33, 49, 65 ) 67% ,#233647 100%);

}
.textAndGitHubImg{
    display: flex;
    flex-direction: column;
    gap: clamp(16px, calc(1vw + 0.1rem), 80px);
    padding-top:clamp(10px, calc( 0.6vw + 0.1rem), 60px) ;
    padding-bottom: clamp(10px, calc( 0.6vw + 0.1rem), 60px);
}
.benefitsWrapper{
    display: flex;
    flex-direction: column;
    gap: clamp(10px, calc( 1.8vw + 0.1rem), 800px);
    padding-top:clamp(0px, calc(7vw + 0.5rem),500px) ;
    padding-bottom: clamp(0px, calc(7vw + 0.5rem),500px);
}
.benefitsHeader{
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: clamp(10px, calc( 1.3vw + 0.1rem), 300px);
    padding-bottom: clamp(8px, calc(3vw + 0.1rem),300px);
}
.positionFlexCard{
    margin-top: clamp(10px, calc( 0.9vw + 0.1rem), 60px);
    margin-bottom: clamp(10px, calc( 0.9vw + 0.1rem), 60px);
}
.githubBG h4{
    font-size: clamp(14px, calc( 1.3vw + 0.1rem ) , 70px);
}
.howWeWork{
    padding-bottom: clamp(40px, calc(2.5vw + 0.1rem),120px);
}
.howWeWorkDetail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: clamp(16px, calc(1.3vw + 0.1rem), 900px);
}
.bgImg{
    background-image: url('../../assets/BG\ gradient.png');
    min-height: 100vh;
    background-position:center;
    background-size:cover ;
    background-repeat: no-repeat;
    padding-top: clamp(10px, calc( 1.2vw + 0.1rem), 300px);
    padding-bottom: clamp(10px, calc( 2vw + 0.1rem), 300px);
}
.careerForBorder{
    box-shadow: -10px 5px 100px 0 #00000093 !important;
}
.visibleInSmBorder{
    border-radius: 0.3rem;
}
.paragraphFont{
    display: flex;
    flex-direction: column;
    gap: clamp(8px, calc(1.2vw + 0.1rem), 80px);
    margin-bottom: clamp(56px, calc(7vw + 0.5rem),500px);
}
.positionsBorder::before{
    content: "";
    background-image: conic-gradient(transparent,#53acff 110deg ,#53acff 300deg,transparent 320deg );
    width: 250%;
    height: 250%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%) rotate(180deg);
    box-sizing: border-box;
    position: absolute;
    transition:all 0.5s
}
.githubImg{
    height: clamp(44px, calc(11vw + 0.1rem),500px);
    margin-bottom: 12px;
}
.emptyDivBorder::before{
    content: "";
    background-image: conic-gradient(transparent 240deg ,#53acff 300deg,transparent 360deg );
    width: 250%;
    height: 250%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%) rotate(0deg);
    box-sizing: border-box;
    position: absolute;
    transition:all 0.5s
  }
  .emptyDivBorder:hover::before ,.positionsBorder:hover::before ,.positionsBorderW:hover::before {
    transform:translate(-50%,-50%) rotate(360deg);
  }

  .joinTeamBG{
    border: 1px solid transparent;
    border-radius: 1.6rem;
    background-color: rgba(0, 0, 0, 0.44);
  }
  .forShadow{
    box-shadow:
    10px -7px 5px -10px rgba(0, 0, 0, 0.137) inset,
    10px 12px 9px -10px rgba(0, 0, 0, 0.288) inset,
    -10px 7px 7px -8px rgba(0, 0, 0, 0.267) inset,
    -10px -7px 7px -8px rgba(0, 0, 0, 0.363) inset;
    border-radius: 1.6rem;
    overflow: hidden;
    background-color: #202020;
    padding: clamp(16px, calc(2vw + 0.5rem), 500px);
    display: flex;
    position: relative;
  }

.headerRight h3{
    font-size:clamp(24px, calc(2.6vw + 0.1rem),140px);
    font-weight: 700;
  }
.headerRight p,.everySection p{
    font-size:clamp(14px, calc( 1.2vw + 0.1rem ) , 70px);
    font-weight: 400;
}
.everySection h5 , .movingCards h5{
    font-size: clamp(15px, calc( 1.2vw + 0.1rem ) , 70px);
    font-weight: 700;
}

.forAfterEffect h3{
    position: relative;
}
.forAfterEffect h3::after{
    content: "";
    position: absolute;
    background-color: #FFFFFF;
    width: clamp(56px, calc(42vw + 0.1rem),6128px);
    height: 2px;
    transform: translate(10px,0);
    top:25%;
}
.forFonts h3{
    font-size:clamp(20px, calc( 2.5vw + 0.1rem ) , 70px);
    font-weight: 700;
}
.forFonts p{
    font-size: clamp(12px, calc( 1.2vw + 0.1rem ) , 70px);
    font-weight: 400;
}
.middleCard{
    color: #53ACFF;
    background-color: #202020;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(10px, calc( 0.7vw + 0.1rem), 150px);
    padding-right: clamp(10px, calc( 0.7vw + 0.1rem), 150px);
    padding-left: clamp(10px, calc( 0.7vw + 0.1rem), 150px);
    padding-top: clamp(24px, calc(1.8vw + 0.1rem),90px);
    padding-bottom: clamp(24px, calc(1.8vw + 0.1rem),90px);
    border-radius: 0.9rem 0.9rem 0 0;
}
.moveMiddle{
    position: absolute;
    bottom: 0;
    left: 30%;
    transform: translate(12px, 20px);
    height: 100%;
}

.moveLast{
    position: absolute;
    bottom: -0.3%;
    right: 5%;
    transform: translateY(2rem);
}
.moveFirst{
    position: absolute;
    bottom: 0;
    left: 5%;
    transform: translateY(2rem);
}
.movingBorder{
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 0.9rem;
}
.githubBG h4{
    font-weight:600 ;
}
.visibleInSm{
    display: flex;
    background: linear-gradient(150deg,rgba(83, 172, 255, 0.2) 27% ,rgb(15, 23, 32) 60%);
}
.careerBtn{
    padding-top:  clamp(10px, calc( 0.5vw + 0.1rem), 60px);
    padding-bottom:  clamp(10px, calc( 0.6vw + 0.1rem), 60px);
    padding-right: clamp(20px, calc(1.2vw + 0.5rem), 70px);
    padding-left: clamp(20px, calc(1.2vw + 0.5rem), 70px);
    border-radius: 0.2rem !important;
    font-weight: 600;
    font-size: clamp(10px, calc( 0.7vw + 0.1rem), 60px);
}
.positionBtn{
    padding-top: clamp(4px, calc( 0.3vw + 0.1rem), 60px) !important;
    padding-bottom: clamp(4px, calc( 0.3vw + 0.1rem), 60px) !important;
    padding-right: clamp(10px, calc( 0.7vw + 0.1rem), 60px) !important;
    padding-left: clamp(10px, calc( 0.7vw + 0.1rem), 60px) !important;
    font-size:  clamp(10px, calc( 0.6vw + 0.1rem), 60px) !important;
    font-weight: 600;
    border-radius: 0.2rem !important;
}
.careerImgSize{
    height:clamp(50px, calc(9vw + 0.1rem), 300px);
    width: clamp(111px, calc(24vw + 0.1rem), 500px);
    padding-top:clamp(16px, calc(2.4vw + 0.1rem), 80px);
}
.positionsBorder{
    border-radius: 0.9rem 0.9rem 0 0;
    padding: 2px 2px 0;
    position:relative;
    overflow: hidden;
  }
.positionsBorderW{
    border-radius: 0.9rem 0.9rem 0 0;
    padding: 2px 2px 0;
    position:relative;
    overflow: hidden;
}
.positionsBorderW::before{
        content: "";
        background-image: conic-gradient(transparent 90deg ,#53acff 180deg,transparent 270deg );
        width: 250%;
        height: 250%;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%) rotate(180deg);
        box-sizing: border-box;
        position: absolute;
        transition:all 0.5s
}
.textAndGitHub{
    margin-top: clamp(24px, calc(3.2vw + 0.1rem),140px);
    margin-bottom: clamp(24px, calc(3.2vw + 0.1rem),140px);
}
.cardsFont{
    margin-right: clamp(20px, calc(1.8vw + 0.1rem),90px);
    margin-left: clamp(20px, calc(1.8vw + 0.1rem),90px);
}

@media only screen and (max-width: 676px){
    .bgImg{
        padding-top: 40px;
        padding-bottom: 40px;

    }
    .joinTeamBG {
        border-radius: 0.8rem;
    }
    .forShadow {
        border-radius: 0.8rem;
    }
    .benefitsWrapper{
        padding-top: 0;
        margin-top: 80px;
        margin-bottom: 80px;
        padding-right: 20px;
        padding-left: 20px;

    }
.headerRight h3{
    line-height: 2.25rem;
    font-weight: 700;
}
.headerRight p{
    line-height: 1.125rem;
    font-weight: 400;
}

.forFonts p{
    font-weight: 400;
}
.vectorSize{
    width: 1.56rem;
}

.everySection p{
    font-weight: 400;
}
.cardsFont h5{
    font-size: 0.625rem;
    font-weight: 700;
}
.forFonts h3{
    line-height: 1.5rem;
    font-weight: 700;
}
.paragraphFont{
    margin-bottom: 0;
}
.paragraphFont p{
    font-size: 0.625rem;
    line-height: 0.94rem;
    font-weight: 400;
}
.careerImgSize{
    width: 6.94rem;
    height: 3.125rem;
}
.forAfterEffect h3::after{
    display: none;
}
}