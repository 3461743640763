.private_equities_table {
    background: rgba(32, 32, 32, 1);
}

.private_equities_table .table_head {
    background: rgba(32, 32, 32, 1);
    box-shadow: 0px 0px 13.7px 3px rgba(0, 0, 0, 0.25);
    border-radius: 5px 5px 0 0;
    padding: 0 2rem;
}

.private_equities_table .table_head li {
    display: block;
    text-align: center;
    padding: clamp(10px, calc(0.6vw + 0.1rem), 60px) clamp(10px, calc(0.3vw + 0.1rem), 60px);
    font-size:  clamp(10px,  calc( 0.7vw + 0.1rem ), 60px);
    font-weight: 700;
    line-height: 24px;
    position: relative;
}

.private_equities_table .table_head li::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    height: 70%;
    width: 1px;
    background-color: #fff;
}

.private_equities_table .table_head li:last-child::after {
    content: unset;
}

.private_equities_table .table_body {
    padding: 2rem;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25) inset;
    border-radius: 0 0 5px 5px;
}

.private_equities_table .table_body ul {
    background: rgba(49, 49, 49, 1);
    border-radius: 5px;
    position: relative;
    z-index: 1;
    transition: all 0.3s;
}

.private_equities_table .table_body ul.open {
    background: #53acff;
}

.private_equities_table .table_body ul:hover {
    background: #53acff;
}

.private_equities_table .table_body ul li {
    padding: clamp(10px, calc(0.6vw + 0.1rem), 60px) clamp(10px, calc(0.3vw + 0.1rem), 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: clamp(10px, calc(0.9vw + 0.1rem), 60px);
    font-weight: 400;
    position: relative;
}

.private_equities_table .table_body ul li img{
    width: 50%;
}

.private_equities_table .table_body ul li::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    height: 70%;
    width: 1px;
    background-color: #fff;
}

.private_equities_table .table_body ul li:last-child::after {
    content: unset;
}


.collapse .collapse_body {
    background: rgba(24, 24, 24, 1);
    padding: clamp(14px,  calc( 1.6vw + 0.3rem ) , 90px);
    border-radius: 0 0 5px 5px;
    transform: translateY(-5px);
    display: none;
}

.collapse .collapse_body p {
    font-family: Poppins;
    font-size:  clamp(10px,  calc( 0.7vw + 0.1rem ), 60px);
    font-weight: 400;
    text-align: left;
    margin-bottom: 1rem;
}

.collapse .collapse_body>a {
    width: max-content;
    padding: 10px 2rem;
    font-size: clamp(10px, calc(0.7vw + 0.1rem), 60px);
    font-weight: 600;
    border-radius: 5px;
    background-color: #53ACFF;
    color: #fff;
    outline: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    margin: 1rem 0 0 auto;
    transition: all 0.3s;
}
.collapse .collapse_body>a:hover{
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}




@media only screen and (max-width: 1024px) {

    .private_equities_table .table_head {
        display: none;
    }

    .private_equities_table .table_body {
        padding: 0rem;
        box-shadow: none;
        border-radius: 0;
    }

    .private_equities_table .table_body ul {
        padding: 0.4rem;
    }


    .private_equities_table .table_body ul li {
        padding: 0.4rem;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        text-align: center;
    }

    .private_equities_table .table_body ul li svg {
        width: 7px;
    }
    .private_equities_table .table_body ul li img {
        width: 100%;
    }

    .private_equities_table .collapse .collapse_body {
        padding: 1rem;
    }

    .private_equities_table .collapse .collapse_body p {
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
    }
    .collapse .collapse_body > a {
        height: 26px;
        width: max-content;
        padding: 5px 1rem;
        font-size: 10px;
    }

}