section.hero {
    width: 100%;
    padding: 10% 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(22px, calc(1.5vw + 0.1rem), 202px);
    background-image: url("../../../assets/hero-bg-shadow.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

section.hero h1 {
    font-size: var(--50px);
    font-weight: 700;
    text-align: center;
    line-height: normal;
}

section.hero img {
    width: 100%;
}

section.hero h1>span {
    background-color: var(--Blue300);
    border-radius: 5px;
    padding: 0.2rem 1rem;
    text-wrap: nowrap;
    font-weight: 700;
}

section.hero p {
    font-size: var(--16px);
    margin: 1rem 1.5rem;
    text-align: center;
    max-width: clamp(400px, calc(39vw + 1rem), 1500px);
    margin: auto;
}

section.hero a {
    outline: none;
    color: #fff;
    border: none;
    padding: 0.4rem 2rem 0.5rem;
    background-color: var(--Blue300);
    border-radius: 4px;
    font-size: clamp(12px, calc(0.9vw + 0.1rem), 160px);
    font-weight: 500;
    margin: auto;
    transition: all 0.3s;
}

section.hero a:hover {
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}

@media only screen and (max-width: 1024px) {
    section.hero {
        width: 100%;
        padding: 10vh 10%;
        gap: 0.6rem;
        align-items: start;
    }

    section.hero h1 {
        margin-bottom: 1rem;
        text-align: left;
    }

    section.hero p {
        display: none;
        text-align: left;
        max-width: clamp(300px, calc(49vw + 1rem), 1500px);
        margin: 0 auto 0 0;
    }

    section.hero a {
        padding: 0.4rem 1.5rem 0.4rem;
        margin: 0 auto 0 0;
    }
}