.training_navBar {
    width: 100%;
}

.training_navBar ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8rem;
}

.training_navBar ul li a {
    font-size: clamp(10px, calc(0.4vw + 0.5rem), 60px);
    font-weight: 400;
    text-align: left;
    transition: all 0.3s;

}

.supTitle {
    padding: 0.3rem 0.7rem;
    width: max-content;
    border-radius: 10px;
    background-color: var(--Blue300);
    font-size: clamp(12px, calc(0.8vw + 0.5rem), 70px);
    font-weight: 700;
    text-align: left;
}

.searchInput {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
}

.searchInput input {
    width: 100%;
    background-color: #313131;
    border: 1px solid #313131;
    border-radius: 5px;
    color: #ffff;
    font-size: clamp(10px, calc(0.4vw + 0.5rem), 60px);
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    padding: 8px 20px;
    outline: none;
    transition: all 0.3s;
}

.searchInput input::placeholder {
    color: #ffff;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
}

.searchInput input:focus {
    border: 1px solid #53ACFF;
}

.searchInput img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
}

.st_suite_wiki {
    padding: 3rem 1.5rem;
    background: rgba(24, 24, 24, 1);
    border-radius: 8px;
    position: fixed;
    right: 1%;
}

.st_suite_wiki h6 {
    font-family: Poppins;
    font-size: var(--16px);
    font-weight: 800;
    text-align: center;
    margin-bottom: var(--sy-19px);
}

.st_suite_wiki ul {
    margin-bottom: 3rem;
    list-style: outside;
    padding-left: 1rem;
}

.st_suite_wiki ul li {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.4vw + 0.4rem), 60px);
    font-weight: 400;
    text-align: left;
}

.st_suite_wiki ul li>a {
    color: var(--Blue300);
}

.trainings>a {
    display: block;
}

.training_card {
    padding: clamp(12px, calc(1vw + 0.5rem), 70px) clamp(12px, calc(1.8vw + 0.5rem), 70px) clamp(12px, calc(1vw + 0.5rem), 70px) clamp(12px, calc(0.8vw + 0.5rem), 70px);
    background: rgba(32, 32, 32, 1);
    border-radius: 15px;
    position: relative;
    box-shadow: 10px 12px 12.199999809265137px 0px rgba(0, 0, 0, 0.25) inset;
}

.training_card h4 {
    font-family: Poppins;
    font-size: clamp(12px, calc(0.6vw + 0.5rem), 140px);
    font-weight: 600;
    text-align: left;
}

.training_card span {
    font-family: Poppins;
    font-weight: 400;
    text-align: left;
    color: rgba(151, 151, 151, 1);
}

.training_card ul {
    display: flex;
    align-items: center;
    width: 100%;

}
.border_animate:hover::before {
    transform: translate(-50%,-50%) rotate(180deg);
} 
.training_card ul li {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.4vw + 0.5rem), 160px);
    font-weight: 500;
    text-align: left;
    display: flex;
    align-items: center;
    gap: var(--13px);
    width: 20%;
}

.training_card ul li svg {
    width: clamp(10px, calc(0.45vw + 0.5rem), 60px);
}

.border_animate {
    position: relative;
    padding: 1px;
    border-radius: 15px;
    z-index: 0;
}

.border_animate::before {
    content: "";
    background-image: conic-gradient(#ffffff4b 0deg, #ffffff4b 260deg, #fff 270deg 300deg, #ffffff4b 360deg);
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    border-radius: 15px;
    transition: all 0.7s;
}

.advanced_badge {
    position: absolute;
    top: -10px;
    right: -18px;
    width: 100px;
}



.most_liked_trainings h5 {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.6vw + 0.5rem), 60px);
    font-weight: 700;
    text-align: left;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card {
    padding: 2rem;
    background: rgba(32, 32, 32, 1);
    box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.25) inset;
    border-radius: 15px;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card h6 {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.4vw + 0.5rem), 60px);
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.most_liked_trainings .most_liked_training_card li {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.4vw + 0.4rem), 60px);
    font-weight: 500;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.most_liked_trainings .most_liked_training_card li svg {
    width: clamp(10px, calc(0.5vw + 0.5rem), 90px);
}


@media only screen and (max-width:1024px) {

    .supTitle {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
    }

    .st_suite_wiki {
        display: none;
    }

    .training_card {
        padding: 1rem 1rem 1rem 0.5rem;
    }

    .training_card h4 {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        text-align: left;
    }

    .training_card ul li {
        font-size: 10.45px;
        font-weight: 500;
        line-height: 15.68px;
        text-align: left;
    }

    .training_navBar ul {
        position: absolute;
        top: 0;
        left: -16px;
        width: 100%;
        background: #202020;
        width: calc(100% - 1rem);
        z-index: 9999;
        padding: 1rem;
        gap: 1rem;
    }
}