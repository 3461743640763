.most_liked_trainings h5 {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 700;
    line-height: 28.5px;
    text-align: left;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card {
    padding: 2rem;
    background: rgba(32, 32, 32, 1);
    box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.25) inset;
    border-radius: 15px;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card h6 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.most_liked_trainings .most_liked_training_card li {
    font-family: Poppins;
    font-size: 13.42px;
    font-weight: 500;
    line-height: 20.14px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}



.single_course .course_card_info h4 {
    width: 70%;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.32px;
    text-align: left;
}

.single_course .course_card_info p {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
    color: rgba(151, 151, 151, 1);
    width: 70%;
}

.single_course .course_card_info span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: left;
    text-wrap: nowrap;
    color: rgba(151, 151, 151, 1);
}

.single_course .course_card_info .enrolled_date {
    padding: 0.5rem;
    border-radius: 5px;
    background-color: var(--Blue300);
    text-align: center;
}

.single_course .course_card_info .enrolled_date p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-wrap: nowrap;
    color: #fff;
}

.single_course .course_card_info .enrolled_date span {
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
    text-wrap: nowrap;
    color: #fff;
}

.single_course .course_card_info span.completed {
    padding: 0.3rem 0.6rem;
    border-radius: 5px;
    background-color: var(--Blue300);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
}

.single_course .course_view {
    border: 1px solid #fff;
    border-radius: 10px;
}

.single_course .course_overview {
    background: #202020;
    padding: 1.5rem;
    box-shadow: 5px 7px 22.199999809265137px 0px #00000040 inset;
    border-radius: 10px;
}

.single_course .course_overview p {
    font-size: 13px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: left;
    margin-bottom: 0.5rem;
}

.single_course .course_overview span {
    font-size: 15.96px;
    font-weight: 500;
    line-height: 20.93px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.single_course .course_description h6, .single_course .course_details h6 {
    font-size: 19px;
    font-weight: 700;
    line-height: 25.5px;
    text-align: left;
    margin-bottom: 1rem;
}

.single_course .course_description p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 1.5rem;
}

.single_course .course_details_card {
    padding: 3rem 2rem;
    background: #181818;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.single_course .course_details_card ul {
    width: 40%;
}

.single_course .course_details_card li {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding: 0.7rem 1rem 0.7rem 2rem;
    border-left: 1px solid #fff;
}

.single_course .course_details_card li.completed a{
    color: #5D5D5D;
}

.single_course .course_details_card li.recent a {
    font-weight: 700;
    color: #53ACFF;
}
.course_actions_btn{
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
}
.course_actions_btn a{
    padding: 0.7rem 2rem;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    color: #fff;
    border-radius: 5px;
    transition: all 0.3s;
}

.course_actions_btn .restartCourse{
    border: 1px solid #fff;
    background-color: transparent;
}
.course_actions_btn .restartCourse:hover{
    background-color: #53ACFF;
    border: 1px solid #53ACFF;
}

.course_actions_btn .continueCourse , .course_actions_btn .startCourse{
    background-color: #53ACFF;
}

.course_actions_btn .continueCourse:hover , .course_actions_btn .startCourse:hover{
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}






@media only screen and (max-width:1024px) {

.single_course .course_card_info h4 {
    font-size: 13px;
    font-weight: 700;
    line-height: 19.5px;
}
.single_course .course_card_info .enrolled_date {
    padding: 0.3rem 0.5rem 0.1rem;
}
.single_course .course_card_info .enrolled_date p {
    font-size: 11px;
    font-weight: 700;
    line-height: 12px;
}
.single_course .course_card_info .enrolled_date span {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
}

.single_course .course_card_info span {
    font-size: 10px;
    font-weight: 400;
    line-height: 14.32px;
}

.single_course .course_card_info span.completed {
    padding: 0.3rem 0.4rem;
    font-size: 11px;
    line-height: 16.5px;
}

.single_course .course_overview {
    background: #202020;
    padding: 0.9rem;
    display: flex;
    justify-content: space-between;
    
}

.single_course .course_overview p {
    font-size: 11px;
    font-weight: 700;
    line-height: 16.5px;
    text-align: center;
}

.single_course .course_overview span {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    justify-content: center;
}

.single_course .course_overview span svg{
    width: 15px;
}

.single_course .course_description h6, .single_course .course_details h6 {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
}

.single_course .course_description p {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
}
.single_course .course_details_card {
    flex-direction: column;
    padding: 2rem 1.5rem;
}
.single_course .course_details_card ul {
    width: 100%;
}

.single_course .course_details_card li {
    padding: 0.7rem 0.7rem 0.7rem 1.5rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
}

.course_actions_btn a {
    padding: 0.6rem 1.5rem;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
}


}