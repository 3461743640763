.positionsList{
    list-style-type: disc;
    padding-left: 1.5rem;
}
.positionsList li{
    font-size:clamp(10px, calc(0.6vw + 0.5rem), 60px);
    font-weight: 400;
}
.headOfRightDiv{
    font-size:clamp(24px, calc(1.8vw + 0.5rem),90px) ;
    font-weight:600 ;
}
.txtAlone p,.txtAlone span{
    font-size: clamp(10px, calc( 0.5vw + 0.5rem), 60px);
    font-weight:400 ;
}
.headAndUl h3{
    font-size: clamp(12px, calc( 1vw + 0.5rem ) , 70px);
    font-weight: 700;
}

.everyPosition{
    background-color: #202020;
}
.positionRightPart h3{
    font-size:clamp(24px, calc(2.1vw + 0.5rem), 80px);
    font-family: 700;
}
.headOfPage h1{
    font-size: clamp(24px, calc(3.6vw + 0.5rem),140px);
    font-weight: 700;
}
.headOfPage p{
    font-size: clamp(12px, calc( 1vw + 0.5rem ) , 70px);
    font-weight: 400;
}
.fileBtn{
    background-color: transparent;
    border: 1px solid white ;
    padding: 7px 4rem;
    border-radius: 0.2rem;
    cursor: pointer;
}
.applyBtn{
    padding: 0.6rem 3rem !important;
    font-size: clamp(10px, calc( 0.5vw + 0.5rem), 60px);
    font-weight: 600;
    text-transform: none !important;

}

.MuiAccordionSummary-button  span{
    display: none;
}

.MuiAccordionSummary-root button {
    color: #FFFFFF;
    padding: 1.5rem 8rem ;
    border-radius: 0.6rem;
}
.MuiAccordion-variantPlain{
    padding: 0 !important;
    margin: 0 !important;
}
.bgPositionCard{
    background-color:#202020 ;
}
.MuiAccordionSummary-root button:hover{
    background-color: transparent !important;
    color: #FFFFFF !important;
}
.grayPositionBorder::before{
    content: "";
    background-image: conic-gradient(transparent 0deg ,#ffff 180deg,transparent 280deg );
    width: 250%;
    height: 250%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%) rotate(180deg);
    box-sizing: border-box;
    position: absolute;
    transition:all 0.5s
}
.selectFile label{
    font-size: 0.875rem ;
    font-weight:600 ;
}
.accordionDetails{
    padding: 0 3rem;
}
.MuiAccordionSummary-root {
    transition: 0.5s;
}


@media only screen and (max-width: 676px) {
    .headOfPage h1,.headOfRightDiv,.positionRightPart h3{
        line-height: 2.25rem;
        font-weight: 700;

    }
    .headOfPage p{
        line-height: 1.125rem;
        font-weight: 400;
    }

    .txtAlone p,.txtAlone span,.positionsList li{

        line-height: 0.94rem;
        font-weight: 400;
    }
    .headAndUl h3{

        line-height: 21px;
        font-weight: 700;
    }
    .selectFile label,.applyBtn{

        line-height:0.9rem ;
        font-weight:600 ;
    }
    .accordionDetails{
        padding: 0;
    }
    .MuiAccordionSummary-root button{
        padding: 0.8rem 2rem;
    }
    .bottomBorderInSm{
        border-bottom:1px solid #979797 ;
    }
    .fileBtn{
        padding: 7px 3rem;
    }
    .applyBtn{
        padding: 0.2rem 1.5rem !important;
        text-transform: none !important;
    }   
}