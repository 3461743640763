/* alerts section style  */
section.alerts {
    padding: 10% 10% 0%;
    border-top: 1px solid #5D5D5D;
    position: relative;
}

/* title of the section style  */
.section-title {
    position: absolute;
    top: 0;
    left: 10%;
    padding: 0 2rem;
    transform: translateY(-50%);
    font-size: clamp(24px , calc(3vw + 0.1rem) , 1600px);
    line-height: normal;
    color: var(--Blue300);
    font-weight: 800;
    background-color: #202020;
    text-shadow: 0px 10px 10px #000;
}
section.alerts .alerts-container{
    gap: clamp(16px , calc(1vw + 0.1rem) , 1600px);
}


/* card in alert section style and add some animation ^_^ */
.alert-card {
  height: clamp(180px,  calc( 16vw + 0.1rem ), 1400px);
    padding: clamp(2rem,  calc( 3vw + 0.1rem ), 1400px);
    transition: all 0.3s;
    backdrop-filter: blur(30px);
    background: linear-gradient(90deg, rgba(83, 172, 255, 0.1) 0%, rgba(35, 53, 70, 0.1) 100%);
    border-radius: 35.74px;
    position: relative;
    border: 1px solid #5252527a;
    box-shadow: 1px 0px 5px 0px var(--Blue300);
}

/* card in alert section style when hover */

.alert-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 10px 26px -7px #000;
}


.alert-card .content {
    width: max-content;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s;
}

.alert-card:hover .content {
    opacity: 1;
}

.alert-card .content p {
    font-size:  clamp(10px, calc(0.7vw + 0.1rem), 360px);
    text-align: center;
}

.animat-text {
    width: 100%;
    height: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.alert-card .animat-text img:nth-child(1) {
    width: clamp(100px, calc(11vw + 0.1rem), 1200px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s;
}

.alert-card:hover .animat-text img:nth-child(1) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
    opacity: 0;
}

.alert-card .animat-text img:nth-child(2) {
  width: clamp(100px, calc(8vw + 0.1rem), 1200px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;
}

.alert-card .animat-text img:nth-child(3) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: clamp(100px, calc(8vw + 0.1rem), 1200px);
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;
}


.alert-card:hover .animat-text img:nth-child(2) {
    top: 0;
    left: 0;
    transform: none;
    opacity: 1;
}

.alert-card:hover .animat-text img:nth-child(3) {
  top: unset;
  left: unset;
    bottom: 0;
    right: 0;
    transform: none;
    opacity: 1;
}



/* 
.alert-card .animat-text.filter img:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 137px;
    width: 165px;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;

}

.alert-card .animat-text.filter img:nth-child(3) {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;
    left: 162px;
    width: 165px;
}

.alert-card:hover .animat-text.filter img:nth-child(2) {
    transform: translate(-120%, -140%);
    opacity: 1;
}

.alert-card:hover .animat-text.filter img:nth-child(3) {
    transform: translate(20%, 30%);
    opacity: 1;
}



.alert-card .animat-text.special img:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 180px;
    width: 190px;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;

}

.alert-card .animat-text.special img:nth-child(3) {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;
    left: 180px;
    width: 190px;
}

.alert-card:hover .animat-text.special img:nth-child(2) {
    transform: translate(-130%, -140%);
    opacity: 1;
}

.alert-card:hover .animat-text.special img:nth-child(3) {
    transform: translate(0%, 30%);
    opacity: 1;
}




.alert-card .animat-text.scanner img:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 110px;
    width: 218px;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;

}

.alert-card .animat-text.scanner img:nth-child(3) {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;
    left: 190px;
    width: 222px;
}

.alert-card:hover .animat-text.scanner img:nth-child(2) {
    transform: translate(-85%, -140%);
    opacity: 1;
}

.alert-card:hover .animat-text.scanner img:nth-child(3) {
    transform: translate(-15%, 35%);
    opacity: 1;
} */


/* end of card in alert section style */





/* responsive mobile view  */

@media only screen and (max-width: 767px) {

    section.alerts {
        padding: 15% 0% 0;
    }

    .alert-card .content p {
        font-size: 11px;
    }

    .alert-card .animat-text.realtime img:nth-child(1) {
        width: 130px;
    }
    .alert-card .animat-text.realtime img:nth-child(2) {
        width: 97px;
    }

    .alert-card .animat-text.realtime img:nth-child(3) {
        width: 97px;
    }

    /* .alert-card:hover .animat-text.realtime img:nth-child(2) {
        transform: translate(-125%, -125%);
    }

    .alert-card:hover .animat-text.realtime img:nth-child(3) {
        transform: translate(25%, 40%);
    } */

    .alert-card .animat-text.filter img:nth-child(1) {
        width: 95px;
    }
    .alert-card .animat-text.filter img:nth-child(2) {
        width: 85px;
    }

    .alert-card .animat-text.filter img:nth-child(3) {
        width: 85px;
    }

    /* .alert-card:hover .animat-text.filter img:nth-child(2) {
        transform: translate(-140%, -110%);
    }

    .alert-card:hover .animat-text.filter img:nth-child(3) {
        transform: translate(50%, 10%);
    } */

    .alert-card .animat-text.special img:nth-child(1) {
        width: 115px;
    }

    .alert-card .animat-text.special img:nth-child(2) {
        width: 95px;
    }

    .alert-card .animat-text.special img:nth-child(3) {
        width: 90px;
    }

    /* .alert-card:hover .animat-text.special img:nth-child(2) {
        transform: translate(-130%, -130%);
    }

    .alert-card:hover .animat-text.special img:nth-child(3) {
        transform: translate(30%, 30%);
    } */
    .alert-card .animat-text.scanner img:nth-child(1) {
        width: 135px;
    }
    .alert-card .animat-text.scanner img:nth-child(2) {
        width: 100px;
    }

    .alert-card .animat-text.scanner img:nth-child(3) {
        width: 97px;
    }

    /* .alert-card:hover .animat-text.scanner img:nth-child(2) {
        transform: translate(-115%, -140%);
    }

    .alert-card:hover .animat-text.scanner img:nth-child(3) {
        transform: translate(20%, 40%);
    } */
}


@media only screen and (min-width: 2224px) {

    .section-title {
        position: absolute;
        top: 0;
        left: 10%;
        padding: 0 2rem;
        transform: translateY(-50%);
    }  
}