section.FAQ {
    padding: 0% 0% 10%;
}

.FAQ-container {
    max-width: 80%;
}

.FAQ-container h4 {
    font-family: Poppins;
    font-size: clamp(18px, calc(2.5vw + 0.5rem), 300px);
    font-weight: 700;
    text-align: left;
    margin-bottom: clamp(18px, calc(2.5vw + 0.5rem), 300px);
}

.FAQ-container .collapse-item {
    margin-bottom:  clamp(16px, calc(1.5vw + 0.1rem), 300px);
}
.FAQ-container .collapse-item .collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 21px;
    box-shadow: 0px 18.83098602294922px 29.591548919677734px 0px #00000040;
    cursor: pointer;
    background: #2F2F2F;
    position: relative;
    z-index: 1;
    padding: clamp(14px, calc(1.5vw + 0.1rem), 1000px);
}

.FAQ-container .collapse-item .collapse-header p {
    font-family: Poppins;
    font-size: clamp(12px, calc(0.8vw + 0.5rem), 70px);
    font-weight: 400;
    letter-spacing: 0em;
}

.FAQ-container .collapse-item .collapse-header svg {
    width: clamp(12px, calc(0.8vw + 0.5rem), 70px);
    height: clamp(12px, calc(0.8vw + 0.7rem), 70px);
    transform: rotateX(180deg);
    opacity: 0.6;
    transition: all 0.3s;
}

.FAQ-container .collapse-item .collapse-header.open svg {
    transform: rotateX(0deg);
    opacity: 1;
}

.FAQ-container .collapse-item .collapse-body {
    width: 100%;
    background-color: var(--Blue300);
    transform: translateY(-22px);
    background: #2F2F2F;
    border-radius: 0 0 21px 21px;
    padding: clamp(24px, calc( 2vw + 0.1rem ) , 150px) clamp(24px, calc( 1.5vw + 0.1rem ) , 150px) clamp(24px, calc( 1.5vw + 0.1rem ) , 150px);
    display: none;
}

.FAQ-container .collapse-item .collapse-body p {
    font-family: Poppins;
    font-size: clamp(12px, calc(0.9vw + 0.1rem), 70px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;

}

@media only screen and (max-width: 1024px) {

    section.FAQ {
        padding: 0% 5% 10%;
    }

    .FAQ-container {
        max-width: 100%;
    }

    .FAQ-container h4 {
        font-family: Poppins;
        font-size: 23px;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 1.5rem;
    }

    .FAQ-container .collapse-item .collapse-header {
        padding: 1rem 1.5rem;
        border-radius: 15px;
    }

    .FAQ-container .collapse-item .collapse-header p {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
    }

    .FAQ-container .collapse-item .collapse-header svg {
        width: 11.63px;
        height: 13.67px;
    }

    .FAQ-container .collapse-item .collapse-body {
        padding: 2.5rem 1.5rem 1.5rem 2rem;
    }

    .FAQ-container .collapse-item .collapse-body p {
        font-size: 10px;
        line-height: 16px;
    }

}