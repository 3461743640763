.quiz-wrapper h4 {
  font-family: Poppins;
  font-size: clamp(12px, calc(1.5vw + 0.5rem), 90px);
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  width: 70%;
  margin-bottom: 2rem;
}

.quiz-wrapper .gradient-border {
  border-radius: 9px;
}

.quiz-view {
  background: #00000040;
  border-radius: 9px;
  padding: 5rem 5rem;
  box-shadow: 4px 4px 10px 0px #00000040 inset,
    -4px -4px 10px 0px #00000040 inset;
}

.quiz-view img {
  width: 100%;
}

.quiz-questions h6 {
  font-family: Poppins;
  font-size: clamp(12px, calc(0.8vw + 0.5rem), 70px);
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 1.5rem;
}

.quizzes-wrapper .quiz-answers label {
  background: rgba(32, 32, 32, 1);
  border-radius: 5px;
  color: #fff;
  position: relative;
  width: 100%;
  padding: 0.7rem 1rem;
  transition: all 0.3s;
  font-family: Poppins;
  font-size: clamp(12px, calc(1.4vw + 0.5rem), 80px);
  font-weight: 700;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.25) inset;
  cursor: pointer;
}

.quizzes-wrapper .quiz-answers input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
}

.quizzes-wrapper .quiz-answers label:has(> input[type="radio"]:checked) {
  background: linear-gradient(90deg, #53acff 0%, #326799 100%);
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.25);
}

.quiz-score-sheet .quiz-score-sheet-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.quiz-score-sheet .quiz-score-sheet-card.my-score {
  background-color: var(--Blue300);
}

.quiz-score-sheet .quiz-score-sheet-card p {
  font-family: Poppins;
  font-size: clamp(10px, calc(0.8vw + 0.5rem), 70px);
  font-weight: 400;
  text-align: left;
}

.quiz-score-sheet .quiz-score-sheet-card span {
  width: 100%;
  text-align: center;
  font-size: clamp(10px, calc(2vw + 0.5rem), 80px);
  font-weight: 700;
  padding: 0.7rem 2rem;
  background: rgb(33 46 59);
  box-shadow: 0px 18.44875717163086px 28.990909576416016px 0px
    rgba(0, 0, 0, 0.25);
  border-radius: 9px;
}

.quiz-score-sheet button {
  font-family: Poppins;
  font-size: clamp(12px, calc(0.8vw + 0.5rem), 70px);
  font-weight: 700;
  text-align: center;
  background: rgba(126, 126, 126, 1);
  outline: none;
  border: none;
  border-radius: 5px;
}

.quiz-score-sheet button.try-again {
  letter-spacing: 0em;
  padding: 10px 38px 10px 38px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  font-size: clamp(10px, calc(0.4vw + 0.5rem), 60px);
  border-radius: 5px;
  margin-top: 2rem;
  transition: all 0.3s;
}

.quiz-score-sheet button.try-again:hover {
  background: var(--Blue300);
  border: 1px solid var(--Blue300);
}

.more-quizzes {
  padding: 5% 0;
}

.more-quizzes h6 {
  font-family: Poppins;
  font-size: clamp(12px, calc(2.3vw + 0.5rem), 80px);
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 2rem;
}

.more-quizzes .gradient-border {
  border-radius: 15px;
}

.add-email-modal .modal-body {
  background: rgba(32, 32, 32, 1);
}

.add-email-modal h6 {
  font-family: Poppins;
  font-size: clamp(14px, calc(1.4vw + 0.5rem), 90px);
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
}

.add-email-modal .line {
  width: 60%;
  height: 1px;
  margin: 2rem auto;
  background: rgba(93, 93, 93, 1);
}

.add-email-modal p {
  font-family: Poppins;
  font-size: clamp(10px, calc(0.6vw + 0.5rem), 60px);
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.add-email-modal input {
  border-radius: 5px;
  background: #ffffff38;
  font-family: Poppins;
  font-size: clamp(10px, calc(0.4vw + 0.5rem), 60px);
  font-weight: 400;
  letter-spacing: 0em;
  padding: 0.5rem 1rem;
  outline: 0;
  border: 0;
  box-shadow: none;
  margin: 1.5rem 0 0.5rem;
}

.add-email-modal span {
  font-family: Poppins;
  font-size: clamp(10px, calc(0.35vw + 0.5rem), 60px);
  font-weight: 400;
  letter-spacing: 0em;
  color: rgba(151, 151, 151, 1);
  width: 70%;
  text-align: left;
}

.add-email-modal button {
  letter-spacing: 0em;
  padding: 10px 38px 10px 38px;
  background: var(--Blue300);
  font-size: clamp(10px, calc(0.4vw + 0.5rem), 60px);
  border-radius: 5px;
  color: #fff;
  margin-top: 2rem;
  outline: none;
  border: none;
}

.quiz-results-title button {
  font-family: Poppins;
  font-size: clamp(12px, calc(0.8vw + 0.5rem), 70px);
  font-weight: 700;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: transparent;
  outline: none;
  padding: 0.8rem 2rem;
  align-items: center;
  justify-content: center;
}

.quiz-score-sheet-results {
  padding: 2rem;
  background: linear-gradient(180deg, #53acff 0%, #326799 80%);
  box-shadow: 6px 9px 15px 0px rgba(255, 255, 255, 0.25) inset;
}

.quiz-score-sheet-results {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  border-radius: 13px;
}

.quiz-score-sheet-results .line {
  width: 4px;
  border-radius: 4px;
  background-color: #fff;
}

.quiz-score-sheet-results p {
  font-family: Poppins;
  font-size: clamp(12px, calc(1vw + 0.5rem), 70px);
  font-weight: 700;
  text-align: left;
}

.quiz-score-sheet-results span {
  font-family: Poppins;
  font-size: clamp(16px, calc(2.8vw + 0.5rem), 140px);
  font-weight: 700;
  text-align: left;
}

.get-better-card h5 {
  font-family: Poppins;
  font-size: clamp(22px, calc(2.8vw + 0.5rem), 140px);
  font-weight: 700;
  text-align: left;
}

.get-better-card .quiz-card .hot-padge-dark {
  width: 120px;
}

.quiz-question-view {
  min-height: 20rem;
  background: linear-gradient(
    118.78deg,
    rgba(83, 172, 255, 0.27) 0%,
    rgba(217, 217, 217, 0) 159.37%
  );
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.quiz-question-view h5 {
  font-family: Poppins;
  font-size: clamp(14px, calc(2.2vw + 0.5rem), 90px);
  font-weight: 700;

  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .quiz-wrapper h4 {
    font-weight: 700;
    width: 100%;
  }

  .quiz-view {
    background: rgba(0, 0, 0, 0.25);
    border-radius: var(--15px);
    padding: 1.5rem;
  }

  .quiz-score-sheet {
    align-items: end;
  }

  .quiz-score-sheet .quiz-score-sheet-card p {
    font-weight: 400;
    line-height: 10px;
  }

  .quiz-score-sheet .quiz-score-sheet-card span {
    width: 100%;
    padding: 0.3rem 1rem;
    background: rgb(33 46 59);
    font-size: 11px;
    font-weight: 700;
    line-height: 16.5px;
    border-radius: 2px;
  }

  .quiz-score-sheet button {
    font-size: 7.95px;
    font-weight: 700;
    line-height: 10.93px;
    height: 26px;
  }

  .quizzes-wrapper .quiz-answers label {
    width: 100%;
    height: 34px;
    font-size: 9.63px;
    font-weight: 700;
    line-height: 14.44px;
  }

  .add-email-modal h6 {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
  }

  .add-email-modal p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .add-email-modal input {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0.5rem 0 0.5rem;
  }

  .add-email-modal span {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 400;
    line-height: 12px;
    color: rgba(151, 151, 151, 1);
    width: 100%;
  }

  .add-email-modal button {
    padding: 7px 26px;
    font-size: 12px;
    border-radius: 5px;
    color: #fff;
    margin-top: 0.5rem;
  }

  .add-email-modal .line {
    width: 80%;
    margin: 1rem auto;
    border: none;
  }

  .quiz-score-sheet button.try-again {
    width: 25%;
    font-size: 7.95px;
    font-weight: 700;
    line-height: 10.93px;
    height: 26px;
    padding: 0.5rem;
    text-align: center;
    border-radius: 3px;
  }

  .quiz-score-sheet-results p {
    font-size: 9.57px;
    font-weight: 700;
    line-height: 14.36px;
  }

  .quiz-score-sheet-results span {
    font-size: 13.67px;
    font-weight: 700;
    line-height: 15.51px;
  }

  .quiz-score-sheet-results .line {
    width: 2px;
  }

  .get-better-card {
    padding: 1.5rem 1rem;
  }

  .get-better-card h5 {
    font-size: 15.12px;
    font-weight: 700;
    line-height: 22.68px;
  }

  .get-better-card .quiz-card .hot-padge-dark {
    width: 80px;
    top: -8px;
    right: -16px;
  }

  .get-better-card .quiz-card.horizontal-card {
    height: 180px;
  }

  .get-better-card .quiz-card.horizontal-card .card-body {
    padding: 1rem 0.5rem;
  }

  .get-better-card .quiz-card.horizontal-card .card-body .highlights span {
    margin-bottom: 0.3rem;
  }
}
