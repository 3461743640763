.training_navBar {
    width: 100%;
}

.training_navBar ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8rem;
}

.training_navBar ul li a {
    font-size: 13px;
    font-weight: 400;
    line-height: 24.32px;
    text-align: left;

}

.supTitle {
    padding: 0.3rem 0.7rem;
    width: max-content;
    border-radius: 10px;
    background-color: var(--Blue300);
    font-size: 22px;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
}

.searchInput {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
}

.searchInput input {
    width: 100%;
    height: 40px;
    background-color: #313131;
    border: 1px solid #313131;
    border-radius: 5px;
    color: #ffff;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    padding: 10px 20px;
    outline: none;
    transition: all 0.3s;
}

.searchInput input::placeholder {
    color: #ffff;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
}

.searchInput input:focus {
    border: 1px solid #53ACFF;
}

.searchInput img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
}

.st_suite_wiki {
    padding: 3rem 1.5rem;
    background: rgba(24, 24, 24, 1);
    border-radius: 8px;
    position: fixed;
    right: 1%;
}

.st_suite_wiki h6 {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 800;
    text-align: center;
    margin-bottom: var(--sy-19px);
}

.st_suite_wiki ul {
    margin-bottom: 3rem;
    list-style: outside;
    padding-left: 1rem;
}

.st_suite_wiki ul li {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.4rem ), 60px);
    font-weight: 400;
    text-align: left;
}

.st_suite_wiki ul li>a {
    color: var(--Blue300);
}


.most_liked_trainings h5 {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 700;
    line-height: 28.5px;
    text-align: left;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card {
    padding: 2rem;
    background: rgba(32, 32, 32, 1);
    box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.25) inset;
    border-radius: 15px;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card h6 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.most_liked_trainings .most_liked_training_card li {
    font-family: Poppins;
    font-size: 13.42px;
    font-weight: 500;
    line-height: 20.14px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}



@media only screen and (max-width:1024px) {

    .supTitle{
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
    }
    .st_suite_wiki {
        display: none;
    }
    
}