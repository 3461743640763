.choose_transfer_method_card {
    padding: clamp(10px,  calc( 3vw + 0.1rem ), 80px);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25) inset;
    border-radius: 5px;
}

.choose_transfer_method_card .card_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.choose_transfer_method_card .card_header h4 {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 1.8vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;
}

.choose_transfer_method_card .card_header .complete_icon {
    width: clamp(18px,  calc( 1.4vw + 0.1rem ), 80px);
    height: clamp(18px,  calc( 1.4vw + 0.1rem ), 80px);
    border-radius: 50%;
    background-color: rgba(70, 70, 70, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.choose_transfer_method_card .card_header .complete_icon svg {
    stroke: #0000008e;
    line-height: 0;
    width: clamp(10px,  calc( 0.7vw + 0.1rem ), 80px);
}

.choose_transfer_method_card .card_header .complete_icon.completed {
    background-color: var(--Blue300);
}

.choose_transfer_method_card .card_header .complete_icon.completed svg {
    stroke: #fff;
}

.choose_transfer_method_card .transfer_methods {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 2rem;
}

.choose_transfer_method_card .transfer_methods .method_item {
    width: 25%;
    background: rgba(32, 32, 32, 1);
    box-shadow: inset 0px 0px 20px 0px #00000069;
    border-radius: 10px;
    border: 1px solid #00000069;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: all 0.3s;
}

.choose_transfer_method_card .transfer_methods .method_item input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.choose_transfer_method_card .transfer_methods .method_item:has(input:checked) {
    background: var(--Blue300);
    border: 1px solid var(--Blue300);
    box-shadow: none;
}

.choose_transfer_method_card .transfer_methods .method_item p:first-child {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.8vw + 0.1rem ), 60px);
    font-weight: 400;
    text-align: left;
}

.choose_transfer_method_card .transfer_methods .method_item p:last-child {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.8vw + 0.1rem ), 60px);
    font-weight: 700;
    text-align: left;
}

.choose_transfer_method_card .transfer_methods .method_item img {
    width: clamp(25px,  calc( 2vw + 0.1rem ), 80px);
}

.choose_transfer_method_card .transfer_methods .add_more {
    width: 25%;
}

.choose_transfer_method_card .transfer_methods .add_more button {
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 10px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clamp(10px,  calc( 2.5vw + 0.1rem ), 60px);
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 60px);
    font-weight: 700;
    text-align: left;
    box-sizing: content-box;
    transition: all 0.3s;
}
.choose_transfer_method_card .transfer_methods .add_more button:hover {
    background: #53acff;
    border: 1px solid #53acff;
}

.input_group {
    display: flex;
    flex-direction: column;
}

.input_group label {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 60px);
    font-weight: 700;
    text-align: left;
    margin-bottom: 1rem;
}

.input_group input {
    padding: 4px 10px;
    background-color: rgba(255, 255, 255, 0.16);
    color: #fff;
    outline: none;
    box-shadow: none;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 50px);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.16);

}

.input_group input:last-child {
    background-color: transparent;
    border: 1px solid #fff;
}

.input_group span {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.7vw + 0.1rem ), 80px);
    font-weight: 400;
    text-align: left;
    padding: 0.3rem 1rem;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}


.check_input_group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.check_input_group label {
    position: relative;
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.7vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;

    cursor: pointer;
}

.check_input_group label:before {
    content: '';
    background-color: transparent;
    border: 1px solid #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 4px;
}

.check_input_group input:checked+label::before {
    background-color: var(--Blue300);
    border: 1px solid var(--Blue300);
}

.check_input_group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 7px;
    width: 4px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: translateY(-50%) rotate(45deg);
    transition: all 0.3s;
}

.confirm_btn {
    display: block;
    font-size: clamp(10px,  calc( 0.7vw + 0.1rem ), 80px);
    font-weight: 600;
    text-align: center;
    padding: 8px 3rem;
    background-color: var(--Blue300);
    border-radius: 4px;
    outline: none;
    border: none;
    margin: 1rem 0 0 auto;
    transition: all 0.3s;
}

.confirm_btn:hover{
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}



.bank_transfer_transaction_card {
    padding: clamp(10px,  calc( 2.2vw + 0.1rem ), 100px);
    box-shadow: inset 0px 0px 20px 0px #00000069;
    border-radius: 5px;
    margin-top: 2rem;
}

.bank_transfer_transaction_card .card_header {
    padding-bottom: 2rem;
    border-bottom: 1px solid #fff;
    margin-bottom: 4rem;
}

.bank_transfer_transaction_card .card_header h4 {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 1.8vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;
}

.bank_transfer_transaction_card .card_header p {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 400;
    text-align: left;
    margin-top: 2rem;
}

.bank_transfer_transaction_card .card_body ul li , .bank_transfer_transaction_card .card_body ul li span {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 400;
    text-align: left;
    margin: 0.2rem 0;
}

.bank_transfer_transaction_card .card_body ul li:first-child {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;
    margin-bottom: 1rem;
}

.bank_transfer_transaction_card .card_body ul li button {
    border: none;
    padding: 0;
    outline: none;
    box-shadow: none;
    background: none;
}



.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    font-size: clamp(10px,  calc( 0.5vw + 0.1rem ), 80px);
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    text-wrap: nowrap;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }



@media only screen and (max-width: 1024px) {

    .choose_transfer_method_card {
        padding: 2rem 1rem;
    }

    .choose_transfer_method_card .card_header h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
    }

    .choose_transfer_method_card .transfer_methods {
        flex-direction: column;
        gap: 1rem;
        padding: 0 0.5rem;
    }

    .choose_transfer_method_card .transfer_methods .method_item {
        width: 100%;
        padding: 1rem 2rem;
    }

    .choose_transfer_method_card .transfer_methods .add_more {
        width: 100%;
        height: 75px;
    }

    .choose_transfer_method_card .transfer_methods .method_item p:first-child {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    .choose_transfer_method_card .transfer_methods .method_item p:last-child {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
    }

    .choose_transfer_method_card .transfer_methods .add_more button {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }

    .input_group label {
        margin-bottom: 0.8rem;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
    }

    .input_group .check_input_group label {
        font-size: 11px;
        font-weight: 700;
        line-height: 11px;
    }

    .confirm_btn {
        margin: 2rem auto 1rem;
        font-size: 12px;
    }

    .input_group input {
        padding: 3px 10px;
        font-size: 12px;

    }

    .bank_transfer_transaction_card {
        padding: 2rem 1rem;
    }

    .bank_transfer_transaction_card .card_header h4 {
        font-size: 16px;
        line-height: 24px;
    }

    .bank_transfer_transaction_card .card_header p {
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
    }

    .bank_transfer_transaction_card .card_body ul li:first-child {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        margin-bottom: 0.7rem;
    }

    .bank_transfer_transaction_card .card_body ul li , .bank_transfer_transaction_card .card_body ul li span {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
    }

    .bank_transfer_transaction_card .card_body ul li a > img {
        width: 20px;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        font-size: 8px;
    }

}