.pricing-wrapper {
    background: radial-gradient(circle, rgba(83, 172, 255, 0.6138830532212884) 0%, rgba(37, 41, 45, 1) 80%);

}

.pricing-wrapper section.hero {
    background-image: none;
    padding: 10% 0 0%;
}

.pricing-container {
    max-width: 75vw;
}

.pricing-container .plane-pricing .plane-points li {
    font-size: clamp(10px,  calc( 0.45vw + 0.5rem ), 60px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
}

.pricing-container .plane-pricing .plane-points li::before {
    content: none;
}

.pricing-container .plane-pricing .plane-points li svg {
    margin-right: 1rem;
    width: clamp(10px,  calc( 0.2vw + 0.5rem ), 60px);
}

.pricing-container .plane-pricing .plane-points li.available .false {
    display: none;
}

.pricing-container .plane-pricing .plane-points li.not-available .true {
    display: none;
}

.pricing-container .plane-pricing .plane-points li.not-available {
    color: rgba(255, 255, 255, 0.6);
}

.pricing-container .plane-pricing.popular img {
    width: clamp(12px, calc( 4.6vw + 0.5rem ) , 400px);
    position: absolute;
    top: -4px;
    right: -3px;
    z-index: 9;
}

.pricing-container .plane-pricing {
    overflow: unset;
    transform: translate(0);
    box-shadow: 0px 18.83098602294922px 29.591548919677734px 0px rgba(0, 0, 0, 0.25);

}

/* .pricing-container .plane-pricing.popular {
    background: linear-gradient(153deg, #FFFFFF 0%, rgba(37, 41, 45, 0.6138830532212884) 100%);

} */

.pricing-container .plane-pricing::before {
    content: unset;
}

.pricing-container .gradient-border {
    border-radius: var(--21px);
    padding: 1px;
    background: linear-gradient(100deg, rgba(255, 255, 255, 0.8715861344537815) 0%, rgba(37, 41, 45, 0) 34%);
}
.pricing-container .gradient-border:hover::after{
    transform: rotate(180deg);
}

.pricing-container .gradient-border.popular {
    background: linear-gradient(90deg, rgba(71, 153, 230) 0%, rgba(37, 41, 45, 0) 100%);

}

.pricing-container .gradient-border.popular .plane-body {
    background: linear-gradient(153deg, rgba(73, 143, 209, 0.5063200280112045) 0%, rgba(37, 41, 45, 0.03461134453781514) 56%, rgba(71, 136, 198, 0.5287289915966386) 100%);

}

.pricing-container .plane-pricing .line {
    background-color: #fff;
}

.pricing-container .plane-pricing .plane-price p {
    color: #fff;
}
.setting-ourselves{
    max-width: 73vw;
}
.setting-ourselves h5 {
    font-family: Poppins;
    font-size: clamp(22px, calc( 2.2vw + 0.5rem ) ,140px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 1rem;
}
.plane-pricing.border-animate {
    padding: 0 ;
}
.setting-ourselves p {
    font-family: Poppins;
    font-size: clamp(12px,  calc( 1vw + 0.5rem ) , 100px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
}

.feature-comparison {
    padding: 5% 0;
}

.feature-comparison .ourFeature-card {
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    padding: 1px;
    border-radius: var(--21px);
}

.feature-comparison .ourFeature-card::before {
    content: "";
    background-image: conic-gradient(transparent 180deg, var(--Blue300) 270deg, transparent 360deg);
    width: 250%;
    height: 180%;
    position: absolute;
    transform: translate(-50%, -50%) rotate(0);
    top: 50%;
    left: 50%;
    z-index: 0;
    transition: all 0.7s;
}
.feature-comparison .ourFeature-card.mainFeatureCard::before{
    animation: rotatestroke 0.9s infinite ;
}

@keyframes rotatestroke {
    from {transform: translate(-50%, -50%) rotate(0);}
    to {transform: translate(-50%, -50%) rotate(270deg);}  
}



.feature-comparison .ourFeature-card .card-content {
    background: #1e3d5a;
    z-index: 1;
    position: relative;
    border-radius: var(--21px);
    display: block;
}

.feature-comparison .ourFeature-card .card-body {
    background: linear-gradient(153deg, rgb(66 119 171 / 62%) 0%, rgb(44 50 57 / 11%) 40% 60%, rgb(66 119 171 / 48%) 100%);
    border-radius: var(--21px);
    padding: 0 2rem;
}

.feature-comparison .ourFeature-card .card-body .card-header {
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem;
    border-bottom: 1px solid #fff;
}

.feature-comparison .ourFeature-card .card-body .card-header img {
    width: clamp(50px, calc( 5vw + 0.5rem ) ,400px);
}

.feature-comparison .ourFeature-card .card-body .card-header h6{
    font-size: clamp(10px,  calc( 0.5vw + 0.5rem ), 60px);
}

.feature-comparison .ourFeature-card .card-body .card-header p {
    font-family: Poppins;
    font-size: clamp(12px, calc( 0.8vw + 0.5rem ) , 70px);
    font-weight: 400;
    letter-spacing: 0em;
}

.feature-comparison .ourFeature-card .card-body .features-list {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feature-comparison .ourFeature-card .card-body .features-list ul {
    display: flex;
    flex-direction: column;
    gap: clamp(12px, calc( 0.6vw + 0.5rem ) , 70px);
}

.feature-comparison .ourFeature-card .card-body .features-list ul>li {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 400;
    text-align: left;
}

.feature-comparison .ourFeature-card .card-body .features-list ul>li>svg {
    width: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    height: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
}

.feature-comparison .line {
    height: 20rem;
    width: 1px;
    background: #fff;
}


@media only screen and (max-width: 1024px) {
    .pricing-wrapper section.hero{
        padding: 20% 1rem;
    }

    .pricing-container {
        max-width: 100%;
        overflow-x: auto;
        padding: 10% 0;
    }

    .setting-ourselves {
        max-width: 100%;
    }

    .pricing-container .plane-pricing {
        max-width: 300px;
    }

    .pricing-container .plane-pricing.popular img {
        position: absolute;
        top: -4px;
        right: -4px;
        width: 80px;
    }

    .pricing-container .plane-pricing .plane-points li {

        font-weight: 400;
        text-align: left;
        text-wrap: nowrap;
    }


    .pricing-container .gradient-border {
        border-radius: 11px;
    }

    .pricing-container .plane-pricing {
        border-radius: 11px;
    }

    .pricing-container .plane-pricing .line {
        margin: 1rem;
    }

    .pricing-container .plane-pricing .plane-body {
        border-radius: 11px;
    }

    .pricing-container .plane-pricing.recommended::after {
        border-radius: 11px;
    }

    .pricing-wrapper section.hero p {
        display: block;
    }

    .feature-comparison {
        width: 100%;
        overflow-x: auto;
    }

    .feature-comparison .ourFeature-card {
        min-width: 270px;
    }

    .feature-comparison .ourFeature-card .card-body .card-header {
        min-height: 8rem;
        padding: 0rem;
    }

    .feature-comparison .ourFeature-card .card-body .card-header img {
        width: 80px;
    }

    .feature-comparison .ourFeature-card .card-body .features-list {
        padding: 2rem 0.5rem;
    }

    .feature-comparison .ourFeature-card .card-body {
        padding: 0 1rem;
    }

}