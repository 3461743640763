.checkout-wrapper {
    background: linear-gradient(153deg, rgba(32, 32, 32, 1) 20%, rgba(83, 172, 255, 0.5) 100%);
}

.checkout-wrapper section.hero {
    background-image: none;
}

.checkout-container {
    max-width: 80vw;
}

.checkout-container .chosen-plan .recommended-plane {
    position: relative;
}

.checkout-container .chosen-plan .recommended-plane img {
    position: absolute;
    top: -5px;
    right: -4px;
    z-index: 9;
}

.border-animate {
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.border-animate::before {
    content: "";
    background-image: conic-gradient(transparent 180deg, white 240deg, transparent 360deg);
    width: 250%;
    height: 250%;
    transform: translate(-50%, -50%) rotate(0deg);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transition: all 0.7s;
}

.border-animate:hover::before {
    transform: translate(-50%, -50%) rotate(180deg);
}

.checkout-container .plane-body {
    background: linear-gradient(54deg, rgba(38, 58, 75, 1) 0%, rgba(51, 85, 117, 1) 100%);
    padding: 3rem var(--90px);
}

.checkout-container .plane-pricing .plane-points li {
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
}

.checkout-container .plane-pricing .plane-points li::before {
    content: none;
}

.checkout-container .plane-pricing .plane-points li svg {
    margin-right: 1rem;
    width: clamp(10px,  calc( 0.4vw + 0.3rem ), 60px);
}

.checkout-container .plane-pricing .plane-points li.available .false {
    display: none;
}

.checkout-container .plane-pricing .plane-points li.not-available .true {
    display: none;
}

.checkout-container .plane-pricing .plane-points li.not-available {
    color: rgba(255, 255, 255, 0.6);
}

.checkout-container .plane-pricing .line {
    background-color: #fff;
}

.checkout-container .plane-pricing .plane-price p {
    color: #fff;
}

.checkout-container .plane-pricing .plane-action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.checkout-container .plane-pricing .plane-action p {

    font-family: Poppins;
    font-size: var(--13px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 1rem;

}

.checkout-container .plane-pricing .plane-action a {
    width: auto;
    padding: var(--10px) var(--30px);
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.3rem ), 60px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
}

.checkout-container .plane-pricing .plane-action a:hover {
    background: var(--Blue300);
    border: 1px solid var(--Blue300);
    box-shadow: none;
}


.checkout-container .payment-details h6 {
    font-family: Poppins;
    font-size: var(--33px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    margin: 1.5rem 0;
}

.checkout-container .payment-details .form-input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.checkout-container .payment-details .form-input label {
    font-family: Poppins;
    font-size: var(--16px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
}

.checkout-container .payment-details .form-input input {
    font-family: Poppins;
    font-size: clamp(14px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding: 7px 1rem;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 7px;
    width: 90%;
}

.checkout-container .payment-details .form-input input::placeholder {
    color: rgba(151, 151, 151, 1);

}

.checkout-container .payment-details .card-details {
    position: relative;
    padding: 2px;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 2rem;
    width: 100%;
    box-shadow: 0px 17.466970443725586px 27.448097229003906px 0px rgba(0, 0, 0, 0.25);

}

.checkout-container .payment-details .card-details::before {
    content: "";
    background-image: conic-gradient(transparent 180deg, var(--Blue300) 240deg, var(--Blue300) 280deg, transparent 360deg);
    width: 250%;
    height: 250%;
    transform: translate(-50%, -50%) rotate(270deg);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transition: all 0.7s;
}

.checkout-container .payment-details .card-details:hover::before {
    transform: translate(-50%, -50%) rotate(360deg);
}

.checkout-container .payment-details .card-details .card-body {
    background: linear-gradient(54deg, rgba(38, 58, 75, 1) 0%, rgba(60, 105, 147, 1) 100%);
    padding: 3rem 2rem;
    border-radius: 20px;
}

.checkout-container .payment-details .card-details .form-input input {
    width: 100%;
}

.checkout-container .payment-details button {
    display: block;
    margin-left: auto;
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 600;
    letter-spacing: 0em;
    background: var(--Blue300);
    color: #fff;
    border: none;
    padding: 0.6rem 1.5rem;
    border-radius: 4px;
    transition: all 0.3s;
}

.checkout-container .payment-details button:hover{
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}


@media only screen and (max-width: 1024px) {

    .checkout-container .plane-body {
        background: linear-gradient(54deg, rgba(38, 58, 75, 1) 0%, rgba(51, 85, 117, 1) 100%);
        padding: 2rem 1rem;
    }

    .checkout-container .plane {
        border-radius: 50px;
    }

    .checkout-container .chosen-plan .recommended-plane {
        width: 100%;
        margin-bottom: 2rem;
    }

    .checkout-container .payment-details .form-input input {
        padding: 7px 1rem;
        width: 100%;
    }

    .checkout-container .payment-details .card-details .card-body {
        padding: 2rem 1rem;
    }

    .checkout-container .payment-details h6 {
        margin: 3rem 0 1.5rem;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
    }

}