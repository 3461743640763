.time span{
    color: #53ACFF;
    font-size: clamp(10px, calc( 0.5vw + 0.1rem), 60px);
    font-weight: 400;
}
.grayOne{
    color:#979797 !important  ;
}

@media only screen and (max-width: 676px){
    .grayOne{
        font-size: 0.625rem;
        line-height: 0.7875rem;
        font-weight: 400;
    }
}