
.customSelectMenu ul{
    box-shadow: 0px -1px 12.100000381469727px 0px rgba(0, 0, 0, 0.44) inset;
    border-radius: 4px;
    top: calc(100% + 0.2rem);
    background: rgba(32, 32, 32, 1);
    z-index: 999999;
    padding: 0.5rem 0;
}
.customSelectMenu ul li{
    font-size: clamp(10px,  calc( 0.6vw + 0.1rem ), 60px);
    font-weight: 400;
    text-align: left;
    
}

.customSelectMenu ul li:hover{
    background-color: #53acffa2;
}


.customSelectMenu ul::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 10px;
    background-color: transparent;
  
  }
  
  .customSelectMenu ul::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }
  
  .customSelectMenu ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: none;
    background-color: #424242;
  }
  
