.disclaimerHeader h1{
    font-size: clamp(24px, calc(2.5vw + 0.1rem), 140px);
    font-weight: 700;
}
.disclaimerHeader{
    display: flex;
    flex-direction: column;
    gap: clamp(8px, calc( 0.6vw + 0.1rem), 60px);
}
.disclaimerPage{
    display: flex;
    flex-direction: column;
    gap: clamp(48px, calc(5.4vw + 0.1rem),200px);
    padding-top: clamp(50px, calc(8vw + 0.1rem),300px);
    padding-bottom: clamp(50px, calc(3.8vw + 0.1rem),200px);
}
.disclaimerContentParent{
    display: flex;
    flex-direction: column;
    gap: clamp(32px, calc(3.3vw + 0.1rem),160px);
}
.disclaimerContent{
    display: flex;
    flex-direction: column;
    gap: clamp(12px, calc(1.6vw + 0.1rem), 150px)    ;
}
.disclaimerHeader p{
    font-size:  clamp(10px, calc( 0.8vw + 0.1rem), 60px);
    font-weight: 400;
}
.disclaimerContent h4{
    font-size: clamp(16px, calc(1.9vw + 0.1rem), 140px);
    font-weight: 700;
}
.disclaimerContent p{
    font-size: clamp(12px, calc( 0.8vw + 0.1rem), 60px);
    font-weight: 400;
    text-align: justify;
}
.disclaimerBG{
   background:linear-gradient(175deg, rgba(29,36,43,1) 6%, rgba(63, 112, 158, 0.98) 47%, rgba(46,80,112,1) 65%, rgba(29,36,43,1))
}

@media only screen and (max-width: 676px){
    .disclaimerHeader h1{
        line-height: 2.25rem;
    }
    .disclaimerHeader p{
        line-height: 0.9375rem;
    }
    .disclaimerContent h4{
        line-height: 1.5rem;
    }
    .disclaimerContent p{
        line-height: 1.125rem;
    }
}