
.want_share {
    padding: 2rem 3rem;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25) inset;
    border-radius: 5px;
}


.company_details_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 1.5rem 0;
    border-bottom: 1px solid #fff;
}
.company_details_header img{
  width: clamp(10px,  calc( 6vw + 0.1rem ), 500px);

}

.company_details_body ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.company_details_body ul li span:first-child {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.8vw + 0.1rem ), 60px);
    font-weight: 700;
    text-align: left;

}

.company_details_body ul li span:last-child {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.8vw + 0.1rem ), 60px);
    font-weight: 400;
    text-align: left;


}

.company_details_body ul li span a {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.8vw + 0.1rem ), 60px);
    font-weight: 400;
    text-align: left;
    color: var(--Blue300);

}


.want_share .total_price_direction {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background-color: #1E1E1E;
    box-shadow: 0px 6px 10px 0px #000000a2;
    border-radius: 7px;
}

.want_share .total_price_direction {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 1vw + 0.1rem ), 60px);
    font-weight: 400;
    line-height: 25.96px;
    text-align: left;

}

.want_share .total_price_direction svg {
    width: 10px;
    transform: rotateX(180deg);
}

.want_share .total_price_direction svg path {
    fill: #118F4B;
}

.want_share_chart_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.want_share_chart_header h5 {
    font-family: Poppins;
    font-size: clamp(12px, calc( 1.2vw + 0.1rem ) , 70px);
    font-weight: 700;
    text-align: left;
}


.number_of_sales {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.number_of_sales span {
    font-size: 15.95px;
    font-weight: 500;
    background: var(--Blue300);
    border-radius: 5px;
    padding: 0 0.2rem;
}

.number_of_sales p {
    font-size: 9.84px;
    left: 10px;
}

.want_share_chart_body {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    border: 1px solid #ffffff38;
    border-radius: 5px;
}

.want_share_chart_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.want_share_chart_footer .input_group label {
    font-family: Poppins;
    font-size: clamp(12px, calc( 0.7vw + 0.1rem ) , 70px);
    font-weight: 400;
    line-height: 1.024rem;
    text-align: left;
    margin-bottom: 1rem;
}

.want_share_chart_footer .input_group input {
    width: 100%;
    padding: 7px 10px;
    border: 0;
    outline: none;
    border-radius: 4px;
    font-size: clamp(12px, calc( 0.7vw + 0.1rem ) , 70px);
    color: #1E1E1E;
}

.want_share_chart_footer .input_group span {
    font-family: Poppins;
    font-size: clamp(12px, calc( 0.5vw + 0.1rem ) , 70px);
    font-weight: 400;
    text-align: left;
    color: #ffffff96;
}

.want_share_chart_footer .actions {
    display: flex;
    flex-direction: column;
}

.want_share_chart_footer .actions button {
    font-family: Poppins;
    font-size: clamp(12px, calc( 0.6vw + 0.1rem ) , 70px);
    font-weight: 600;
    text-align: center;
    padding: 0.5rem 1rem;
    outline: none;
    box-shadow: none;
    border-radius: 5px;
}

.want_share_chart_footer .actions button:first-child {
    background-color: var(--Blue300);
    border: none;
    transition: all 0.3s;
}

.want_share_chart_footer .actions button:first-child:hover {
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}

.want_share_chart_footer .actions button:last-child {
    background: rgba(204, 204, 204, 0.22);
    border: 1px solid rgba(204, 204, 204, 0.22);
    transition: all 0.3s;
}

.want_share_chart_footer .actions button:last-child:hover {
    background: var(--Blue300);
    border: 1px solid var(--Blue300);
}