:root {
  --Blue50: #eef7ff;
  --Blue75: #b8ddff;
  --Blue100: #9bcfff;
  --Blue200: #70BAFF;
  --Blue300: #53ACFF;
  --Blue400: #3a78b3;
  --Blue500: #33699c;
  --Black50: #e6e6e6;
  --Black75: #969696;
  --Black100: #6b6b6b;
  --Black200: #2b2b2b;
  --Black300: #000000;
  --white: #fff;
}
.linksSection{
  display: flex !important;
  gap: clamp(12px, calc( 1.4vw + 0.1rem ) , 70px) !important;
}

/*
8px=> clamp(4px, calc( 0.15vw + 0.1rem), 60px);
12px/13px=> clamp(10px, calc( 0.6vw + 0.1rem), 60px);
14px=> clamp(10px, calc( 0.4vw + 0.5rem), 60px);
16px=> clamp(10px, calc( 0.5vw + 0.1rem), 60px);
19px=> clamp(10px, calc(0.7vw + 0.1rem), 60px);
23px=> clamp(12px, calc( 0.9vw + 0.5rem ) , 70px);
24px=> clamp(14px, calc( 1.1vw + 0.5rem ) , 70px);
27px=>  clamp(20px, calc(1.2vw + 0.5rem), 70px);
30px=> clamp(14px, calc(2.5vw + 0.5rem), 80px);
33px=> clamp(16px, calc(2.2vw + 0.1rem), 80px);
48px=> clamp(24px, calc(2.8vw + 0.5rem),140px);
64px=> clamp(24px, calc(3.8vw + 0.1rem),140px);
96px => clamp(56px, calc(5.5vw + 0.5rem),140px);
40px=> clamp(24px, calc(1.8vw + 0.1rem),90px); 
320px=> clamp(56px, calc(15vw + 0.1rem),500px)*/

.css-1gw9vc6-JoyInput-input{
  padding: clamp(4px, calc(0.2vw + 0.1rem), 100px) 0px !important;
}
body {
  color: #fff;
  overflow-x: hidden;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #FFFFFF;
  font-family: "Poppins", sans-serif;
  line-height: normal !important;
}
.switchStyle .MuiSwitch-track {
  height: var(--sy-16px) !important;
  border-radius: var(--23px) !important;
  width: var(--32px) !important;
}
.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background: black !important;

}
.css-jsexje-MuiSwitch-thumb {
  width: var(--16px) !important;
  height: var(--16px) !important;
  transform: translateY(var(--sy-3px));
}
.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked .css-jsexje-MuiSwitch-thumb {
  background: linear-gradient(180deg, #53ACFF 20.59%, #2B72B5 85.35%);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.textAreaInForm{
  border-radius: 0.5rem;
  color:#2b2b2b;
  font-weight: 400;
  font-size: clamp(10px, calc( 0.7vw + 0.1rem), 60px);
  padding: 4px;
}
.textAreaInForm:focus,.msgTextArea:focus{
    outline:none !important;
    border: 3px solid #2070bb;
}
.Msglayout{
  background: linear-gradient(150deg, rgba(32,32,32,1) 15%, rgba(47, 83, 117,1) 100%);
}

.Signuplayout {
  background: linear-gradient(190deg, rgba(32, 32, 32, 1) 40%, rgba(47, 83, 117, 1) 100%);
  min-height: 100vh;
  align-items: center;
}
.formGroup{
  display: flex !important;
  flex-direction: column !important;
  gap: clamp(15px, calc(1.3vw + 0.1rem), 60px);
}

.Msglayout {
  background: linear-gradient(150deg, rgba(32, 32, 32, 1) 15%, rgba(47, 83, 117, 1) 100%);
  min-height: 100vh;
}

.highlight {
  background-color: #53ACFF;
  border-radius: 0.3rem;
  padding: 0 0.3rem;
  text-wrap: nowrap;
}
.homePage-wrapper section.hero{
  padding: 10vh 10% 0;
}

.forBorder,
.smforBorder,
.emptyDivBorder,
.whiteBorder,
.winnerAndLoserBorder {
  position: relative;
  overflow: hidden;
  padding: 1px;
  border-radius: var(--21px);
}

.formElementsAndBtn{
  display: flex;
  flex-direction: column;
  gap: clamp(6px, calc(1.7vw + 0.1rem),140px);
}

.formElements{
  display: flex;
  flex-direction: column;
  gap:var(--sy-30px);
}

.labelAndInputStyle{
  display: flex;
  flex-direction: column;
  gap: clamp(6px, calc( 0.3vw + 0.2rem), 15px);
}

.forBorder::before {
  content: "";
  background-image: conic-gradient(transparent 0deg, #53acff 120deg, transparent 220deg);
  width: 250%;
  height: 250%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(360deg);
  box-sizing: border-box;
  position: absolute;
  transition: all 0.5s
}

.forBorder:hover::before {
  transform: translate(-50%, -50%) rotate(180deg);
}
.contactPage .labelfont {
  font-size: var(--16px) !important;
}
.labelfont {
  font-size: var(--14px) !important;
  font-family: "Poppins", sans-serif;
  font-weight: 400 !important;
  font-style: normal;
  color: #FFFFFF !important;
  display: flex !important;

}

.inForm {
  display: flex;
  justify-content: center;
  margin: auto var(--60px);
}

.formPart {
  border-radius: var(--21px);
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.alertsPage-wrapper {
  display: flex;
  overflow: hidden;
}
.alertsPage-wrapper .notification_window{
  right: -5.5rem;
}

.transfer_pay_side_nav li a.active {
  padding: 0.5rem 0.8rem;
  border-radius: 5px;
}

.transfer_pay_side_nav li:has(a.active) {
  background-color: var(--Blue300);
  border-radius: 5px;
}

.transfer_pay_side_nav li:has(a.active):hover {
  color: #fff;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  padding: 1rem 0;
}

.dashboard-wrapper .trading-wrapper a.active {
  color: #fff;
  padding: 0.5rem 1rem;
  background-color: var(--Blue300);
  border-radius: 6px;
  font-size: clamp(10px, calc(0.4vw + 0.5rem), 60px);
  font-weight: 700;
}

.equity_collapse .collapse_header.open {
  background-color: var(--Blue300);
}


.training_lison_collapse.current .training_lison_collapse_header {
  background-color: var(--Blue300);
}

.training_lison_collapse.open .training_lison_collapse_header svg {
  transform: rotateX(0deg);
}

.training_lison_collapse.completed .training_lison_collapse_header h6,
.training_lison_collapse.completed .training_lison_collapse_header svg path {
  color: rgba(126, 126, 126, 1);
  fill: rgba(126, 126, 126, 1);
}

.training_lison_collapse.current .training_lison_collapse_header h6,
.training_lison_collapse.current .training_lison_collapse_header svg path {
  color: #fff;
  fill: #fff;
}

.MyCourses-wrapper .myCourses-tabs .tabs-nav li a {
  display: block;
  font-size: clamp(10px, calc(0.6vw + 0.5rem), 60px);
  font-weight: 700;
  color: rgba(93, 93, 93, 1);
  padding: 0.4rem 1.5rem;
  width: fit-content;
  border-radius: 6px;
  transition: all 0.3s;
}

.MyCourses-wrapper .myCourses-tabs .tabs-nav li.selected a {
  background-color: var(--Blue300);
  color: #fff;
}

.dashboard-wrapper ul li:has(a.active)  {
  background-color: var(--Blue300);
}

.private_equities_table .customSelectMenu button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: left;
  padding: 0;
  border: none;
  background: transparent;
  border-radius: 4px;
  box-shadow: none;
}

.private_equities_table .customSelectMenu ul {
  overflow-x: hidden;
}

.private_equities_table .customSelectMenu ul li {
  display: block;
  text-align: center;
  padding: 0.4rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1rem;
}

.notification_window .selected>a {
  color: #53ACFF;
  border-bottom: 1px solid #53ACFF;
}

.formBtn {
  padding: 0.6rem 2.5rem !important;
  font-weight: 600 !important;
  font-size: clamp(10px, calc(0.5vw + 0.3rem), 60px) !important;
}

.labelfont a {
  text-decoration: underline;
}

.labelfont span {
  font-weight: 400 !important;
  font-size: clamp(10px, calc( 0.7vw + 0.1rem), 60px) !important;

}

.dashboard-side-menu ul li a.active>p,
.dashboard-side-menu ul li a.active>svg path {
  color: #53ACFF !important;
  fill: #53ACFF !important;
}
.checkboxes{
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width:767px) {

  .formHead h3,
  .formHead span {
    font-size: 1.25rem;
    line-height: 1.9rem;

  }

  .formHead p,
  .formHead .labelfont {
    font-size: 0.875rem !important;
    line-height: 1.3125rem;
  }

  .checkboxFont span {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  .rememberClass span {
    padding-top: 0.94rem !important;
  }

  .labelfont {
    align-items: start !important;
  }

  .formBtn {
    padding: 0.3rem 1.5rem !important;
  }

}


input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.dashboard-wrapper {
  width: calc(100% - clamp(80px, calc(4vw + 0.5rem), 300px));
  padding: 1rem 0 1rem 1rem;
  margin-left: auto;
  overflow: hidden;
}

.search-table-section {
  width: 65%;
  padding: 1rem;
}

.filters-section {
  width: 30%;
}


@media only screen and (max-width: 1280px) {

  .search-table-section {
    width: 100%;
  }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1024px) {
  .dashboard-wrapper {
    width: calc(100% - 60px);
    padding: 0.5rem 0;
  }

  .search-table-section {
    width: 100%;
    padding: 1rem;
  }

  .filters-section {
    width: 0%;
  }

  .transfer_pay_side_nav li {
    margin: 0
  }


  .MyCourses-wrapper .tabs-nav li a {
    padding: 0.4rem 1rem;
    font-size: 11px;
    font-weight: 700;
    line-height: 16.5px;
  }



}



.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.wheelSelect .slick-slider .slick-list,
.wheelSelect .slick-slider .slick-track {
  padding: 0rem 0;
  /* transform: none !important; */
}

.wheelSelect .slick-prev {
  position: absolute;
  top: 0;
  bottom: unset;
  right: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: #fff;
  opacity: 0;
  transform: translate(0px, 0px);
  z-index: 99;
}

.wheelSelect .slick-next {
  position: absolute;
  top: unset;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: #fff;
  opacity: 0;
  transform: translate(0px, 0px);
  z-index: 99;
}

.wheelSelectInput.checked {
  background-color: #53ACFF;
}

.overlay-modal {

  background:  linear-gradient(180deg, rgba(32,32,32,1) 40%, rgb(37, 66, 94) 100%) !important;
}
.contactPage .formLabelsAndInputs {
  gap: var(--sy-16px) !important;
}
.contactPage .css-kf85qm-JoyInput-root {
  margin-bottom: var(--sy-15px);
}