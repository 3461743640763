.brokersHeader h1{
    font-size: clamp(24px, calc(2.3vw + 0.5rem), 140px);
    font-weight: 700;
}
.brokersHeader{
    margin-bottom: clamp(48px, calc(8vw + 0.5rem), 200px) !important;
}
.rightPrt h4{
    font-size: clamp(16px, calc(1.3vw + 0.5rem), 70px);
    font-weight: 700;
}
.topBrokerSM h4{
    font-size: clamp(16px, calc(1vw + 0.5rem), 70px);
    font-weight: 700;
}
.rightPrt{
    display: flex;
    flex-direction: column;
    gap:5rem
}
.textSection{
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}
.textSection p{
    font-size: (10px, calc( 0.5vw + 0.5rem), 60px);
    font-weight: 400;
}
.brokerBG{
    background: linear-gradient(180deg, rgb(34, 72, 107) 5%,  rgb(59, 126, 189) 100%);
}
.blackBG{
    background-color: #202020;
}
.brokerDiv{
    box-shadow: 0px 4px 100px 4px #00000093;
    border-radius: 0.5rem;
}
.OneBrokerBG{
    background: linear-gradient(230deg, #00000040 40%, #53ACFF 100%);
}
.spacedList span{
    font-size: clamp(10px, calc( 0.5vw + 0.5rem), 60px);
    font-weight: 400;
}
.anyList{
    display:flex;
    flex-direction:column;
    gap: 0.7rem;
}
.spacedList{
    gap:1.5rem;
}

.smbrokerBG{
    background: linear-gradient(150deg, rgb(21, 43, 65) 5%,  rgb(37, 72, 104) 100%);
}

/* header css */

.textToImg{
    padding: 0.6rem 0.1rem;
}
.textToImg h4{
    font-size: clamp(11px, calc(0.7vw + 0.5rem), 70px);
    line-height:2.156rem;
}
.holder{
position: relative;
}
.imgDivHeader{
    width: clamp(33px, calc(5vw + 0.5rem), 300px);
    height: clamp(31px, calc(4vw + 0.5rem), 200px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectedItems{
    background-color: rgba(51,83,115,0.7);
    padding: 2rem 1.5rem;
    border-top-left-radius: 1.8rem;
    border-top-right-radius: 1.8rem;

}
/* compare table css */
.compareHeader h1{
    font-size: 3rem;
    font-weight: 700;
    }
    .compareTable{
        background-color: rgba(34,40,45,0.9);
        border-top-left-radius:1.5rem ;
        border-top-right-radius:1.5rem ;
    }
    .compareTable h5, .compareTable h6{
        font-size:  clamp(15px, calc(0.7vw + 0.5rem), 60px);
        font-weight: 700;
    }
    .providerTitle h4 {
        font-size: clamp(12px, calc( 0.9vw + 0.5rem ) , 70px);
        font-weight: 700;
    }
    .visitWebsite span{
        color: #979797;
        font-size: clamp(10px, calc(0.2vw + 0.5rem), 60px);
        font-weight: 400;
    }
    .rightThickBorder{
        border-right: 2px solid #979797;
    }
    .bottomThickBorder{
        border-bottom: 2px solid #979797 ;
    }
    .providerFeatureName{
        background-color: rgba(41, 45, 50, 0.9);
    }

    .providerFeature h6{
        font-size: clamp(20px, calc(1.3vw + 0.5rem), 70px);
        font-weight: 400;
    }
    .comparePrt{
        position: fixed;
        z-index: 999;
        bottom: 0;
        width: 100%;
    }
    .comparePrt h4,.feesPrt h4 ,.accountPrt h4{
        font-size: clamp(18px, calc(0.7vw + 0.5rem), 60px);;
        font-weight: 700;
    }
    .feesPrt{
        position: absolute;
        top: 41%;
        left: 5%;
    }
    .accountPrt{
        position: absolute;
        top: 61%;
        left: 5%;
    }
    .promotionsPrt{
        position: absolute;
        top: 81%;
        left: 5%;
    }
    .oneBrokerFonts h5{
        font-weight: 700;
        font-size: clamp(10px, calc( 0.5vw + 0.5rem), 60px);;
    }
    .ourPicks h4{
        font-size: clamp(11px, calc(0.9vw + 0.5rem), 70px);
    }
    .selectFont h5{
        font-size: clamp(18px, calc(0.7vw + 0.5rem), 60px);
        font-weight: 500;
    }
@media only screen and (max-width: 676px){
    .brokersHeader h1{
        line-height: 2.5rem;
    }
     .brokersHeader span{
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
    .oneBrokerFonts h5{
            line-height:0.94rem !important;
    }
    .rightPrt h4{
        line-height: 1.5rem;
    }

    .topBrokerList span{
        font-weight: 400;
        font-size: 0.625rem;
        line-height: 0.94rem;
    }
    .spacedList{
        gap: 0.8rem;
        list-style: none;
    }
    .ourPicks h4{
        line-height: 1.5rem;
    }
    /* header css */
    .textToImg h4{
        line-height: 1.03rem;
        font-weight: 700;
    }
    .holder{
        padding: 0.4rem 0rem;
    }
    .compareTable h5{
        line-height: 1.5rem;

    }
    .compareTable h6{
        line-height: 1.5rem;

    }
    .providerTitle h4 {
        line-height: 1.56rem;
        font-weight: 600;
    }
    .visitWebsite span{
        font-size: 8px;
        line-height: 0.875rem ;
    }
    .visitWebsite .pickcardBtn{
        font-size: 0.5rem;
        line-height: 0.625rem;
    }
    .providerFeature h6{
        line-height: 2.2rem;
    }
    .feesPrt h4,.accountPrt h4,.promotionsPrt h4{
        line-height: 1.5rem;
        font-weight: 500;
    }
    .topBrokerSM h4{
        line-height: 1.5rem;
    }
     .feesPrt{
        position: absolute;
        top: 47%;
        left: 5%;
    }
    .selectedItems{
        padding: 1rem 1rem;
    }
    .selectFont h5{
        line-height: 1.5rem;

    }
    .accountPrt{
        position: absolute;
        top: 66%;
        left: 5%;
    }
    .promotionsPrt{
        position: absolute;
        top: 85%;
        left: 5%;
    }
    .anyList{
        gap: 0rem !important;
    }

}