
.filters_section {
  width: 30%;
  min-width: 420px;
  padding: 0.5rem 1.5rem;
  position: fixed;
  right: 0rem;
}
.filtersContainer {
  padding: clamp(16px, calc( 1vw + 0.4rem ) , 100px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #181818;
  overflow: auto;
}
.filters{
  position: relative;
}

.filters h4 {
  font-family: Poppins;
  font-size:  clamp(12px, calc( 0.9vw + 0.5rem ) , 70px);
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  margin-bottom: 1rem;
}

.filtersApplaied {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clearAll {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.3rem;
}

.clearAll p {
  font-family: Poppins;
  font-size: clamp(10px, calc( 0.35vw + 0.5rem ), 60px);
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  color: #979797;
}

.clearAll button {
  font-family: Poppins;
  font-size: clamp(10px, calc( 0.35vw + 0.5rem ), 60px);
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  color: #fff;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.filtersApplaiedContainer {
  list-style: none;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #202020;
  border-radius: 7px;
  height: auto;
  padding: clamp(8px,  calc( 0.4vw + 0.1rem ), 32px) clamp(8px,  calc( 0.6vw + 0.1rem ), 32px) ;
  margin-bottom: 1rem;
  min-height: 69.56px;
}

.filtersApplaiedContainer li {
  position: relative;
  font-family: Poppins;
  font-size: clamp(8px, calc( 0.35vw + 0.3rem ), 60px);
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: #202020;
  background: #53ACFF;
  padding: 1px 20px 1px 5px;
  border-radius: 4.42px;
  display: flex;
  align-items: center;
  margin: 0.3rem 0.4rem 0.2rem 0;
  height: 22px;
}

.filtersApplaiedContainer span {
  position: absolute;
  right: 5px;
  cursor: pointer;
}

.filtersStock {
  background: #202020;
  border-radius: 7px;
  padding: clamp(16px,  calc( 0.6vw + 0.5rem ), 60px);
}

.filtersStock h5 {
  font-family: Poppins;
  font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  color: #fff;
}

.filtersStockContainer {
  padding: 0.5rem;
}

.searchInput {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

.searchInput input {
  width: 100%;
  background-color: #313131;
  border: 1px solid #313131;
  border-radius: 5px;
  color: #fff;
  font-size: clamp(10px,  calc( 0.4vw + 0.3rem ), 60px);
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  padding: 5px 15px;
  outline: none;
}

.searchInput input::placeholder {
  color: #737373;
  font-size: clamp(10px,  calc( 0.4vw + 0.3rem ), 60px);
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
}

.searchInput input:focus {
  border: 1px solid #53ACFF;
}

.searchInput img {
  width: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
  height: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 15px;
}

.industryFilter {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 2rem;

}

.selectLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  z-index: 9;
  background: #202020;
  cursor: pointer;
}

.selectLabel img {
  width: 10px;
  transform: rotateZ(-90deg);
  transition: all 0.3s;
}

.industryFilter.open .selectLabel img {
  transform: rotateZ(0deg);
}

.selectLabel h6 {
  font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  color: #fff;
}



.industryFilter .selectItems {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  flex-wrap: wrap;
}


.industryFilter .selectItems li {
  padding: 0.1rem 0;
  width: 50%;
}

.industryFilter .selectItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.1rem 0;
  width: max-content;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
}

.industryFilter .selectItem:hover {
  background-color: #53ACFF;
  color: #202020;
  border-radius: 5px;
}

.industryFilter .selectItem img {
  width: clamp(10px,calc(0.7vw + 0.1rem),60px);
  margin-right: 0.4rem;
}

.industryFilter .selectItem p {
  font-family: Poppins;
  font-size: clamp(10px,  calc( 0.6vw + 0.2rem ), 60px);
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: #fff;
}

.checkBoxFilters {
  display: flex;
  justify-content: space-between;
  gap: 0rem;
}


.MarketCapFilter {
  width: 50%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 2rem;
}


.MarketCapFilter .checkItems {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0;
  flex-wrap: wrap;
  overflow: hidden;
  transition: all 0.3s;
}


.checkItems {
  padding-left: 1rem;
  list-style: none;
}

.checkItems input:checked,
.checkItems input:not(:checked) {
  position: absolute;
  visibility: hidden;
}

.checkItems input:checked+label,
.checkItems input:not(:checked)+label {
  position: relative;
  font-family: Poppins;
  font-size: clamp(10px,  calc( 0.6vw + 0.3rem ), 60px);
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 2rem;
  color: #fff;
}

.checkItems input:checked+label:before,
.checkItems input:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
  height: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
  border: 1px solid #313131;
  border-radius: 100%;
  background: #313131;
}

.checkItems input:checked+label:after,
.checkItems input:not(:checked)+label:after {
  content: '';
  width: 6px;
  height: 6px;
  background: #53ACFF;
  position: absolute;
  top: 50%;
  transform: translate(-50% , -50%);
  left: calc( clamp(10px,  calc( 0.4vw + 0.5rem ), 60px) / 2);
  border-radius: 100%;
  transition: all 0.2s ease;
}

.checkItems input:not(:checked)+label:after {
  opacity: 1;
  background-color: #202020;
}

.checkItems input:checked+label:after {
  opacity: 1;
}


.riskLevelFilter {
  width: 50%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 2rem;
}

.riskLevelFilter.open .selectLabel img {
  transform: rotateZ(0deg);
}

.riskLevelFilter .checkItems {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0;
  flex-wrap: wrap;
  transition: all 0.3s;
}


.scrollSelect h6 {
  text-align: center;
}

.scrollSelect {
  display: flex;
  justify-content: start;
  gap: 10%;
}



.scrollSelect .selectLabel {
  justify-content: center;
}

.strategyFilter,
.AssetFilter {
  width: 40%;
}



.selectContainer {
  position: relative;
  overflow: hidden;
  /* max-height: 82px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;*/
  overflow: hidden; 
  box-shadow: inset 0px 0px 19px 6px #202020;
}

.selectContainer::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 0;
  box-shadow: 0px 0px 13px 13px #202020;
  z-index: 3;
}
.selectContainer::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  box-shadow: 0px 0px 13px 13px #202020;
  z-index: 3;
}

/* .selectContainer::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 0;
  box-shadow: 0px 0px 19px 6px #000000c7;
}

.selectContainer::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  box-shadow: 0px 0px 13px 4px #00000061;
} */

/* .scrollSelect .strategyFilter .selectContainer::after,
.scrollSelect .AssetFilter .selectContainer::after{
  content: "";
  position: absolute;
  top: 30px;
  width: 100%;
  border-radius: 5px;
  height: 30px;
  background-color: #53ACFF;
  z-index: 99;
} */

.selectContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 0px;
  background-color: transparent;
  width: 0;
  visibility: hidden;
}



.selectContainer::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.selectContainer::-webkit-scrollbar-thumb {
  border-radius: 0;
  width: 0;
  -webkit-box-shadow: none;
  background-color: #424242;
}

.selectContainer .selectItem {
  position: relative;
  transition: all 0.3s;
  height: 30px;
}

.selectContainer .selectItem input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.selectContainer .selectItem label {
  color: #fff;
  font-family: Poppins;
  font-size: clamp(10px, calc(0.3vw + 0.5rem), 60px);
  font-weight: 400;
  letter-spacing: 0px;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  text-wrap: nowrap;
  width: 100%;
  height: 100%;
  text-align: center;
}

.activeBox {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  width: 100%;
  background-color: #53ACFF;
  border-radius: 5px;

}

/* .selectContainer .selectItem input[checked]+label {
  color: #fff;
} */

.applyBtn {
  font-family: Poppins;
  font-size: clamp(10px, calc(0.3vw + 0.5rem), 60px);
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  padding: 0.5rem 1.5rem;
  width: clamp(10px, calc(7vw + 0.5rem), 300px);
  background-color: #53ACFF !important;
  border: none;
  border-radius: 6px;
  margin: 1.5rem 0 0;
  outline: 0;
  cursor: pointer;
  transition: all 0.3s;
}

.applyBtn:hover {
  box-shadow: inset 0px -20px 30px -10px #165B9C;
}

.saveFilterBtn{
  font-family: Poppins;
  font-size: clamp(10px, calc(0.3vw + 0.5rem), 60px);
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  padding: 0.5rem 1.5rem;
  width: clamp(10px, calc(7vw + 0.5rem), 300px);
  background-color: transparent;
  border-radius: 6px;
  margin: 1.5rem 0 0;
  border: 1px solid #fff;
  outline: 0;
  cursor: pointer;
  transition: all 0.3s;
}

.saveFilterBtn:hover{
  background: #53acff;
  border: 1px solid #53acff;
}

.closeFilter{
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size:  clamp(10px,  calc( 0.9vw + 0.5rem ), 60px);
  position: absolute;
  top: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
  right: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
}

@media only screen and (max-width: 1280px) {

  .filters_section {
    min-width: auto;
    position: fixed;
    width: 100% !important;
    height: 100vh;
    top: 0;
    left: 0;
    background: #00000091;
    z-index: 9999999999999;
    padding: 1rem;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
  }

  .filtersContainer {
    border-radius: 15px;
    padding: 2rem 1rem;
  }

  .filters{
    max-height: 100%;
    overflow-y: scroll;
  }

  /* .filters.open::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 100vh;
    right: 100%;
    top: 0;
    background: transparent;
    z-index: 999;
  } */

  .filters_section.open {
    visibility: visible;
    opacity: 1;
  }

  .filters .filtersStock {
    padding: 0.5rem;
  }


  .scrollSelect {
    gap: 2rem;
  }

  .industryFilter .selectItems {
    padding: 0;
  }

  .applyBtn , .saveFilterBtn {
    margin: 1rem 0 0.5rem;
    font-size: 14px;
    line-height: 20px;
    width: 140px;
  }

}

