.table {
    width: 100%;
    /* overflow-y: auto;
    overflow-x: auto; */
    text-wrap: nowrap;
    animation: slidUp 1s ease;
    animation-iteration-count: 1;
  }
  
  .table::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 10px;
    background-color: transparent;
  
  }
  
  .table::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  
  .table::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: none;
    background-color: #424242;
  }
  
  .table img {
    width: clamp(10px,  calc( 0.9vw + 0.5rem ), 65px);
  }
  
  .tableItemContainer {
    margin-bottom: var(--sy-19px);
  }
  
  .tableItem {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: var(--12px);
    padding: clamp(8px,  calc( 0.4vw + 0.3rem ), 32px);
    width: 100%;
    position: relative;
    z-index: 2;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .tableItem:hover {
    background-color: #53ACFF;
  }
  
  .tableItem li {
    flex: 1;
    display: flex;
    gap: 2rem;
    color: #fff;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 1rem;
  }
  
  .tableItem li:not(:last-child) {
    border-right: 1px solid #414040;
    transition: all 0.3s;
  }
  
  .tableItem:hover li:not(:last-child) {
    border-right: 1px solid #fff;
  }
  
  .tableItem li p {
    font-size: clamp(10px,  calc( 0.6vw + 0.5rem ), 65px);
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
  }
  
  .tableItem p.UP {
    color: #118F4B;
  }
  
  .tableItem p.DOWN {
    color: #D94111;
  }
  
  .tableItemCollaps {
    transform: translateY(-6px);
    width: 100%;
    background-color: #181818;
    position: relative;
    z-index: 1;
    padding: 2rem 3rem;
    display: none;
    border-bottom-right-radius: var(--12px);
    border-bottom-left-radius: var(--12px);
  }
  
  .tableItemCollaps p {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    text-wrap: wrap;
  }
  
  .tableItemCollaps p:first-child {
    margin-bottom: 1rem;
  }
  
  .tableItemCollaps p span {
    font-weight: 600;
  }
  
  .tableItemCollaps p a {
    color: #53ACFF;
  }
  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 1024px) {
    .tableItem li {
      gap: 1rem;
    }
  
    .table {
      padding: 0 0rem;
    }
  
    .tableItemCollaps {
      padding: 1.5rem;
    }
  
    .tableItem li {
      gap: 0.3rem;
      padding: 0.2rem 0.5rem;
  }
  
  .tableItem li p {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
  }
  .table img {
    width: 12px;
  }
  .tableItemCollaps p {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
  }
  }
  
  
  
  @keyframes slidUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }