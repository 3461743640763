section.trainings {
    padding: 15% 5% 0;
    border-top: 1px solid #5D5D5D;
    position: relative;
}

.blog {
    margin-bottom:  clamp(30px, calc(3vw + 0.1rem), 200px);
}

.blog-title {
    display: flex;
    align-items: center;
    margin-bottom: clamp(24px, calc(1vw + 0.1rem), 100px);
}

.blog-title svg {
    margin-right: 44px;
}

.blog-title h6 {
    font-size: clamp(12px, calc( 1.3vw + 0.1rem ) , 70px);
    font-weight: 500;
}

.blog p {
    margin-left: calc(44px +  clamp(30px, calc(2vw + 0.1rem), 200px));
    font-weight: 200;
    font-size:  clamp(10px, calc(1vw + 0.1rem), 60px);
}

.trainings-img img {
    width: calc(40vw + 0.1rem);
    position: absolute;
    right: 0;
    top: 10%;
}

.signUpNow {
    margin: calc(10vw + 0.1rem) calc(10vw + 0.1rem) 0;
    border-radius: 15px;
}

.signUpNow.border-animate {
    border-radius: 15px;
}

.signUpNow.border-animate::before {
    background-image: conic-gradient(transparent 0deg, var(--Blue300) 78deg, transparent 107deg, var(--Blue300) 256deg, transparent 290deg);
}

.signUpNow-body {
    background-color: #202020;
    position: relative;
    border-radius: 15px;
}

.signUpNow-content {
    padding: clamp(4rem, calc(4vw + 0.1rem), 400px) clamp(2rem, calc(2vw + 0.1rem), 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    box-shadow: inset 0px 0px 43px -10px #000;
    background-image: url("../../../assets/electric-bg.png");
    background-size: cover;
}

.signUpNow h4 {
    font-size:  clamp(18px, calc(2.4vw + 0.1rem), 100px);
    font-weight: 700;
    margin-bottom: clamp(16px, calc(1.4vw + 0.1rem), 200px);
    text-align: center;
}

.signUpNow h4>span {
    padding: 0 0.5rem;
    background-color: var(--Blue300);
    border-radius: 5px;
}

.signUpNow a {
    padding: clamp(1rem , calc(0.9vw + 0.1rem), 200px) clamp(3rem , calc(3vw + 0.1rem), 400px);
    background-color: var(--Blue300);
    font-size: clamp(10px, calc(1vw + 0.1rem), 48px);
    font-weight: 600;
    border-radius: 4px;
    border: none;
    transition: all 0.3s;
}

.signUpNow a:hover{
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}

.trainings-content {
    overflow: auto;
}

.trainings-content .blog .blog-title svg{
    width: clamp(30px, calc(2vw + 0.1rem), 200px);
    height: clamp(30px, calc(2vw + 0.1rem), 200px);
}

@media only screen and (max-width: 1024px) {
    section.trainings {
        padding: 20% 0rem 0%;
    }

    .trainings-container {
        padding-left: 0%;
    }

    .trainings-content {
        display: flex;
        overflow-x: scroll;
        gap: 1rem;
        width: 100%;
        margin: 0 auto 10%;
    }

    *::-webkit-scrollbar {
        width: 0px;
        display: none;
    }

    .trainings-content .blog {
        display: flex;
        flex-direction: column;
        min-width: 75%;
        gap: 1rem;
    }

    .trainings-content .blog .blog-title {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 0;
    }


    .trainings-content .blog p {
        margin-left: 0;
        text-align: center;
    }

    .trainings-img img {
        position: unset;
        width: 100%;
    }

    .signUpNow {
        width: 100%;
    }

    .signUpNow .signUpNow-body,.signUpNow.border-animate , .signUpNow .signUpNow-content{
        border-radius: 0;
    }

    .signUpNow.border-animate{
        padding: 1px 0;
    }

    .signUpNow .signUpNow-body .signUpNow-content a {
        padding: 0.7rem 2rem;
    }
}