section.trading{
    padding: 10% 10% 0;
    border-top: 1px solid #5D5D5D;
    position: relative;
    background-image: url("../../../assets/tradingBgShadow.png");
    background-position: center left;
    background-size: contain;
    background-repeat: no-repeat;
}

section.trading .trading-container .img-container{
    padding: clamp(12px, calc(3vw + 0.1rem), 700px);
    backdrop-filter: blur(30px);
    background: linear-gradient(90deg, rgba(83,172,255,0.1) 0%, rgba(35,53,70,0.1) 100%);
    border: 1px solid #eeeeee23;
    border-radius: 35.74px;
    box-shadow: 0px 15px 50px -3px #000;
    width: 100%;
    margin-left: auto;
}

.trading-content h4{
    color: var(--Blue300);
    font-size: clamp(24px , calc(2.4vw + 0.1rem) , 300px);
    font-weight: 800;
    line-height : normal;
}
.trading-content h4+p{
    color: #A4A4A4;
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 160px);
    font-weight: 400;
}
.trading-content h5{
    font-size: clamp(12px, calc(1.2vw + 0.1rem), 170px);;
    font-weight: 700;
}
.trading-content h5+p{
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 160px);
    font-weight: 400;
}

.private-companies-box{
    padding: clamp(16px , calc(2vw + 0.1rem) , 300px);
    box-shadow: inset 0px 15px 50px -3px #000000b9;
    background-image: url("../../../assets/privat\ company\ bg.png");
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: clamp(10px, calc(1vw + 0.1rem), 160px);
}
.private-companies-box .item{
    width: 48%;
    padding: clamp(10px, calc(0.7vw + 0.1rem), 160px) clamp(10px, calc(1vw + 0.1rem), 160px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #313131;
    border-radius: 15px;
    gap: clamp(10px, calc(0.8vw + 0.1rem), 160px);
}
.private-companies-box .item img{
    width: 50%;
}
.private-companies-box .item .price{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clamp(10px, calc(0.5vw + 0.1rem), 100px);
    padding: clamp(6px, calc(0.5vw + 0.1rem), 100px) 0 clamp(6px, calc(0.5vw + 0.1rem), 100px) clamp(12px, calc(0.9vw + 0.1rem), 100px);
    border-left: 1px solid #fff;

}
.private-companies-box .item .price p{
    font-size: clamp(10px, 1vw + 0.1rem, 60px);
    text-wrap: nowrap;
}

.private-companies-box .price svg{
    width: clamp(10px, 0.6vw + 0.1rem, 60px);
}

.private-companies-box .price.up svg{
    transform: rotateX(180deg);
}
.private-companies-box .price.up svg path{
    fill: #118F4B;
}
.private-companies-box .price.down svg{
    transform: rotateX(0deg);
}
.private-companies-box .price.down svg path{
    fill: #D94111;
}

.spaceX{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: clamp(16px, calc(1.5vw + 0.1rem), 160px);
    position: relative;
    background: linear-gradient(166deg, rgba(105,180,255,0.02189250700280112) 0%, rgba(32,32,32,0.9183648459383753) 46%);
    border-radius: 10px;
    gap: 4rem;
    overflow: hidden;
}
.spaceX img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.spaceX .box {
    width: 35%;
    min-height: clamp(6rem, calc(4vw + 0.1rem), 700px);
    border: 1px solid var(--Blue300);
    border-radius: 5px;
}
.spaceX h6{
    font-size: clamp(12px, calc(1.3vw + 0.1rem), 70px);
    font-weight: 600;
}

@media only screen and (max-width: 1300px) {
    .private-companies-box .item .price p{
        font-size: 13px;
    }
    
}
@media only screen and (max-width: 1100px) {
    .private-companies-box {
        padding: 2rem 1rem;
        border-radius: 5px;
    }
    .private-companies-box .item {
        width: 48%;
        padding: 0.7rem;
        gap: 0.5rem;
        border-radius: 5px;
    }
    .private-companies-box .item .price {
        gap: 0.5rem;
        font-size: 10px;
        padding: 0rem 0.3rem 0rem 0.5rem;
    }
    .private-companies-box .item .price svg{
        width: 7px;
    }
    .spaceX .box {
        min-height: 4rem;
    }
}
@media only screen and (max-width: 600px) {
    .trading-content h4 {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 2.5rem;
    }
    .trading-content h4+p {
        display: none;
    }
    .trading-content h5 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 0.7rem;
    }
    .trading-content h5+p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 1.5rem;
    }

    .spaceX {
        padding: 1rem;
        gap: 1rem;
    }
    .spaceX h6 {
        font-size: 14px;
    }
    section.trading {
        padding: 20% 5% 0;
    }
    .section-title {
        left: 10%;
        padding: 0 1rem;
        font-size: 32px;
    }

}



/* 
@media only screen and (min-width: 2224px) {

    .trading-content h4 {
        color: var(--Blue300);
        font-size: 64px;
        font-weight: 800;
        line-height: 80px;
    }

    .trading-content h4+p {
        color: #A4A4A4;
        font-size: 28px;
    }
    .trading-content h5 {
        font-size: 36px;
    }
    .trading-content h5+p {
        font-size: 28px;
    }

} */