.training_navBar {
    width: 100%;
}

.training_navBar ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8rem;
}

.training_navBar ul li a {
    font-size: 13px;
    font-weight: 400;
    line-height: 24.32px;
    text-align: left;

}

.supTitle {
    padding: 0.3rem 0.7rem;
    width: max-content;
    border-radius: 10px;
    background-color: var(--Blue300);
    font-size: 22px;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
}

.searchInput {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
}

.searchInput input {
    width: 100%;
    background-color: #313131;
    border: 1px solid #313131;
    border-radius: 5px;
    color: #ffff;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    padding: 8px 20px;
    outline: none;
    transition: all 0.3s;
}

.searchInput input::placeholder {
    color: #ffff;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 400;
    letter-spacing: 0px;
}

.searchInput input:focus {
    border: 1px solid #53ACFF;
}

.searchInput img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
}

.st_suite_wiki {
    padding: 3rem 1.5rem;
    background: rgba(24, 24, 24, 1);
    border-radius: 8px;
    position: fixed;
    right: 1%;
}

.st_suite_wiki h6 {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.3rem;
}

.st_suite_wiki ul {
    margin-bottom: 3rem;
    list-style: outside;
    padding-left: 1rem;
}

.st_suite_wiki ul li {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.4rem ), 60px);
    font-weight: 400;
    text-align: left;
}

.st_suite_wiki ul li>a {
    color: var(--Blue300);
}


.most_liked_trainings h5 {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.6vw + 0.5rem ), 60px);
    font-weight: 700;
    text-align: left;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card {
    padding: 2rem;
    background: rgba(32, 32, 32, 1);
    box-shadow: 0px 0px 12px 10px rgba(0, 0, 0, 0.25) inset;
    border-radius: 15px;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card h6 {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 700;
    text-align: left;
    margin-bottom: 1rem;
}

.most_liked_trainings .most_liked_training_card ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.most_liked_trainings .most_liked_training_card li {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.3vw + 0.5rem ), 60px);
    font-weight: 500;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}


.trainings_courses ul li{
    width: max-content;
} 

.trainings_courses ul li:first-child{
    border-right: 1px solid #fff;
}

.trainings>a {
    display: block;
}

.course_card {
    padding: 1.5rem 3rem 1.5rem 1.5rem;
    background: rgba(32, 32, 32, 1);
    border-radius: 15px;
    position: relative;
    box-shadow: 10px 12px 12.199999809265137px 0px rgba(0, 0, 0, 0.25) inset;
}


.border_animate {
    position: relative;
    padding: 1px;
    border-radius: 15px;
    z-index: 0;
    overflow: hidden;
}

.border_animate::before {
    content: "";
    background-image: conic-gradient(#ffffff4b 0deg, #ffffff4b 260deg, #fff 270deg 300deg, #ffffff4b 360deg);
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    border-radius: 15px;
    transition: all 0.7s;
}

.course_card .course_card_info h4 {
    width: 70%;
    font-size: clamp(10px,  calc( 0.7vw + 0.5rem ), 60px);
    font-weight: 600;
    text-align: left;
}

.course_card .course_card_info p {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.3vw + 0.4rem ), 60px);
    font-weight: 400;
    color: rgba(151, 151, 151, 1);
    width: 70%;
}

.course_card .course_card_info span {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.3vw + 0.4rem ), 60px);
    font-weight: 400;
    text-align: left;
    text-wrap: nowrap;
    color: rgba(151, 151, 151, 1);
}

.course_card .course_card_info .enrolled_date {
    padding: var(--6px) var(--9px);
    border-radius: var(--10px);
    background-color: var(--Blue300);
    text-align: center;
}

.course_card .course_card_info .enrolled_date p {
    font-family: Poppins;
    font-size: var(--16px);
    font-weight: 700;
    text-wrap: nowrap;
    color: #fff;
    line-height: var(--20px) !important;
}

.course_card .course_card_info .enrolled_date span {
    font-size: var(--11px);
    font-weight: 400;
    text-wrap: nowrap;
    color: #fff;
    line-height: var(--16px) !important;
}

.course_card .course_card_info span.completed {
    padding: 0.3rem 0.6rem;
    border-radius: 5px;
    background-color: var(--Blue300);
    text-align: center;
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 700;
    color: #fff;
}


@media only screen and (max-width:1024px) {

    .supTitle{
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
    }
    .st_suite_wiki {
        display: none;
    }
    .course_card {
        padding: 1rem;
    }
    .course_card .course_card_info h4 {
        font-size: 13px;
        font-weight: 700;
        line-height: 19.5px;
    }
    .course_card .course_card_info .enrolled_date {
        padding: 0.3rem 0.5rem 0.1rem;
    }
    .course_card .course_card_info .enrolled_date p {
        font-size: 11px;
        font-weight: 700;
        line-height: 12px;
    }
    .course_card .course_card_info .enrolled_date span {
        font-size: 10px;
        font-weight: 400;
        line-height: 10px;
    }

    .course_card .course_card_info span {
        font-size: 10px;
        font-weight: 400;
        line-height: 14.32px;
    }

    .course_card .course_card_info span.completed {
        padding: 0.3rem 0.4rem;
        font-size: 11px;
        line-height: 16.5px;
    }
    .course_card ul li {
        font-size: 10.45px;
        font-weight: 500;
        line-height: 15.68px;
    }
    
    .course_navBar ul {
        display: none;
    }
}
.border_animate:hover::before {
    transform: translate(-50%, -50%) rotate(180deg);
}
.border_animate::before {
    width: 250%;
    height: 450%;
}