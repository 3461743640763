section.plans {
    border-top: 1px solid #5D5D5D;
    position: relative;
    background-image: url("../../../assets/PlansBgShadow.png");
    background-position: right top;
    background-size: contain;
    background-repeat: no-repeat;
}

section.plans .section-title {
    color: #fff;
    font-size:  clamp(22px, calc(3.1vw + 0.5rem),140px);
    left: 50%;
    transform: translate(-50%, -50%);
    text-wrap: nowrap;
}

.plans-container {
    max-width: 90%;
    padding: 10% 5% 7%;
}

.plane {
    box-shadow: 0px 18.83098602294922px 29.591548919677734px 0px #00000040;
    border-radius: var(--21px);
    padding: 1px;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    transition: all 0.7s;

}

.plane::before {
    content: "";
    background-image: conic-gradient(transparent 180deg, #c2c2c2 270deg, transparent 360deg);
    width: 180%;
    height: 180%;
    transform: translate(-50%, -50%) rotate(0);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transition: all 0.7s;
}

.plane:hover {
    transform: rotateY(10deg);
}

.plane.recommended .plane-body {
    background: linear-gradient(150.99deg, rgba(54, 110, 163, 0.24) 9.75%, rgb(86 173 255 / 26%) 72.13%);

}

.plane.recommended::before {
    background-image: conic-gradient(transparent 180deg, var(--Blue300) 270deg, transparent 360deg);
}

.plane.recommended::after {
    content: "";
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 1px;
    left: 1px;
    background-color: #202020;
    border-radius: var(--21px);
    z-index: 1;
}

.plane:hover::before {
    transform: translate(-50%, -50%) rotate(180deg);
}

.plane.recommended:hover::before {
    transform: translate(-50%, -50%) rotate(-180deg);
}

.plane-body {
    height: 100%;
    padding: clamp(32px, calc(3vw + 0.1rem), 400px) clamp(12px, calc(1.7vw + 0.1rem), 400px);
    background: #252525;
    border-radius: var(--21px);
    position: relative;
    z-index: 2;
}

.plane-title {
    font-size: clamp(12px, calc(1vw + 0.5rem), 70px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.plane .line {
    width: 90%;
    height: 1px;
    background-color: #414040;
    margin: 2.5rem 0;
}

.plane.recommended .line {
    background-color: #ffffff4f;
}

.plane .plane-points li {
    font-size: clamp(10px, calc(0.9vw + 0.1rem), 60px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
}

.plane .plane-points li::before {
    content: "";
    width: 5px;
    height: 5px;
    background-color: #fff;
    position: absolute;
    left: -1rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
}

.plane-price p {
    font-family: Poppins;
    font-size: clamp(12px, calc(0.8vw + 0.5rem), 70px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #5D5D5D;
    margin-bottom: 2rem;
}

.plane-price p>span {
    font-family: Poppins;
    font-size:  clamp(32px, calc(3vw + 0.5rem),120px);;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
}

.plane-action a {
    position: relative;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(8px, calc(0.4vw + 0.5rem), 40px);
    font-weight: 600;
    transition: all 0.3s;
    padding: 0.5rem 2rem;
    
}
.plane-action.first a {

    border: 1px solid white;
} 

.plane-action a:hover {
    background-color: var(--Blue300);
    box-shadow: none;
}

.recommended-plane .plane-action a {
    background: var(--Blue300);
    box-shadow: none;
}

.recommended-plane .plane-action a:hover {
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}

.recommended-plane h6 {
    text-align: center;
    font-family: Poppins;
    font-size: clamp(10px, calc(0.6vw + 0.5rem), 60px);
    font-weight: 400;
    letter-spacing: 0em;
    margin-top: 1.5rem;
}

@media only screen and (max-width: 1024px) {
    section.plans {
        padding: 10% 0 0% 1rem;
        margin-top: 0;
    }

    .plans-container {
        max-width: 100%;
    }

    section.plans .plans-container{
        width: 100%;
        margin: auto;
    }
    section.plans .plans-container .plane {
        min-width: 250px;
    }

    .plane .line {
        margin: 1.5rem 0;
    }

    .plane .plane-points li {
        font-family: Poppins;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
    }

    .plane-price p {
        margin-bottom: 1rem;
    }

    .plane .plane-action a {
        width: 80px;
        height: 30px;
    }

    .plans-container{
        overflow-x: auto;
    }
}



/* @media only screen and (min-width: 2224px) {

    section.plans .section-title {
        color: #fff;
        font-size: 80px;
    }

    .plane-title {
        font-size: 36px;
    }
    
    .plane .plane-points li {
        font-size: 22px;
        font-weight: 400;
        line-height: 29px;
    }

    .plane .plane-body{
        padding: 6rem 3rem;
    }

    .plane-action a {
        position: relative;
        width: 150px;
        height: 50px;
        font-size: 22px;
        line-height: 22px;
    }

    .recommended-plane h6 {
        font-size: 26px;
    }
} */