.withdraw_funds {
    padding: clamp(10px,  calc( 3vw + 0.1rem ), 80px);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25) inset;
    border-radius: 5px;
}

.withdraw_funds .card_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.withdraw_funds .card_header h4 {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 1.8vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;
    margin: 2.5rem 0 1.5rem;
}

.withdraw_funds .transfer_methods {
    display: flex;
    justify-content: space-between;
    gap: clamp(10px,  calc( 2vw + 0.1rem ), 80px);
}



.withdraw_funds .method_item {
  width: 25%;
  background: rgba(32, 32, 32, 1);
  box-shadow: inset 0px 0px 20px 0px #00000069;
  border-radius: 10px;
  border: 1px solid #00000069;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: all 0.3s;

}

.withdraw_funds .method_item input {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 2;
opacity: 0;
cursor: pointer;
}

.withdraw_funds .method_item:has(input:checked) {
  background: var(--Blue300);
  border: 1px solid var(--Blue300);
  box-shadow: none;
}

.withdraw_funds .method_item p:first-child {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 400;
    text-align: left;
}

.withdraw_funds .method_item p:last-child {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;
}

.withdraw_funds .method_item img {
    width: clamp(25px,  calc( 2vw + 0.1rem ), 80px);
}

.withdraw_funds .add_more {
    width: 25%;
}

.withdraw_funds .add_more button {
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 10px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clamp(10px,  calc( 3vw + 0.1rem ), 80px);
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;
    box-sizing: content-box;
    transition: all 0.3s;
}

.withdraw_funds .add_more button:hover {
    background: #53acff;
    border: 1px solid #53acff;
}

.wire_amount h5 {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 1.1vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;
    margin: 2.5rem 0 1.5rem;
}




.input_group {
    display: flex;
    flex-direction: column;
}

.input_group label {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;
    margin-bottom: 1rem;
}

.input_group input {
    padding: 4px 10px;
    background-color: rgba(255, 255, 255, 0.16);
    color: #fff;
    outline: none;
    box-shadow: none;
    font-size: clamp(10px,  calc( 0.8vw + 0.1rem ), 80px);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.16);

}

.input_group input:last-child {
    background-color: transparent;
    border: 1px solid #fff;
}

.input_group span {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.7vw + 0.1rem ), 80px);
    font-weight: 400;
    text-align: left;
    padding: 0.2rem 1rem;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}


.check_input_group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.check_input_group label {
    position: relative;
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.7vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;

    cursor: pointer;
}

.check_input_group label:before {
    content: '';
    background-color: transparent;
    border: 1px solid #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 4px;
}

.check_input_group input:checked+label::before {
    background-color: var(--Blue300);
    border: 1px solid var(--Blue300);
}

.check_input_group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 7px;
    width: 4px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: all 0.3s;
}

.confirm_btn {
    display: block;
    font-size: clamp(10px,  calc( 0.8vw + 0.1rem ), 80px);
    font-weight: 600;
    text-align: center;
    padding: 10px 3rem;
    background-color: var(--Blue300);
    border-radius: 4px;
    outline: none;
    border: none;
    margin: 2rem 0 0 auto;
    transition: all 0.3s;
}
.confirm_btn:hover{
    box-shadow: inset 0px -20px 30px -10px #165B9C;

}

.available_cash li {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.available_cash li p:first-child {
    width: 50%;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 400;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.available_cash li p:first-child span {
    font-size: clamp(10px,  calc( 0.7vw + 0.1rem ), 80px);
    font-weight: 400;
    color: #979797;
}


.available_cash li p:last-child {
    width: 30%;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 700;
    color: #118F4B;
    display: flex;
    justify-content: space-between;
    gap: clamp(10px,  calc( 1vw + 0.1rem ), 80px);
}

.available_cash li p:last-child span {
    width: 50%;
    color: #118F4B;
}

.available_cash li:last-child p:last-child {
    color: #D94111;
}

.available_cash li:last-child p:last-child span {
    color: #D94111;
}


.withdraw_funds .complete_icon {
    width: clamp(18px,  calc( 1.4vw + 0.1rem ), 80px);
    height: clamp(18px,  calc( 1.4vw + 0.1rem ), 80px);
    border-radius: 50%;
    background-color: rgba(70, 70, 70, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.withdraw_funds .complete_icon svg {
    stroke: #0000008e;
    line-height: 0;
    width: clamp(10px,  calc( 0.7vw + 0.1rem ), 80px);
}

.withdraw_funds .complete_icon.completed {
    background-color: var(--Blue300);
}




@media only screen and (max-width: 1000px) {

    .withdraw_funds {
        padding: 2rem 1rem;
    }

    .withdraw_funds .card_header h4 {
        font-size: clamp(16px,  calc( 0.9vw + 0.1rem ), 80px);
        font-weight: 700;
  
        text-align: left;
    }

    .withdraw_funds .transfer_methods {
        flex-direction: column;
        gap: 1rem;
        padding: 0 0.5rem;
    }

    .withdraw_funds .method_item {
        width: 100%;
    }

    .withdraw_funds .transfer_methods .method_item {
        width: 100%;
        padding: 1rem 2rem;
    }

    .withdraw_funds .transfer_methods .add_more {
        width: 100%;
        height: 75px;
    }

    .withdraw_funds .transfer_methods .method_item p:first-child {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    .withdraw_funds .transfer_methods .method_item p:last-child {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
    }

    .withdraw_funds .transfer_methods .add_more button {
        font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
        font-weight: 400;
        line-height: 20px;
    }

    .input_group label {
        margin-bottom: 0.8rem;
        font-size: 14px;
        font-weight: 700;
        line-height: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    }

    .input_group .check_input_group label {
        font-size: 11px;
        font-weight: 700;
        line-height: 11px;
    }

    .confirm_btn {
        width: 140px;
        margin: 2rem auto 1rem;
        font-size: 12px;
    }

    .input_group input {
        padding: 3px 10px;
        font-size: 12px;

    }

    .bank_transfer_transaction_card {
        padding: 2rem 1rem;
    }

    .bank_transfer_transaction_card .card_header h4 {
        font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
  
    }

    .wire_amount h5 {
        font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
        font-weight: 700;
  
        text-align: left;
    }

    .available_cash li {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
        flex-direction: column;
        margin-bottom: 1.5rem;
    }

    .available_cash li p:first-child {
        width: 100%;
    }

    .available_cash li p:last-child {
        width: 100%;
        justify-content: end;
    }

    .available_cash li p:last-child span {
        width: auto;
    }


}