.trading_history_table .table_head {
    background: rgba(32, 32, 32, 1);
    box-shadow: 0px 0px 13.7px 3px rgba(0, 0, 0, 0.25);
}

.trading_history_table .table_head li {
    display: block;
    text-align: center;
    padding: clamp(10px, calc(0.6vw + 0.1rem), 60px) clamp(10px, calc(0.3vw + 0.1rem), 60px);
    font-family: Poppins;
    font-size: clamp(10px, calc(0.6vw + 0.1rem), 60px);
    font-weight: 700;
}

.trading_history_table .table_body {
    background: radial-gradient(circle, rgba(255, 255, 255, 0.7) 0%, rgba(32, 32, 32, 1) 100%);
    position: relative;
}

.trading_history_table .table_body::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25) inset;

}

.trading_history_table .table_body ul li {
    padding: clamp(10px, calc(0.8vw + 0.1rem), 60px) clamp(10px, calc(0.3vw + 0.1rem), 60px);
    background: rgba(32, 32, 32, 1);
    display: block;
    text-align: center;
    font-family: Poppins;
    font-size: clamp(10px, calc(0.6vw + 0.1rem), 60px);
    font-weight: 400;

}

.trading_history_filter {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25) inset;
    padding: var(--sy-19px) 1.5rem;
}

.trading_history_filter h6 {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 60px);
    font-weight: 700;
    text-align: center;
    width: 100%;
    padding: 0 0 var(--sy-15px);
    border-bottom: 1px solid #fff;
    margin: 0 0 1rem;

}

.trading_history_filter .drop_down_menu{
    margin-bottom: var(--sy-16px);
}
.trading_history_filter .drop_down_menu p{
    font-size: clamp(10px,  calc( 0.6vw + 0.1rem ), 60px);
    font-weight: 400;
    text-align: left;
    margin-bottom: 0.6rem;
}
.trading_history_filter .drop_down_menu button {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.6vw + 0.1rem ), 60px);
    font-weight: 400;
    line-height: 16.5px;
    text-align: left;
    padding: 0.6rem 1rem;
    border: none;
    background: rgba(32, 32, 32, 1);
    border-radius: 4px;
    box-shadow: 0px -1px 12.100000381469727px 0px rgba(0, 0, 0, 0.44) inset;
}


.trading_history_filter > button {
    padding: 8px 2rem;
    font-size: clamp(10px, calc(0.6vw + 0.1rem), 60px);
    font-weight: 600;
    border-radius: 5px;
    background-color: #53ACFF;
    color: #fff;
    outline: none;
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    margin: 1rem auto;
    display: block;
    transition: all 0.3s;
}
.trading_history_filter > button:hover {
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}

.trading_history_filter > button:last-child {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
}

.trading_history_filter > button:last-child:hover {
    background: #53acff;
    border: 1px solid #53acff;
    box-shadow: none;
}


@media only screen and (max-width: 1042px) {

.trading_history_table{
    display: flex;
    flex-direction: row;
}

.trading_history_table .table_head{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
    position: relative;
    z-index: 2;
}

.trading_history_table .table_head li{
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    position: relative;
}

.trading_history_table .table_head li::after{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 0.5px;
    background-color: #ffffff70;
}
.trading_history_table .table_head li:last-child:after{
    content: unset;
}

.trading_history_table .table_body{
    display: flex;
    flex-direction: row;
    overflow: auto;
    max-width: 65%;
}

.trading_history_table .table_body ul{
    display: flex;
    flex-direction: column;
    min-width: 100px;
}
.trading_history_table .table_body ul li{
font-size: 10px;
font-weight: 300;
line-height: 15px;
text-align: left;

}

.trading_history_filter > button {
    width: 100%;
}

}