.companiesCarousel {
    padding:  clamp(3rem , calc(3vw + 0.1rem), 400px) 0;
    box-shadow: 0px 15px 50px -3px #00000088;
    position: relative;
}

.companiesCarousel::before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(32, 32, 32, 0.8183648459383753) 67%, rgba(255, 255, 255, 0.5) 100%);
}

.companiesCarousel::after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(32, 32, 32, 0.8183648459383753) 37%, rgba(255, 255, 255, 0.5) 100%);
}

.companiesCarousel .slick-slide img {
    margin: auto;
    width: 80%;
}


