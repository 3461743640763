section.acrossGlobal {
    padding: 0% 5%;
    margin-top: 5vw !important;
}

section.acrossGlobal .acrossGlobal-container {
    width: 70vw;
    box-shadow: 0px 17.025px 32px 2px rgba(0, 0, 0, 0.45);
    border-radius: 50px;
}

section.acrossGlobal .acrossGlobal-container .border-animate{
    border-radius: 50px;
}

section.acrossGlobal .acrossGlobal-container .border-animate::before{
    background-image: conic-gradient(transparent 180deg, #ffffff5d 240deg, #ffffff5d 280deg, transparent 360deg);
    transform: translate(-50%, -50%) rotate(180deg);
}
section.acrossGlobal .acrossGlobal-container .border-animate:hover::before{
    transform: translate(-50%, -50%) rotate(0);
}
section.acrossGlobal .acrossGlobal-container .acrossGlobal-box {
    padding: clamp(22px, calc(4.1vw + 0.5rem), 140px);
    position: relative;
    background: rgb(25, 57, 85);
    background: linear-gradient(142deg, #395065, #202020 60%);
    border-radius: 50px;
    box-shadow: 0px 21.025px 33.04px 0px rgba(0, 0, 0, 0.25);
}

.acrossGlobal-box h2{
    font-size: clamp(22px, calc( 5.1vw + 0.5rem ) ,220px);
    font-weight: 700;
    text-align: center;
    margin-bottom: 3rem;
    text-shadow: 0px 10px 10px #000;
}

.acrossGlobal-box h5{
    font-size: clamp(18px, calc( 3vw + 0.5rem ) ,160px);
    font-weight: 600;
    text-align: center;
    text-shadow: 0px 10px 10px #000;
}




@media only screen and (max-width: 1024px) {

    section.acrossGlobal {
        padding: 0% 5%;
        margin-top: 5vw !important;
    }
    section.acrossGlobal .acrossGlobal-container {
        width: 90vw;
        border-radius: 20px;
    }
    
    section.acrossGlobal .acrossGlobal-container .border-animate{
        border-radius: 20px;
    }
    section.acrossGlobal .acrossGlobal-container .acrossGlobal-box {
        border-radius: 20px;
        padding: 2rem 1rem;
    }

    .acrossGlobal-box h2{
        font-size: clamp(22px, calc( 5.1vw + 0.5rem ) ,220px);
        margin-bottom: 1.5rem;
    }

}