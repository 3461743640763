.CompleteCheckout-wrapper {
    background: linear-gradient(180deg, rgba(32, 32, 32, 1) 5%, rgba(83, 172, 255, 0.3) 100%);
}

.CompleteCheckout-wrapper .CompleteCheckout-container {
    width: 80vw;
}
.CompleteCheckout-container .CompleteCheckout-img img{
    width: 100%;
    max-width: fit-content;
}

.CompleteCheckout-container .CompleteCheckout-message h4 {
    font-family: Poppins;
    font-size: clamp(22px, calc(2.8vw + 0.5rem), 140px);
    font-weight: 700;
    letter-spacing: 0px;
    margin-bottom: 1.5rem;
    text-align: left;
}

.CompleteCheckout-container .CompleteCheckout-message h4+p {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 4rem;
}

.CompleteCheckout-container .CompleteCheckout-message .receipt-card {
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 18.83098602294922px 29.591548919677734px 0px rgba(0, 0, 0, 0.25);
    width: 70%;
    min-width: 400px;
}

.CompleteCheckout-container .CompleteCheckout-message .receipt-card::before {
    transform: translate(-50%, -50%) rotate(0deg);
}

.CompleteCheckout-container .CompleteCheckout-message .receipt-card:hover::before {
    transform: translate(-50%, -50%) rotate(180deg);
}

.CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body {
    padding: 2rem 2rem 2.5rem;
    background: linear-gradient(54deg, rgba(38, 58, 75, 1) 0%, rgba(51, 85, 117, 1) 100%);
    backdrop-filter: blur(5px);
    border-radius: 20px 20px 0 0;
}

.CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body h6 {
    font-family: Poppins;
    font-size: clamp(12px, calc( 1vw + 0.5rem ) , 70px);
    font-weight: 700;
    text-align: center;
}

.CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body p {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 1rem;
}

.CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body .receipt-price {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 0;
}

.CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body .receipt-price h5 {
    font-family: Poppins;
    font-size: clamp(12px, calc( 0.8vw + 0.5rem ) , 70px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body .receipt-price p {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 400;
    letter-spacing: 0em;
    color: rgba(204, 204, 204, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
}

.CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body .receipt-price p>span {
    font-family: Poppins;
    font-size: clamp(12px, calc( 0.8vw + 0.5rem ) , 70px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}


.CompleteCheckout-wrapper .receipt-card .plane-points li {
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
}

.CompleteCheckout-wrapper .receipt-card .plane-points li::before {
    content: none;
}

.CompleteCheckout-wrapper .receipt-card .plane-points li svg {
    margin-right: 1rem;
}

.CompleteCheckout-wrapper .receipt-card .plane-points li.available .false {
    display: none;
}

.CompleteCheckout-wrapper .receipt-card .plane-points li.not-available .true {
    display: none;
}

.CompleteCheckout-wrapper .receipt-card .plane-points li.not-available {
    color: rgba(255, 255, 255, 0.6);
}

.CompleteCheckout-wrapper .receipt-card a {
    border: none;
    outline: none;
    background: var(--Blue300);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    display: block;
    padding: 0.6rem 2rem;
    text-align: center;
    margin: 0 auto 2rem;
    font-size: clamp(10px,  calc( 0.6vw + 0.5rem ), 60px);
    font-weight: 600;
    width: fit-content;
    transition: all 0.3s;
}
.CompleteCheckout-wrapper .receipt-card a:hover {
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}

.CompleteCheckout-wrapper .border-animate {
    position: relative;
    padding: 1px 1px 0px;
}

@media only screen and (max-width: 1024px) {

    .CompleteCheckout-wrapper .CompleteCheckout-container {
        width: 100%;
    }

    .CompleteCheckout-container .CompleteCheckout-message h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 26px;
        text-align: left;
        margin-bottom: 1rem;
    }

    .CompleteCheckout-container .CompleteCheckout-message h4+p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }

    .CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body h6 {
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
    }

    .CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body .receipt-price {
        padding: 1.5rem 0.5rem;
    }

    .CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body .receipt-price p {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin: 0;
    }

    .CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body .receipt-price h5 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    .CompleteCheckout-container .CompleteCheckout-img {
        position: absolute;
        z-index: -1;
    }

    .CompleteCheckout-container .CompleteCheckout-img img {
        transform: translateX(-70px) scale(0.7);
    }

    .CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body {
        padding: 3rem 1rem 1rem;
    }

    .CompleteCheckout-container .CompleteCheckout-message .receipt-card {
        width: 100%;
    }

    .CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body .receipt-price {
        padding: 1.5rem 0rem;
    }

    .CompleteCheckout-wrapper .receipt-card .plane-points li {
        font-size: 10px;
        line-height: 12.6px;
    }

    .CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body .receipt-price p {
        font-size: 14px;
        line-height: 21px;
    }

    .CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body .receipt-price p>span {
        font-size: 16px;
        line-height: 24px;
    }

    .CompleteCheckout-wrapper .receipt-card button {
        font-size: 12px;
        font-weight: 600;
        line-height: 14.4px;
        text-align: left;
    }

    .CompleteCheckout-container .CompleteCheckout-message .receipt-card {
        border-radius: 20px 20px 0 0;
        box-shadow: 0px 18.83098602294922px 29.591548919677734px 0px rgba(0, 0, 0, 0.25);
        width: 100%;
        min-width: auto;
    }

    .CompleteCheckout-container .CompleteCheckout-message .receipt-card .card-body p {
        font-size: 10px;
        line-height: 15px;
    }

}