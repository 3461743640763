.notificationBtn {
  width: clamp(20px, calc(1vw + 0.5rem), 100px);
  background: transparent;
  border: none;
  outline: none;
  margin: 0 1rem;
  cursor: pointer;
  position: relative;
}

.notificationBtn > img {
  width: 100%;
}

.notificationBtn > span {
  position: absolute;
  top: -5px;
  right: -3px;
  width: clamp(15px, calc(0.8vw + 0.1rem), 60px);
  height: clamp(15px, calc(0.8vw + 0.1rem), 60px);
  background-color: #118f4b;
  color: #fff;
  font-family: Nunito Sans;
  font-size: clamp(10px, calc(0.7vw + 0.1rem), 60px);
  font-weight: 700;
  letter-spacing: 0px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification_window {
  position: absolute;
  min-width: 400px;
  top: calc(100% + 1rem);
  right: 0rem;
  padding: 1.5rem;
  border: 1px solid #ffffff8c;
  backdrop-filter: blur(30px);
  z-index: 999999;
  box-shadow: 0px 4px 6.300000190734863px 0px #00000040;
  border-radius: 15px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.notification_window.open {
  visibility: visible;
  opacity: 1;
}

.notification_window ul {
  position: relative;
  display: flex;
}

.notification_window ul::after {
  content: "";
  height: 80%;
  width: 1px;
  background: #fff;
  position: absolute;
  top: 10%;
  left: 50%;
  z-index: 2;
}

.notification_window li {
  width: 50%;
}
.notification_window li > a {
  padding: 10px 3rem;
  font-size: clamp(12px, calc(1vw + 0.1rem), 60px);
  font-weight: 700;
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #ffff;
  display: block;
}

.tabs_content {
  max-height: 50vh;
  overflow-y: auto;
  padding: 1rem 0 0;
}
.tabs_content::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 10px;
  background-color: transparent;
}

.tabs_content::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.tabs_content a {
  display: block;
}
.notification_item {
  padding: clamp(10px, calc(0.9vw + 0.1rem), 60px);
  border-radius: 0.625rem;
  background: #202020;
  box-shadow: 0px 4px 6.9px 0px rgba(0, 0, 0, 0.25) inset;
  transition: all 0.3s;
}

.notification_item:hover {
  background: #36546f;
}

.notification_item p {
  font-size: clamp(11px, calc(0.9vw + 0.1rem), 60px);
  font-weight: 400;
  text-align: left;
}
.notification_item p > span {
  font-weight: 700;
}
.notification_item > span {
  font-family: Poppins;
  font-size: clamp(8px, calc(0.6vw + 0.1rem), 60px);
  font-weight: 400;
  text-align: right;
  margin-top: 0.5rem;
  display: block;
}

@media only screen and (max-width: 767px) {
  .notification_window {
    min-width: 270px;
    max-width: 270px;
    right: -2.2rem;
    padding: 0.7rem;
  }
}
