
.quiz-card {
    height: clamp(250px, calc( 18vw + 0.5rem ) ,1000px);
    position: relative;
    border-radius: 15px;
    border: 1px solid transparent;
    box-shadow: inset 0px 0px 25px 5px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
}
.quiz-card:hover{
    border: 1px solid #ffffff56;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}
.quiz-card .card-img{
    border-radius: 15px 15px 0 0;
}
.quiz-card .card-body {
    padding: 1.5rem 1rem;
    background-color: rgba(32, 32, 32, 1);
    border-radius: 0 0 15px 15px;
    transition: all 0.2s;
}

.quiz-card:hover .card-body{
    background: linear-gradient(180deg, #53ACFF 0%, #326799 100%);
}
.quiz-card .card-body h4 {
    font-family: Poppins;
    font-size: clamp(10px, 0.5vw + 0.5rem, 60px);
    font-weight: 700;
    text-align: left;
    color: #fff;
    margin-bottom: 1.5rem;
    width: 100%;
}

.quiz-card .quiz-details span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: Poppins;
    font-size:  clamp(10px, 0.6vw + 0.5rem, 60px);
    font-weight: 400;
    text-align: left;

}
.quiz-card .quiz-details svg{
    width: 25px;
}
.quiz-card .quiz-details svg path {
    fill: rgba(83, 172, 255, 1);
    transition: all 0.2s;
}
.quiz-card:hover .quiz-details svg path{
    fill: #fff;
}

.hot-padge ,.hot-padge-dark {
    position: absolute;
    top: -11px;
    right: -20px;
    width: 120px;
}
.quiz-card .hot-padge-dark{
    display: none;
}
.horizontal-card{

    display: flex;
    box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.25);
}
.horizontal-card .hot-padge{
    display: none;
}
.horizontal-card .hot-padge-dark{
    display: block;
}
.horizontal-card .card-img{
    height: 100%;
    width: 50%;
    border-radius: 15px 0 0 15px;
}
.horizontal-card .card-body h4{
font-family: Poppins;
font-size: clamp(20px,  calc( 1.7vw + 0.5rem ) , 80px);
font-weight: 700;
text-align: left;
margin-bottom: 0;

}
.horizontal-card .card-body{
    border-radius: 0 15px 15px 0;
    background: linear-gradient(180deg, #53ACFF 0%, #326799 100%);
}
.horizontal-card .card-body .highlights span{
    padding: 0.5rem var(--8px);
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    font-weight: 400;
    background: rgba(65, 64, 64, 0.5);
    display: block;
    width: max-content;
    border-radius: 5px;
    margin-bottom: 0.5rem;
}
.horizontal-card .quiz-details svg path{
    fill: #fff;
}



@media only screen and (max-width: 1024px) {

    .quiz-card .hot-padge, .hot-padge-dark {
        position: absolute;
        top: -8px;
        right: -15px;
        width: 90px;
    }    
    .quiz-card .card-body h4 {
        font-size: 10px;
        font-weight: 700;
    }
    .quiz-card .quiz-details span {
        font-size: 10px;
        font-weight: 400;
        gap: 0.2rem;
    }

    .quiz-card .quiz-details svg {
        width: 12px;
    }
    .quiz-card.horizontal-card {
        margin: 0 !important;
        max-width: 100%;
    }
    .horizontal-card .card-body{
        padding: 1.5rem 0.5rem;
    }
    .horizontal-card .card-body .highlights span {
        padding: 0.3rem 0.4rem;
        font-size: 10px;
        font-weight: 400;
    }

    .quiz-card.horizontal-card .card-body h4 {
        font-size: 18px;
        font-weight: 700;
    }
}