.LeftBorder{
    border-left: 4px solid #53ACFF;
    padding: 0 0.5rem;
    border-radius: 0.2rem ;
}

.linksSection a{
    color: white !important;
    text-decoration: none;
    font-size: clamp(10px, calc( 0.9vw + 0.1rem), 60px);
    font-weight: 400;
}.linksSection a{
    text-decoration: none !important;
}
.LeftBorder h1{
    font-size: clamp(24px, calc(2.4vw + 0.5rem),140px);
    font-weight: 700;
}
.loginPerson h6{
    font-size: clamp(10px, calc( 0.5vw + 0.5rem), 60px);
    font-weight: 700;

}
.loginPerson p{
    color: #979797;
    font-size:clamp(9px, calc( 0.3vw + 0.5rem), 60px) ;
    font-weight: 600;
}
.spaceBottom{
    padding-bottom: clamp(20px, calc(1.5vw + 0.5rem), 70px);
}
.nameAndDescEverySec{
    display: flex;
    flex-direction: column;
    gap:clamp(2px, calc( 0.1vw + 0.2rem), 20px);

}
.settingBtn{
    font-size:clamp(10px, calc( 0.5vw + 0.1rem), 60px) !important;
    border-radius: 0.2rem !important;
    font-weight: 600 !important;
    padding-top: clamp(1.6px, calc( 0.3vw + 0.1rem), 60px) !important;
    padding-bottom: clamp(1.6px, calc( 0.3vw + 0.1rem), 60px) !important;
    padding-left: clamp(8px, calc( 0.6vw + 0.1rem ) , 70px) !important;
    padding-right: clamp(8px, calc( 0.6vw + 0.1rem ) , 70px) !important;
}
.widerBtn{
    font-size:clamp(10px, calc( 0.5vw + 0.1rem), 60px) !important;
    border-radius: 0.2rem !important;
    font-weight: 600 !important;
    padding-top: clamp(1.6px, calc( 0.3vw + 0.1rem), 60px) !important;
    padding-bottom: clamp(1.6px, calc( 0.3vw + 0.1rem), 60px) !important;
    padding-left: clamp(8px, calc( 1.2vw + 0.1rem ) , 70px) !important;
    padding-right: clamp(8px, calc( 1.2vw + 0.1rem ) , 70px) !important;
}
.disconnetBtn{
    background: transparent;  
    text-transform: none !important;
    color: #ffff !important;
    border: 1px solid rgba(255, 255, 255, 0.29) !important;
    transition: all 0.4s !important;
}
.disconnetBtn:hover{
   background-color:rgba(83, 172, 255, 1) !important ;
}
.disconnectBtn{
    color:#979797 !important ;
    border:1px solid #979797 !important;
    background-color: transparent !important;

}
.avatarTxt a{
    font-size: clamp(8px, calc( 0.6vw + 0.1rem), 30px);
    font-weight: 400;
}
.largeDiv{
    border-radius: 0.5rem;
    background-color: #181818;
}
.forborderRaduis{
    border-radius: 0.5rem;
    background-color: #282828;
    display: flex;
    flex-direction: column;
    gap: clamp(5px, calc(0.45vw + 0.1rem), 60px);
}
.labelColor label{
    color: #ffffff;
    font-size: clamp(12px, calc(0.7vw + 0.1rem), 60px);
    font-weight: 400;
}
.react-international-phone-input-container input , .react-international-phone-input-container button{
    width: 100%;
    background-color: #2E2E2E !important;
    border: 1px solid #979797;
    border-radius: 0.2rem;
    color: white !important;
}
.inputAndChangeBtn{
    display: flex;
    transition: all 2s ;
    gap: clamp(4px, calc( 0.15vw + 0.1rem), 60px);
}

.react-international-phone-country-selector-dropdown__list-item span{
    color: black !important;
}
.everyInput{
    border:1px solid #979797;
    border-radius:0.2rem;
    background-color:#202020;
    padding: 0 4px;
}
.lightTxt{
    color: #979797;
    font-size: clamp(11px, calc( 0.4vw + 0.1rem), 60px);
    font-weight: 400;
}
.accountBilling{
    display: flex;
    flex-direction: column;
}
.accountBilling h5{
    font-size: clamp(16px, calc(1.5vw + 0.1rem), 70px);
    font-weight: 600;
}
.accountBilling p{
    font-size: clamp(10px, calc( 0.9vw + 0.1rem), 60px);
    font-weight: 700;
}
.accountBilling h4{
    font-size: clamp(16px, calc( 1.2vw + 0.1rem ) , 70px);
    font-weight: 700;
}
.accountBilling h1{
    font-size: clamp(40px, calc(3.7vw + 0.1rem),140px);
    font-weight: 700;
}
.accountBilling h6{
    font-size: clamp(23px, calc( 1.2vw + 0.1rem ) , 70px);
    font-weight: 400;
}
.premuimDiv{
    border-radius: 0.8rem;
    background: linear-gradient(315deg, rgb(32, 44, 54) 34%, rgb(52, 84, 114)) ;
}
.rightSecAccount h5{
    font-size: clamp(23px, calc( 0.5vw + 0.5rem), 60px);
    font-weight: 700;
}

.tableSection{
    background-image: radial-gradient(circle, rgba(120,120,120,1) 26%, rgba(60,60,60,1) 56%);
    border-radius: 0.5rem;
    overflow: hidden;
}
.disconnectSetting{
    display: flex;
    flex-direction: column;
    gap: clamp(10px, calc(0.3vw + 0.1rem), 60px) ; align-items: end;
}
.disconnectSetting p{
    font-size: clamp(8px, calc(0.5vw + 0.1rem), 60px);;
}
.itemsBG li{
    text-align: center;
    background-color: #2E2E2E;
    padding: 0.6rem 0.2rem;
}
.tableHead {
    padding: 0.6rem 0;
    background-color: #2E2E2E;
    box-shadow: 0px -20px 10px -20px rgba(0,0,0,0.6) inset;
}
.tableHead li{
    font-size:clamp(11px, calc( 0.6vw + 0.1rem), 60px);
    font-weight: 700;
}
.tableBody li{
    font-size:  clamp(11px, calc( 0.6vw + 0.1rem), 60px);
    font-weight: 400;
}
.blueTxt{
    color: rgba(83, 172, 255, 1);
    font-size: clamp(8px, calc( 0.6vw + 0.1rem), 60px);
}
.settingCardBorder,.grayPositionBorder{
    position:relative;
    overflow: hidden;
    padding: 2px;
    border-radius: 0.5rem;
}
.premuimBorder{
    position:relative;
    overflow: hidden;
    padding: 1px;
    border-radius: 0.8rem;
}
.premuimContainer{
    border-radius: 0.8rem;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
}

.settingCardBorder::before ,.premuimBorder::before{
    content: "";
    background-image: conic-gradient(transparent 0deg ,transparent -90deg ,#53acff 250deg,transparent 280deg );
    width: 250%;
    height: 250%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%) rotate(180deg);
    box-sizing: border-box;
    position: absolute;
    transition:all 0.5s
}
.textArea{
    overflow-y: auto;
    resize: none;
}
.settingCardBorder:hover::before,.premuimBorder:hover::before,.grayPositionBorder:hover::before{
transform:translate(-50%,-50%) rotate(0deg);
}

.settingBorderContainer,.grayPositionContainer{
border-radius: 0.5rem;
overflow: hidden;
width: 100%;
height: 100%;
position: relative;
}
.rightBorder h3,.sectionHeader h3,.accountBilling h5{
    font-size: clamp(16px, calc(1.4vw + 0.1rem), 70px);
    font-weight: 700;
}

.rightBorder p,.sectionHeader p{
    font-size:clamp(10px, calc( 0.6vw + 0.1rem), 60px);
    font-weight: 400;
}
.properityInSwitch p{
    font-size:var(--13px);
    font-weight: 400;
}

@media only screen and (max-width:676px) {
    .LeftBorder h1{
        line-height: 2.25rem;
        font-weight: 700;

    }
    .rightBorder h3,.leftSectionSetting h3,.accountBilling h5 ,.accountBilling p ,.premuimDiv h4{
        line-height: 1.5rem;
        font-weight: 700;
    }
    .rightBorder p ,.leftSectionSetting p{

        line-height: 0.7875rem;
        font-weight: 400;
    }
    .labelColor label{

        line-height: 0.945rem;
        font-weight: 400;
    }
    .avatarTxt a{

        line-height:1.442rem;
        font-weight: 400;
    }
    .settingBtn{

        font-weight: 600;
        line-height: 0.7rem;


    }
    .properityInSwitch p{
        line-height: 0.945rem;
        font-weight: 400;
    }
    .txtUnderProperities p{
        font-size: clamp(10px, calc( 0.6vw + 0.1rem), 60px);
        font-weight: 400;
    }
    .premuimDiv h1{

        line-height: 3.75rem;
        font-weight: 700;
    }
    .premuimDiv h6{

        line-height: 2.156rem;
        font-weight: 400;
    }
    .rightSecAccount h5{

        line-height: 1.5rem;
        font-weight: 700;
    }
    .rightBorder{
        border-bottom: 1px solid #979797 ;
        border-right: 0;
    }
    
 
}
