section.clientsFavFeature {
    padding: 10% 0 0 3%;
    border-top: 1px solid #5D5D5D;
    position: relative;
    overflow-x: clip;
}

section.clientsFavFeature .section-title {
    color: #fff;
    font-size: clamp(24px, calc(3.1vw + 0.5rem), 140px);
    left: 12%;
}

section.clientsFavFeature .clientsFavFeature-img img {
    width: 100%;
    transform: scale(1.3) translate(-1% , -9vw);
}

.commingSoon-container>p {
    font-size: clamp(10px, calc(0.4vw + 0.1rem), 60px);
}

.clientsFavFeatures-content .clientsFavFeatures-reviews .clientsFavFeatures-review {
    position: relative;
    width: max-content;
    padding: clamp(10px, calc(1vw + 0.1rem), 140px) clamp(16px, calc(4vw + 0.1rem), 140px) clamp(10px, calc(1vw + 0.1rem), 140px) clamp(16px, calc(1.5vw + 0.5rem), 140px);
    display: flex;
    align-items: center;
    gap: clamp(16px, calc(3vw + 0.5rem), 140px);
    box-shadow: 0px 15px 30px -5px #0000005a;
    border-radius: 21px;
    min-width: 50%;
    background: rgba(49, 49, 49, 0.22);
    backdrop-filter: blur(10.6px);
    -webkit-backdrop-filter: blur(10.6px);
    border: 1px solid rgba(255, 255, 255, 0.19);
}

.clientsFavFeatures-content .clientsFavFeatures-reviews .clientsFavFeatures-review:nth-child(2) {
    margin-left: auto;
    position: relative;
    z-index: 2;
    transform: translate(-10% ,-15%);
}


.clientsFavFeatures-content .clientsFavFeatures-reviews .clientsFavFeatures-review:nth-child(3) {

    transform: translate(7% , -25%);
}

.clientsFavFeatures-content .clientsFavFeatures-reviews .clientsFavFeatures-review:nth-child(4) {
    margin-left: auto;
    position: relative;
    z-index: 2;
    transform: translateY(-5%);
}

.clientsFavFeatures-content .clientsFavFeatures-reviews .clientsFavFeatures-review:nth-child(5) {
    transform: translate(20% ,-15%);
}



.clientsFavFeatures-content .clientsFavFeatures-reviews .clientsFavFeatures-review img {
    width: clamp(30px, calc(3.2vw + 0.5rem), 140px);
    height: clamp(30px, calc(3.2vw + 0.5rem), 140px);
}

.clientsFavFeatures-content .clientsFavFeatures-reviews .clientsFavFeatures-review p {
    font-size: clamp(10px, calc(1vw + 0.1rem), 140px);
}


@media only screen and (max-width: 1024px) {
    section.clientsFavFeature {
        padding: 10% 0;
    }

    .commingSoon-container>p {
        margin: 2rem 0;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    section.clientsFavFeature .clientsFavFeature-img img {
        transform: translateY(0px);
    }
}

/* @media only screen and (min-width: 2224px) {

section.clientsFavFeature .section-title {
    font-size: 80px;
}

} */