.Signuplayout{
    background: linear-gradient(190deg, rgba(32,32,32,1) 40%, rgba(47, 83, 117,1) 100%);
    min-height: 100vh;
    align-items: center;
    padding: clamp(10px, calc( 0.5vw + 0.1rem), 60px) clamp(10px, calc( 0.3vw + 0.5rem), 60px);
}

.desc{
    display: flex;
    align-items: center;
}

.logoDiv{
    margin-left:-2rem;
}

.imgDiv{
    width: clamp(135px, calc(24vw + 0.1rem),250px);
    height: clamp(60px, calc(11vw + 0.1rem),140px);
}

.content h3{
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size:  clamp(24px, calc(2vw + 0.1rem),140px);
  font-style: normal;
  padding: 2rem 0;
}

.content p{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: clamp(12px, calc( 0.9vw + 0.1rem), 60px);
}

.content span{
    font-size: clamp(24px, calc(2vw + 0.1rem),90px);
}

@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.inForm h3{
    font-size: clamp(24px, calc(1.8vw + 0.1rem), 80px);
}

.inForm p{
    font-size: clamp(24px, calc(1vw + 0.1rem), 80px);
}

.formBGSignup{
    background: linear-gradient(265deg, rgb(23, 37, 49) 20%, rgb(48, 73, 97) 100%);

}

@media only screen and (max-width: 676px) {
    .content h3,.content span{

       line-height: 2.25rem;
     }
     .content p{
  
        line-height: 1.125rem;
     }
     .imgDiv{
        width: 8.44rem;
        height: 3.75rem;
     }
     .formBGSignup{
        padding: 56px 16px;
     }


}
.more-quizzes .gradient-border {
    border-radius: 15px;
  }
  
  .add-register-modal .modal-body {
    background: rgba(32, 32, 32, 1);
  }
  
  .add-register-modal h6 {
    font-family: Poppins;
    font-size: clamp(14px, calc(1.4vw + 0.5rem), 90px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
  }
  
  .add-register-modal .line {
    width: 60%;
    height: 1px;
    margin: 2rem auto;
    background: rgba(93, 93, 93, 1);
  }
  
  .add-register-modal p {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.6vw + 0.5rem), 60px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
  }
  

  
  .add-register-modal span {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.35vw + 0.5rem), 60px);
    font-weight: 400;
    letter-spacing: 0em;
    color: rgba(151, 151, 151, 1);
    width: 70%;
    text-align: left;
  }
  
  .add-register-modal button {
    letter-spacing: 0em;
    padding: 10px 38px 10px 38px;
    background: var(--Blue300);
    font-size: clamp(10px, calc(0.4vw + 0.5rem), 60px);
    border-radius: 5px;
    color: #fff;
    margin-top: 2rem;
    outline: none;
    border: none;
  }