.similarBlogs h5{
    font-size: clamp(10px, calc(0.5vw + 0.5rem), 60px);
    line-height: 28.53px;
    font-weight: 400;
}

@media only screen and (max-width:676px) {
    .similarBlogs h5{
        line-height: 21px;
        font-weight: 400;
    }
}

.similarBlogs span{
    font-size: clamp(10px, calc(0.5vw + 0.1rem), 60px);
    line-height: 28.53px;
    font-weight: 400;
    color: #53ACFF;
}

