.caption h1,span,h3{
    font-weight: 700;
}
.caption h1{
    font-size: clamp(24px, calc(2.8vw + 0.1rem),140px);
}
.caption span ,.caption p{
    font-size: clamp(12px, calc( 0.9vw + 0.1rem), 60px);
}
.caption p{
    font-weight: 400;
}

.msgTextArea{
    padding: 0 clamp(4px, calc( 0.8vw + 0.1rem), 50px);
    border-radius: 0.5rem;
    color:#2b2b2b;
    font-weight: 400;
    font-size: clamp(10px, calc( 0.7vw + 0.1rem), 60px);
}
.gapInUpdate{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.logcardBG p{
font-size: clamp(10px, calc( 0.7vw + 0.1rem), 60px);
}
.logcardBG h5{
    font-size: clamp(14px, calc( 1vw + 0.1rem ) , 70px);
}
.caption h3{
    font-size: clamp(16px, calc(1.2vw + 0.1rem), 80px);;
}
.leftBorder{
    border-left: 3px solid #FFFFFF99;
    padding:0 2.5rem;
    display: flex;
    flex-direction: column;
    gap:1rem
}
.leftBorder::-webkit-scrollbar{
    width: 0;
    display: none;
}
.leftBorder::-webkit-scrollbar-thumb{
    width: 0;
    display: none;
}
.changeLogLayout{
    background-image: url('../../assets/changeLogBG.png');
}
.logcardBG{
    background: linear-gradient(265deg, rgb(80, 125, 165) 20%, rgba(60, 96, 131, 1) 100%);
    padding: clamp(56px, calc(4vw + 0.1rem),200px) 0 !important;
}
.updatesChangeLog h5{
    font-size: clamp(10px, calc( 0.8vw + 0.1rem), 60px);
}
.cardContainer{
  padding-top: 2.8rem;
  padding-bottom: 3.5rem;
  overflow: hidden;
  border-radius: 0.9rem;
  width: 100%;
  height: 100%;
  position: relative;
}
.changeLogBorder{
    position:relative;
    overflow: hidden;
    padding: 2px;
    border-radius: 0.9rem;
    box-shadow: 0px 4px 100px 4px #00000093;
}

.changeLogBorder::before{
    content: "";
    background-image: conic-gradient(transparent 110deg ,#93CBFF 220deg,transparent 300deg );
    width: 250%;
    height: 250%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%) rotate(320deg);
    box-sizing: border-box;
    position: absolute;
    transition:all 0.5s
}
.changeLogBorder:hover::before{
    transform:translate(-50%,-50%) rotate(0deg);
}
@media only screen and (max-width: 676px){
    .caption h5{
        font-size: 0.625rem;
        line-height: 0.94rem;
    }
    .caption h1{

        line-height: 2.25rem;
    }
    .caption p{

        line-height: 1.125rem;
    }
    .logcardBG h5{
        line-height: 1.3125rem;
    }
    .logcardBG p{
        line-height: 0.7875rem;
    }
    .msgPrt h3{
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .btnColor{
        font-size: 0.75rem;
        line-height: 0.9rem;
    }
}
