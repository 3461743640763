.whiteBorder::before{
    content: "";
    background-image: conic-gradient(transparent 60deg ,#f5f0f0 180deg,transparent 2700deg );
    width: 250%;
    height: 250%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%) rotate(180deg);
    box-sizing: border-box;
    position: absolute;
    transition:all 0.5s
}
.movingCardsBG{
    background-color: #202020;
}
.movingCards p{
    font-size: clamp(10px, calc( 0.8vw + 0.1rem), 60px);
    font-weight: 400;

}