.sideMenu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999999999;
  width: clamp(150px, calc(10vw + 0.5rem), 500px);
  height: 100vh;
  background-color: #0f0f0f;
  box-shadow: 10px 4px 21.100000381469727px 0px #00000061;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all 0.6s;
  animation-name: animateOnOpen;
  animation-delay: 0.6s;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  overflow: hidden;
}

.sideMenuContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sideMenu .sideMenuContent .logo {
  height: clamp(100px, calc(4.1vw + 0.5rem), 340px);
  padding: 2rem 2.5rem 1rem;
  margin-bottom: 1rem;
  transition: all 0.6s;
}

.sideMenu .sideMenuContent .logo img {
  width: 100%;
  transition: all 0.6s;
}

.sideMenu .sideMenuContent .menu {
  list-style: none;
  padding: 0 1rem;
  margin: auto;
  max-height: 70vh;
  overflow: auto;
  transition: all 0.6s;
}

.sideMenu .sideMenuContent .menu::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 10px;
  background-color: transparent;
}

.sideMenu .sideMenuContent .menu::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.sideMenu .sideMenuContent .menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: none;
  background-color: #424242;
}

.sideMenu .sideMenuContent .menu li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s;
}

.sideMenu .sideMenuContent .menu svg {
  width: clamp(15px, calc(1.2vw + 0.1rem), 60px);
  fill: #fff;
  margin-right: 0.6rem;
  transition: all 0.3s;
}

.sideMenu .sideMenuContent svg path {
  transition: all 0.3s;
  fill: #fff;
}

.sideMenu .sideMenuContent .menu li a {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: clamp(14px, calc(0.9vw + 0.1rem), 60px);
  font-weight: 400;
  letter-spacing: 0px;
  transition: all 0.3s;
}

.sideMenu .sideMenuContent .menu li a:hover p {
  color: #53acff;
}

.sideMenu .sideMenuContent .menu li a:hover svg {
  fill: #53acff;
}

.sideMenu .sideMenuContent .menu li a:hover svg path {
  fill: #53acff;
}
.sideMenu .sideMenuContent .menu li.comingSoon {
  position: relative;
}
.sideMenu .sideMenuContent .menu li.comingSoon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(39, 68, 95);
  box-shadow: inset 0px 0px 10px 8px #0f0f0f;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}
.sideMenu .sideMenuContent .menu li.comingSoon::after {
  content: "Coming Soon";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(10px, calc(0.3vw + 0.5rem), 60px);
  font-weight: 600;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.sideMenu .sideMenuContent .menu li.comingSoon:hover::after,
.sideMenu .sideMenuContent .menu li.comingSoon:hover::before {
  visibility: visible;
  opacity: 1;
}

.sideMenu .sideMenuContent .menu li.comingSoon a p {
  color: #ffffff4d;
}

.sideMenu .sideMenuContent .menu li.comingSoon svg {
  fill: #ffffff4d;
}

.sideMenu .sideMenuContent .menu li.comingSoon a:hover svg {
  fill: #ffffff4d;
}

.sideMenu .sideMenuContent .menu li.comingSoon a:hover svg path {
  fill: #ffffff4d;
}

.sideMenu .sideMenuContent .menu li.comingSoon svg path {
  fill: #ffffff4d;
}

.sideMenu .sideMenuContent .menu li a p {
  position: unset;
  left: unset;
  opacity: 1;
  transition: all 0.6s;
}

.userInfoContent {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 1rem;
}

.sideMenu.onClose .userInfoContent {
  justify-content: center;
}

.userInfoContent img {
  width: clamp(22px, calc(2vw + 0.5rem), 100px);
  height: clamp(22px, calc(2vw + 0.5rem), 100px);
  margin-right: 0.5rem;
  transition: all 0.6s;
}

.userInfoContent h6 {
  font-size: clamp(10px, calc(0.4vw + 0.5rem), 60px);
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 0.1rem;
  color: #fff;
  position: static;
  left: unset;
  opacity: 1;
  transition: all 0.6s;
}

.userInfoContent p {
  font-size: clamp(10px, calc(0.4vw + 0.3rem), 60px);
  font-weight: 600;
  letter-spacing: 0px;
  color: #979797;
  position: static;
  left: unset;
  opacity: 1;
  transition: all 0.6s;
}

.userInfo > p {
  color: #424242;
  font-size: clamp(10px, calc(0.4vw + 0.3rem), 60px);
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 1rem;
  position: unset;
  left: unset;
  opacity: 1;
  transition: all 0.6s;
}

/* ======================================= */

.sideMenu.onClose {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  width: clamp(80px, calc(4.5vw + 0.5rem), 300px);
  height: 100vh;
  animation-name: animateOnClose;
  animation-delay: 0.6s;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.sideMenu.onClose .logo {
  padding: 2rem 1rem 1rem;
}

.sideMenu.onClose .logo img {
  width: 100%;
}

.sideMenu.onClose .menu {
  list-style: none;
  padding: 0 1rem;
  margin: auto;
}

.sideMenu.onClose .menu li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sideMenu.onClose .menu li a p {
  position: absolute;
  left: -200px;
  opacity: 0;
}

.sideMenu.onClose .menu li a img {
  width: clamp(25px, calc(1.5vw + 0.5rem), 80px);
  margin-right: 0rem;
}

.sideMenu.onClose .userInfoContent img {
  width: clamp(40px, calc(2.5vw + 0.5rem), 80px);
  height: clamp(40px, calc(2.5vw + 0.5rem), 80px);
  margin-right: 0rem;
}

.sideMenu.onClose .userInfoContent h6 {
  position: absolute;
  left: -200px;
  opacity: 0;
}

.sideMenu.onClose .userInfoContent p {
  position: absolute;
  left: -200px;
  opacity: 0;
}

.sideMenu.onClose .userInfo > p {
  position: absolute;
  left: -200px;
  opacity: 0;
}

.notificationBtn {
  width: 30px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
}

.notificationBtn span {
  position: absolute;
  top: -5px;
  right: 0;
  width: 15px;
  height: 15px;
  background-color: #118f4b;
  color: #fff;
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1024px) {
  .logo {
    height: 80px;
  }

  .sideMenu.onClose {
    width: 60px;
  }

  .sideMenu.onClose .logo img {
    width: 45px;
  }

  .menu {
    padding: 0 0rem;
  }

  .onClose .menu li a img {
    width: 20px;
    margin-right: 0rem;
  }

  .onClose .userInfoContent img {
    width: 35px;
    height: 35px;
  }

  @keyframes animateOnClose {
    0% {
      width: 60px;
    }

    50% {
      width: 55px;
    }

    100% {
      width: 60px;
    }
  }
}
