.SuccessfulTransactionPage {
    padding: 3rem;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25) inset;
    border-radius: 5px;
}



.SuccessfulTransactionPage h4 {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 3vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;
    margin-bottom: 1rem;

}

.SuccessfulTransactionPage p {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 400;
    text-align: left;

}

.SuccessfulTransactionPage p:first-child {
    margin-bottom: clamp(10px,  calc( 2vw + 0.1rem ), 80px);
}

.SuccessfulTransactionPage h6 {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;
    margin-top: clamp(10px,  calc( 3vw + 0.1rem ), 80px);
}

.SuccessfulTransactionPage h6>a {
    color: var(--Blue300);
}

.SuccessfulTransactionPage ul li {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 400;
    text-align: left;
    color: rgba(204, 204, 204, 1);
    display: flex;
    align-items: center;
    gap: clamp(10px,  calc( 1.5vw + 0.1rem ), 80px);
    margin: 0.5rem 0;
}

.SuccessfulTransactionPage ul li:last-child {
    margin-bottom: 0;
}

.SuccessfulTransactionPage ul li span {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    font-weight: 400;
    text-align: left;
    color: rgba(204, 204, 204, 1);
}

.SuccessfulTransactionPage ul li:first-child {
    font-family: Poppins;
    font-size: clamp(10px,  calc( 1.1vw + 0.1rem ), 80px);
    font-weight: 700;
    text-align: left;
    color: #fff;
    margin-bottom: 1rem;
}

.go_home_btn {
    display: block;
    font-size: clamp(10px,  calc( 0.8vw + 0.1rem ), 80px);
    font-weight: 600;
    text-align: center;
    padding: 8px 3rem;
    background-color: var(--Blue300);
    border-radius: 4px;
    outline: none;
    border: none;
    margin: 1rem 0 0 auto;
    width: max-content;
}



@media only screen and (max-width: 1000px) {

    .SuccessfulTransactionPage {
        padding: 2rem 1rem;
    }

    .SuccessfulTransactionPage h4 {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
    }

    .SuccessfulTransactionPage p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
    }

    .SuccessfulTransactionPage p:first-child {
        margin-bottom: 1rem;
    }

    .SuccessfulTransactionPage h6 {
        margin-top: 2rem;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        text-align: left;
    }
    .SuccessfulTransactionPage ul li , .SuccessfulTransactionPage ul li span{
        margin: 0.2rem 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        gap: 1rem;
    }
    .SuccessfulTransactionPage ul li:first-child {
        margin-bottom: 0.5rem;
        font-size: 14px;
        font-weight: 700;
        line-height: clamp(10px,  calc( 0.9vw + 0.1rem ), 80px);
    }
    .SuccessfulTransactionPage ul li span>img{
        width: 15px;
    }

    .go_home_btn {
        font-size: 12px;
        margin: 1rem auto;
    }

}