.supTitle {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font-size: clamp(16px,  calc( 1.7vw + 0.1rem ), 80px);;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
}

.ProgressBar {
    position: relative;
}

.ProgressBar span {
    position: absolute;
    top: 16px;
    font-family: Poppins;
    font-size: 11px !important;
    font-weight: 700 !important;
    line-height: 11.5px;
    padding: 4px 6px;
    background-color: var(--Blue300);
    border-radius: 5px;
    text-wrap: nowrap;
    transform: translateX(50%);
}

.ProgressBar span::before {
    content: "";
    position: absolute;
    bottom: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid var(--Blue300);
}

.transfer_pay_side_nav {
    padding: 0.5rem 1rem;

}
.transfer_pay_side_nav li{
    transition: all 0.3s;
    width: fit-content;
}
.transfer_pay_side_nav li a {
    font-family: Poppins;
    font-size: clamp(14px,  calc( 1.2vw + 0.1rem ), 80px);;
    font-weight: 400;
    display: block;
    padding: 0.5rem 0.8rem;
    transition: all 0.3s;
    width: fit-content;
}




@media only screen and (max-width: 1042px) {

    .supTitle {
        padding: 0.5rem 0rem;
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
    }

    .supTitle svg{
        margin-left: 1rem;
        width: 10px;
    }

    .transfer_pay_side_nav {
        padding: 0.5rem 0rem;
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        margin-bottom: clamp(24px,  calc( 1.5vw + 0.1rem ), 100px);
    }

    .ProgressBar span {
        font-size: 9px !important;
    }

    .ProgressBar {
        margin-bottom: clamp(48px,  calc( 5vw + 0.1rem ), 120px);
    }

    .transfer_pay_side_nav li a {
        font-family: Poppins;
        font-size: clamp(12px,  calc( 0.7vw + 0.1rem ), 60px);
        font-weight: 700;
        text-align: center;
    }
}