.winnerBG{
    background-color: rgb(34, 38, 42);
    border-radius: 0.4rem;
}
.putTop3{
    width: 22%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(15%,-10%);
}
.winnerBG p{
    font-size:clamp(10px, calc( 0.5vw + 0.1rem), 60px);
    font-weight: 400;
}