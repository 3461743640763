.forBG{
    background-position:center ;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
}
.leftContentInContactUs{
    display: flex;
    flex-direction: column;
    gap: clamp(10px, calc( 0.5vw + 0.5rem), 60px);
}
.timeForContact{
    display: flex;
    justify-content: space-between;
}
.contactBg{
    background-image: url("../../assets/background.svg");
}
.contactDays,.contactHours{
    display: flex;
    flex-direction: column;
    gap: clamp(6px, calc( 0.5vw + 0.2rem), 60px);
}
.contactHours{
    align-items: center;
}
.contactUsBrief{
    display: flex ;
    flex-direction: column;
    gap: clamp(1px, calc( 0.3vw + 0.2rem), 60px);
    padding-bottom: clamp(1px, calc( 0.5vw + 0.2rem), 60px);
}
.contactWays{
    display: flex;
    align-items: center;
    gap: clamp(12px, calc(1.9vw + 0.3rem), 60px);
}
.reachUsAndContactWays{
    display: flex;
    flex-direction: column;
    gap: clamp(8px, calc( 0.3vw + 0.5rem), 60px);
}
.containContactWays{
    display: flex;
    flex-direction: column;
    gap: clamp(8px, calc( 0.6vw + 0.2rem), 30px);
}

.descPart h1, .descPart h5 , .descPart h3 , .descPart h4 , .formContent h3{
    font-weight: 700;
}
.descPart h5 , .descPart p ,.descPart span{
    font-size:clamp(10px, calc( 0.6vw + 0.5rem), 60px);
}
.descPart span{
    font-weight: 400 !important;
}
.descPart h1{
    font-size: clamp(24px, calc(3.6vw + 0.5rem),140px);
    margin-bottom: 0;
}
.descPart p{
    font-weight: 400;
    margin-bottom: 1.8rem;
}

.descPart h3{
    font-size: clamp(16px, calc(1.6vw + 0.5rem), 80px);
    padding-bottom: 1.5rem;
}
.descPart h4{
    font-size:clamp(12px, calc( 1vw + 0.5rem ) , 70px); 
}
.formBGMsg{
    background: linear-gradient(265deg, rgb(40, 62, 80) 20%, rgb(90, 132, 172) 100%);
    padding-top: clamp(56px, calc(5.8vw + 0.2rem),140px);
    padding-bottom: clamp(32px, calc(2.9vw + 0.5rem),140px);
}

.newBtn{
    text-transform: none !important;
    color: #ffff !important;
    background: rgba(83, 172, 255, 1) !important;
    transition: all 0.4s !important;
    font-family: "Poppins", sans-serif !important;
}
.newBtn:active{
    background-color:rgba(83, 172, 255, 1) !important ;
}
.newBtn:hover{
    box-shadow: inset 0px -20px 30px -10px #165b9c;
}

.contactBtn{
    padding: 0.2rem 1.5rem !important;
    font-weight: 600 !important;
    font-size: clamp(12px, calc( 0.3vw + 0.5rem), 60px);
}


@media only screen and (max-width: 676px){
    .contactBg{
        padding: 50px 0;
    }
    .descPart h5{
        line-height: 0.94rem;
    }
    .descPart h1{
        line-height: 2.25rem;
    }
    .descPart p, .descPart span{
        line-height: 1.125rem;
    }

    .descPart h3{
        line-height: 1.9rem;
        padding-bottom: 0.5rem;
    }
    .descPart h4{
        line-height: 1.3125rem;
    }
}