.oldBlogs p{
    font-weight: 400;
    font-size: clamp(10px, calc( 0.6vw + 0.1rem), 60px);
    color: #53ACFF;
}
.oldBlogs h6{
    font-weight: 400;
    font-size:1.189rem ;
}
.minPrt p{
    font-size: clamp(10px, calc( 0.6vw + 0.1rem), 60px);
}
.blogTitle h6{
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 60px);
}
@media only screen and (max-width:676px) {
.oldBlogs p{
    font-size: 0.625rem;
    line-height: 0.94rem;
    font-weight: 400;
}
.oldBlogs h6 {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-weight: 400;
}
}