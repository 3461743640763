section.navbar {
    box-shadow: 0px 21px 27.700000762939453px 0px rgba(0, 0, 0, 0.59);
    background: #1F2021;
}
.ulSection{
    display: flex;
    flex-direction: column;
    background-color: #1F2021;
    overflow: hidden;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-shadow: 0px 30px 20px -10px rgba(0,0,0,0.45) inset;
    gap: 0;
}
.loginPerson{
    padding-right: 12px;
}
.ulSection li{
    display: block;
}
.ulSection li a{
    padding: 15px 60px;
    color: #fff;
    font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
    transition: all 0.3s;
}
.ulSection li a:hover{
    font-weight: 500;
    background: #53acff;
}
.userOptions{
    position: absolute;
    top: 105%;
    right: 1%;
}

section.navbar .overlay{
    position: absolute;
    top: 100%;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: #00000073;
    z-index: 9999;
    overflow: hidden;
    transition: all 0.3s;
}

nav {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding:  clamp(10px,  calc( 0.5vw + 0.1rem ), 80px) clamp(32px,  calc( 1.8vw + 0.1rem ), 140px);
    width: 100%;
}

nav .bars {
    height: 21px;
    padding: 2px 0;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

nav .bars .bar {
    width: 27px;
    height: 3px;
    background-color: #fff;
    position: relative;
    transition: all 0.3s;
}

nav .bars .bar::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: -7px;
    left: 0;
    transition: all 0.3s;
}

nav .bars .bar::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    bottom: -7px;
    left: 0;
    transition: all 0.3s;
}

nav .bars.open .bar {
    background-color: transparent;
}

nav .bars.open .bar::before {
    top: 0;
    transform: rotate(45deg);
}

nav .bars.open .bar::after {
    bottom: 0;
    transform: rotate(-45deg);
}

nav .logo {
    width: clamp(85px,  calc( 5vw + 0.1rem ), 300px);
}
nav .logo  img{
    width: 100%;
}

nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2rem;
}


nav ul li a {
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: clamp(10px,  calc( 0.7vw + 0.1rem ), 60px);
    padding:  clamp(10px,  calc( 0.3vw + 0.1rem ), 80px) clamp(32px,  calc( 1.8vw + 0.1rem ), 140px);
    border-radius: 4px;
    font-weight: 300;
    transition: all 0.3s;

}

nav ul li a:hover, nav .navLinks li a.active:hover {
    font-weight: 600;
}

nav .navLinks li a.active {
    font-weight: 600;
}

nav .AuthButtons a.signUp {
    outline: none;
    color: #fff;
    background: transparent;
    border: none;
    padding: clamp(10px,  calc( 0.3vw + 0.1rem ), 80px) clamp(32px,  calc( 1.8vw + 0.1rem ), 140px);
    background-color: var(--Blue300);
    border-radius: 4px;
    font-size:clamp(10px,  calc( 0.7vw + 0.1rem ), 60px);
    transition: all 0.3s;
}
nav .AuthButtons a.signUp:hover {
    box-shadow: inset 0px -20px 30px -10px #165B9C;
}

nav .AuthButtons a.logIn {
    outline: none;
    color: #fff;
    background: transparent;
    border: none;
    padding: clamp(10px,  calc( 0.3vw + 0.1rem ), 80px) clamp(32px,  calc( 1.8vw + 0.1rem ), 140px);
    border: 1px solid #fff;
    border-radius: 4px;
    font-size:clamp(10px,  calc( 0.7vw + 0.1rem ), 60px);
    transition: all 0.3s;
}

nav .AuthButtons a.logIn:hover {
    background: #53acff;
    border: 1px solid #53acff;
}

nav .navLinks.first{
    background-color: #53acff98;
    border-radius: 5px;
    overflow: hidden;
}

nav .navLinks.first a{
    border-radius: 0;
}

@media only screen and (max-width: 1024px) {

    nav {
        padding: 0.75rem;
    }
    nav .logo{
        width: 85px;
    }

    nav .mobileScreenNav {
            display: flex ;
            flex-direction: column ;
            align-items: flex-start;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background: #1F2021;
            padding: 1rem;
            box-shadow: inset 0px 8px 17px -5px #00000087;
            z-index: -1;
            transform: translateY(-100%);
            visibility: hidden;
            /* transition: all 0.3s; */
    }

    nav .mobileScreenNav.open{
        z-index: 99999;
        transform: translateY(0);
        visibility: visible;
    }

    nav .mobileScreenNav .navLinks{
        flex-direction: column;
        align-items: flex-start;
    }

    nav .mobileScreenNav .navLinks.first {
        background-color: transparent;
        align-items: flex-start;
    }

    nav .mobileScreenNav .navLinks a {
      font-size: 14px;
  }

    nav .mobileScreenNav .navLinks.first a {
        background-color: #53acff98;
        border-radius: 5px;
    }

    nav .mobileScreenNav .navLinks.first a:hover {
        background-color: var(--Blue300);
    }

}
