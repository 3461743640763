.ourName h1{
    font-size: clamp(24px, calc(3.6vw + 0.5rem),140px);
    font-weight: 700;

}
.descParagraph p{
    font-size:  clamp(10px, calc( 0.8vw + 0.1rem), 60px) ;
    font-weight: 400;

}

.winnerAndLoserBorder::before{
    content: "";
    background-image: conic-gradient(transparent 0deg ,#53acff 120deg,transparent 220deg );
    width: 250%;
    height: 250%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%) rotate(270deg);
    box-sizing: border-box;
    position: absolute;
    transition:all 0.5s
  }
  .winnerAndLoserBorder:hover::before{
    transform:translate(-50%,-50%) rotate(0deg);
  }
.tabsSec{
  overflow-x: auto;
  width: 100%;
}
  @media only screen and (max-width: 676px){
    .ourName h1{
        line-height: 2.25rem;
        font-weight: 700;

    }
    .descParagraph p{
        line-height: 0.94rem;
        font-weight: 400;
    }
    
  }