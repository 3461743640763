section.commingSoon {
    padding: 5% 0 0;
    border-top: 1px solid #5D5D5D;
    position: relative;
    background-image: url("../../../assets/PlansBgShadow.png");
    background-position: right top;
    background-size: contain;
    background-repeat: no-repeat;
    /* margin-top: 0 !important; */
}

.commingSoon-container > p {
    font-family: Poppins;
    font-size: clamp(11px, calc(1.2vw + 0.1rem), 100px);
    text-align: left;
    margin-bottom: clamp(3rem, calc(2.2vw + 0.1rem), 500px);
    width: 70%;
}

.expanding-box{
    padding: clamp(3rem, calc(3vw + 0.1rem), 500px) clamp(2rem, calc(2vw + 0.1rem), 400px);
    background-image: url("../../../assets/expandingBg.png");
    background-position: center;
    background-size: cover;
    border-radius: 21px;
}

.expanding-box .item{
    padding-left: clamp(1rem, calc(2vw + 0.1rem), 500px);
    border-left: 2px solid #fff;
}

.expanding-box .item h6{
font-family: Poppins;
font-size:  clamp(14px, calc(2.8vw + 0.1rem), 600px);
font-weight: 700;
letter-spacing: 0em;
text-align: left;
margin-bottom: clamp(1rem, calc(1.2vw + 0.1rem), 500px);
}

.expanding-box .item p{
font-family: Poppins;
font-size:  clamp(10px, calc(1vw + 0.1rem), 60px);
font-weight: 400;
letter-spacing: 0em;
text-align: left;
}


@media only screen and (max-width: 1024px) {
    /* section.commingSoon {
        margin-top: 20vw !important;

    } */
    .commingSoon-container > p {
        margin: 1.5rem 0;
        width: 100%;
    }
    .expanding-box {
        padding: 2rem 1.5rem;
    }
}

