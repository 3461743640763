.aboutUs-wrapper {
    background: linear-gradient(0deg, rgba(83, 172, 255, 0.342) 0%, rgba(34, 36, 39, 0) 30%);
    width: 100%;
    overflow: hidden;
}

.aboutUs-card {
    position: absolute;
    top: 55%;
    right: 4rem;
    transform: translateY(-50%);
    width: 300px;
    background: rgba(32, 32, 32, 1);
    box-shadow: 0px 0px 11.800000190734863px 0px rgba(0, 0, 0, 0.43) inset;
    border-radius: 15px;
}

.aboutUs-wrapper .aboutUs-card .aboutUs-card-body {
    padding: 2.5rem 1.5rem 1.5rem;
}

.aboutUs-card .aboutUs-card-body p {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}
.blured {
    backdrop-filter: blur(10px);
}
.aboutUs-card .aboutUs-card-body span {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(151, 151, 151, 1);
}

.aboutUs-card .aboutUs-card-body h6 {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 1rem;
}

.aboutUs-card .aboutUs-card-body svg {
    transform: rotateX(180deg);
}

.aboutUs-wrapper .aboutUs-card .aboutUs-card-body h4 {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 1.5rem;
}

.aboutUs-wrapper .aboutUs-card .aboutUs-card-body h4>span {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0.3rem;
    background: var(--Blue300);
    color: #fff;
    border-radius: 10px;
}

.aboutUs-wrapper .aboutUs-card .aboutUs-card-body .collapse-item {
    box-shadow: -2px 6px 6.300000190734863px 0px rgba(0, 0, 0, 0.44);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.aboutUs-wrapper .aboutUs-card .aboutUs-card-body .collapse-item .collapse-header {
    font-family: Poppins;
    font-size: var(--13px);
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0.5rem 1rem;
    background: var(--Blue300);
    border-radius: 5px;
    cursor: pointer;
}

.aboutUs-wrapper .aboutUs-card .aboutUs-card-body .collapse-item .collapse-body {
    display: none;
    padding: 1.5rem 1rem 1rem;
    border-radius: 0 0 10px 10px;
    transform: translateY(-0.5rem);
}

.aboutUs-wrapper .aboutUs-card .aboutUs-card-body .collapse-item .collapse-body:first {
    display: block;
}

.aboutUs-wrapper .aboutUs-card .aboutUs-card-body .collapse-item .collapse-body p {

    font-family: Poppins;
    font-size: var(--11px);
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.st-suit-logo {
    margin-bottom: 1rem;
}

.st-suit-logo img {
    width: 50px;
}

.st-suit-logo h5 {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
}


@media only screen and (max-width: 1024px) {

    .aboutUs-card {
        position: absolute;
        top: 70%;
        right: 2rem;
        transform: translateY(0%);
    }

}