.SearchBar {
  display: flex;
  gap: 5rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.SearchBar h2 {
  position: relative;
  font-size: clamp(22px, calc( 2.2vw + 0.5rem ) ,140px);
  font-weight: 700;
  letter-spacing: 0px;
  padding-left: 1rem;
  color: #fff;
}

.SearchBar h2::before {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #53ACFF;
  border-radius: 5px;
}

.notification_searchInput {
  align-items: center;
  justify-content: center;
  width: 80%;
}

.searchInput {
  position: relative;
  width: 100%;
}

.searchInput input {
  width: 100%;
  height: clamp(10px,  calc( 1.7vw + 0.5rem ), 60px);
  background-color: #313131;
  border: 1px solid #313131;
  border-radius: 5px;
  color: #ffff;
  font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  padding: 10px 20px;
  outline: none;
  transition: all 0.3s;
}

.searchInput input::placeholder {
  color: #ffff;
  font-size: clamp(10px,  calc( 0.4vw + 0.5rem ), 60px);
  font-weight: 400;
  letter-spacing: 0px;
}

.searchInput input:focus {
  border: 1px solid #53ACFF;
}

.searchInput img {
  position: absolute;
  top: 50%;
  right: 1rem;
  width: clamp(12px, calc(0.6vw + 0.5rem), 70px);
  height: clamp(12px, calc(0.6vw + 0.5rem), 70px);
  transform: translateY(-50%);
}

.navBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navBar ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2rem;
}

.navBar ul li {
  display: block;
  border-radius: 5px;
}

.navBar a {
  font-size: clamp(12px, calc(0.9vw + 0.1rem), 70px);
  font-weight: 400;
  padding: 7px;
  border-radius: 5px;
  transition: all 0.3s;
}


.navBar a.active {
  color: #fff;
  background-color: #53ACFF;
}


.navBar a.tradingHistory {
  text-wrap: nowrap;
  background-color: #4d4d4d;
  padding: 7px 1.5rem;
}

.navBar a.tradingHistory:hover {
  background-color: #53ACFF;
  color: #fff;
}

.SearchBar .searchBtn {
  padding: 0.6rem 2rem;
  font-size: clamp(10px, calc(0.4vw + 0.5rem), 60px);
  border-radius: 5px;
  background-color: #53ACFF;
  color: #fff;
  outline: none;
  border: none;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

}

.SearchBar .searchBtn:hover {
  box-shadow: inset 0px -20px 30px -10px #165B9C;
}

.bars {
  height: 21px;
  padding: 2px 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.bars .bar {
  width: 27px;
  height: 3px;
  background-color: #fff;
  position: relative;
  transition: all 0.3s;
}

.bars .bar::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: -7px;
  left: 0;
  transition: all 0.3s;
}

.bars .bar::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  bottom: -7px;
  left: 0;
  transition: all 0.3s;
}

.bars.open .bar {
  background-color: transparent;
}

.bars.open .bar::before {
  top: 0;
  transform: rotate(45deg);
}

.bars.open .bar::after {
  bottom: 0;
  transform: rotate(-45deg);
}


.toggleFilters {
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  text-align: center;
  color: #fff;
  padding: 0.5rem 0;
  width: 80px;
  background-color: #53ACFF !important;
  border-radius: 6px;
  margin-left: 0.5rem;
  border: none;
  outline: 0;
  cursor: pointer;
  transition: all 0.3s;
}



@media only screen and (max-width: 1024px) {
  .SearchBar {
    gap: 0.5rem;
    padding: 1rem 0 0;
    margin-bottom: 1rem;
    position: relative;
  }

  .SearchBar h2 {
    width: 80%;
    font-size: 24px;
    padding-left: 1rem;
  }


  .notification_searchInput {
    width: auto;
  }

  .searchInput input {
    width: 100%;
    height: 30px;
    color: #ffff;
    font-size: 10px;
    line-height: 14px;

  }

  .searchInput input::placeholder {
    font-size: 10px;
    line-height: 14px;
  }

  .SearchBar .navBar ul{
    position: absolute;
    top: 0;
    left: -16px;
    width: 100%;
    background: #202020;
    width: calc(100% - 1rem);
    z-index: 9999;
    padding: 1rem;
    gap: 1rem;
  }

}