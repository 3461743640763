.leaderBoard-wrapper {
    background: linear-gradient(0deg, rgba(83, 172, 255, 0.3) 0%, rgba(32, 32, 32, 0.5) 100%);
}

.tableItem.border-animate::before {
    background-image:conic-gradient(transparent 180deg, #53acff7c 240deg, transparent 360deg) ;
}
.leaderBoard-wrapper .hero {
    background-image: unset;
}
.listItem.first-item {
    background: rgb(61,113,160);
background: linear-gradient(90deg, rgba(61,113,160,1) 0%, rgba(59,106,150,1) 100%);

}
.listItem.second-item {
    background: rgb(38,49,59);
background: linear-gradient(90deg, rgba(38,49,59,1) 0%, rgba(37,48,58,1) 100%);

}
.listItem.third-item {
    background: rgb(38,49,59);
background: linear-gradient(90deg, rgba(38,49,59,1) 0%, rgba(37,48,58,1) 100%);

}
.ranking-tabs {
    background: linear-gradient(336deg, rgba(34, 36, 38, 1) 84%, rgba(37, 103, 168, 0.6166841736694677) 100%);
    overflow: hidden;
}

.taps-container {
    border-radius: clamp(10px, calc(0.5vw + 0.1rem), 600px) clamp(10px, calc(0.5vw + 0.1rem), 600px) 0 0;
    overflow: hidden;
}

.leaderBoard-wrapper .ranking-tabs .tabs-nav {
    height: clamp(9vh, 10vh, 200px);
background-color: #252c33;

    align-items: end;
}

.leaderBoard-wrapper .ranking-tabs .tabs-nav li {
    background: linear-gradient(130deg, #22272b 24%, #202020 100%);
    border-bottom: clamp(1px, calc(0.02vw + 0.1rem), 2px) solid rgba(83, 172, 255, 1);
    width: 100%;
    height: clamp(40px, calc(3.5vw + 0.1rem), 3000px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: font-size 0.3s;
    border-top-right-radius: var(--12px) !important;
    border-top-left-radius: var(--12px) !important;
}


.leaderBoard-wrapper .ranking-tabs .tabs-nav li a {
    color: rgba(164, 164, 164, 1);
    text-decoration: none;
    width: 100%;
    height: 100%;
    font-size: var(--13px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: clamp(16px, calc(1vw + 0.1rem), 2000px) clamp(8px, calc(0.6vw + 0.1rem), 2000px);
    border-top-right-radius: var(--12px) !important;
    border-top-left-radius: var(--12px) !important;
}

.leaderBoard-wrapper .ranking-tabs .tabs-nav li.selected:nth-child(1) {
    border-radius: clamp(10px, calc(0.5vw + 0.1rem), 600px) 0 0 0;

}

.leaderBoard-wrapper .ranking-tabs .tabs-nav li.selected:nth-child(3) {
    border-radius: 0 clamp(10px, calc(0.5vw + 0.1rem), 600px) 0 0;

}

.leaderBoard-wrapper .ranking-tabs .tabs-nav li.not-selected {
    font-size: clamp(10px, calc(0.9vw + 0.1rem), 600px);
    color: rgba(164, 164, 164, 1);
    text-align: center;

}

.leaderBoard-wrapper .ranking-tabs .tabs-nav li:first-child {
    border-radius: clamp(10px, calc(0.5vw + 0.1rem), 600px) 0 0 0;
}

.leaderBoard-wrapper .ranking-tabs .tabs-nav li.selected {
    border: clamp(1px, calc(0.02vw + 0.1rem), 2px) solid rgba(83, 172, 255, 1);
    border-bottom: 0;
    font-family: Poppins;
    font-size: clamp(12px, calc(1.2vw + 0.1rem), 700px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    background: linear-gradient(130deg, #22272b 24%, #202020 100%);
    outline: 0;
    height: clamp(9vh, 10vh, 200px);



}

.leaderBoard-wrapper .ranking-tabs .tabs-nav li.selected a {
    color: #fff;
    font-size: var(--23px);
}

.leaderBoard-wrapper .tabsWrapper {
    border: clamp(1px, calc(0.02vw + 0.1rem), 2px) solid rgba(83, 172, 255, 1);
    border-top: 0;
    border-radius: 0 0 clamp(10px, calc(0.5vw + 0.1rem), 600px) clamp(10px, calc(0.5vw + 0.1rem), 600px);
}
.leaderBoard-wrapper .tabs-content {
    padding: clamp(16px, calc(2vw + 0.1rem), 1000px);
}

.leaderBoard-wrapper .collapse-item {
    margin-bottom: clamp(12px, calc(1.2vw + 0.1rem), 600px);
}

.leaderBoard-wrapper .collapse-item .collapse-header {
    display: flex;
    align-items: center;
    padding: clamp(12px, calc(1.2vw + 0.1rem), 600px) clamp(20px, calc(2vw + 0.1rem), 1000px);
    border-radius: clamp(12px, calc(0.6vw + 0.1rem), 600px);
    box-shadow: 0px 18.83098602294922px 29.591548919677734px 0px #00000040;
    background: rgba(65, 64, 64, 1);
    position: relative;
    z-index: 1;
    gap: clamp(16px, calc(3vw + 0.1rem), 2000px);
    position: relative;
}


.leaderBoard-wrapper .collapse-item .collapse-header .padge {
    width: clamp(50px, calc(5.6vw + 0.1rem), 3000px);
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(17%, -10%);
}

.leaderBoard-wrapper .collapse-item .collapse-header span {
    font-family: Poppins;
    font-size: clamp(12px, calc(1.2vw + 0.1rem), 700px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    color:#5D5D5D    ;
}

.leaderBoard-wrapper .collapse-item:nth-child(1) .collapse-header span {
    color:#53ACFF    ;
}
.leaderBoard-wrapper .collapse-item:nth-child(2) .collapse-header span {
    color: #295780 ;
}

.leaderBoard-wrapper .collapse-item:nth-child(3) .collapse-header span {
    color: #364452    ;
}

.leaderBoard-wrapper .collapse-item:nth-child(1) .collapse-header, .leaderBoard-wrapper .collapse-item:nth-child(2) .collapse-header, .leaderBoard-wrapper .collapse-item:nth-child(3) .collapse-header {
    background-color: var(--Blue300);
}

.leaderBoard-wrapper .collapse-item .collapse-header p {
    font-family: Poppins;
    font-size: clamp(12px, calc(1.2vw + 0.1rem), 700px);
    font-weight: 400;
    letter-spacing: 0em;
}

.leaderBoard-wrapper .collapse-item .collapse-header svg {
    width: clamp(12px, calc(0.8vw + 0.5rem), 700px);
    ;
    height: clamp(12px, calc(0.8vw + 0.5rem), 700px);
    ;
    transform: rotateX(180deg);
    opacity: 0.6;
    transition: all 0.3s;
}

.leaderBoard-wrapper .collapse-item .collapse-header.open svg {
    transform: rotateX(0deg);
    opacity: 1;
}

.leaderBoard-wrapper .collapse-item .collapse-body {
    width: 100%;
    background-color: var(--Blue300);
    transform: translateY(-22px);
    background: #2F2F2F;
    border-radius: 0 0 21px 21px;
    padding: clamp(24px, calc(2vw + 0.1rem), 1000px) clamp(16px, calc(2vw + 0.1rem), 1000px) clamp(16px, calc(1.5vw + 0.1rem), 1000px);
}

.leaderBoard-wrapper .collapse-item .collapse-body p {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 600px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;


}


.ranking-container .avatar img {
    width: clamp(30px, calc(2vw + 0.5rem), 2000px);
    height: clamp(30px, calc(2vw + 0.5rem), 2000px);
    margin-right: clamp(5px, calc(0.4vw + 0.1rem), 800px);
}
.tableItem img {
    width: clamp(30px, calc(2.2vw + 0.5rem), 2000px) !important;
    height: clamp(30px, calc(2.2vw + 0.5rem), 2000px) !important;
    margin-right: clamp(5px, calc(0.4vw + 0.1rem), 800px);
}

.ranking-container .avatar .user-info h6 {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 800px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.ranking-container .avatar .user-info p {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.7vw + 0.1rem), 600px);
    ;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;

}



.trader-types {
    padding: clamp(10px, calc(1.2vw + 0.1rem), 2000px);
    box-shadow: 0px 18.88236427307129px 29.67228889465332px 0px rgba(0, 0, 0, 0.25);
    border: 0.9px solid rgba(93, 93, 93, 1);
    border-radius: clamp(10px, calc(0.5vw + 0.1rem), 600px);
    background: rgba(32, 32, 32, 1);
}

.trader-types h6 {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.9vw + 0.1rem), 600px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: clamp(10px, calc(1.2vw + 0.1rem), 600px);
}

.trader-types .check-trade-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: clamp(10px, calc(1vw + 0.1rem), 800px);
}

.trader-types .check-trade-time label {
    background: transparent;
    border-radius: 5px;
    color: #fff;
    position: relative;
    font-family: Poppins;
    font-size: clamp(10px, calc(0.7vw + 0.1rem), 700px);
    font-weight: 400;
    padding: var(--sy-4px) var(--15px);
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s;
}

.trader-types .check-trade-time input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
}

.trader-types .check-trade-time label:has(> input[type="radio"]:checked) {
    color: #fff;
    background-color: var(--Blue300);
    font-family: Poppins;
    font-size: clamp(10px, calc(0.7vw + 0.1rem), 700px);
    font-weight: 700;
    letter-spacing: 0em;

}



.trader-types .check-trade-during {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: clamp(10px, calc(1vw + 0.1rem), 800px);
    border-top: 0.9px solid rgba(93, 93, 93, 1);
}

.trader-types .check-trade-during label {
    background: transparent;
    border-radius: 5px;
    color: #fff;
    position: relative;
    font-family: Poppins;
    font-size: clamp(10px, calc(0.7vw + 0.1rem), 700px);
    font-weight: 400;
    padding: var(--sy-2px) var(--16px);
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s;
}
.trader-types .check-trade-during label:hover{
    background-color: #294864;
}

.trader-types .check-trade-during input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
}

.trader-types .check-trade-during label:has(> input[type="radio"]:checked) {
    color: #fff;
    background-color: var(--Blue300);
    font-family: Poppins;
    font-size: clamp(10px, calc(0.7vw + 0.1rem), 700px);
    font-weight: 700;
    letter-spacing: 0em;

}

.trader-types .leaderBoard-guid {
    padding-top: clamp(15px, calc(1.5vw + 0.1rem), 600px);
    margin-top: clamp(15px, calc(1.5vw + 0.1rem), 600px);
    border-top: 0.9px solid rgba(93, 93, 93, 1);
    display: none;
}

.trader-types .leaderBoard-guid h5 {
    font-family: Poppins;
    font-size: clamp(15px, calc(1.2vw + 0.1rem), 600px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: clamp(8px, calc(0.6vw + 0.1rem), 600px);

}

.trader-types .leaderBoard-guid p {
    font-family: Poppins;
    font-size: clamp(8px, calc(0.6vw + 0.1rem), 600px);
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: clamp(8px, calc(1vw + 0.1rem), 600px);

}

.trader-types .check-leaderBoard-guid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: clamp(8px, calc(0.6vw + 0.1rem), 600px);
}
.trader-types-wrapper::before{
    background-image: conic-gradient(transparent 180deg, transparent 240deg, #FFFFFFBB 280deg, transparent 360deg) !important;
}
.featuredStories::before{
    background-image: conic-gradient(transparent 180deg, transparent 240deg, #FFFFFFBB 280deg, transparent 360deg) !important;
}
.ConnectYourBrokerSec::before{
    background-image: conic-gradient(transparent 180deg, transparent 240deg, #FFFFFFBB 280deg, transparent 360deg) !important;
}
.trader-types .check-leaderBoard-guid label {
    width: 100%;
    padding: clamp(8px, calc(0.6vw + 0.1rem), 600px) clamp(8px, calc(1vw + 0.1rem), 600px);
    ;
    background: transparent;
    border-radius: clamp(5px, calc(0.2vw + 0.1rem), 200px);
    color: #fff;
    position: relative;
    font-family: Poppins;
    font-size: clamp(8px, calc(0.7vw + 0.1rem), 600px);
    ;
    font-weight: 400;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 5px 6.599999904632568px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.6);
    transition: all 0.1s;
}
.trader-types .check-leaderBoard-guid label:hover {
    background-color: #294864;
}
.trader-types .check-leaderBoard-guid input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
}

.trader-types .check-leaderBoard-guid label:has(> input[type="radio"]:checked) {
    color: #fff;
    background-color: var(--Blue300);
    border: 1px solid var(--Blue300);
    font-weight: 700;

}


.ConnectYourBroker {
    padding: clamp(15px, calc(1.5vw + 0.1rem), 600px);
    box-shadow: 0px 18.88236427307129px 29.67228889465332px 0px rgba(0, 0, 0, 0.25);
    border: 0.9px solid rgba(93, 93, 93, 1);
    border-radius: clamp(10px, calc(0.5vw + 0.1rem), 600px);
    background: rgba(32, 32, 32, 1);

}

.ConnectYourBroker .brokers {
    gap: clamp(15px, calc(1vw + 0.1rem), 600px);
    margin: clamp(15px, calc(1.5vw + 0.1rem), 600px) 0;
}

.ConnectYourBroker .brokers .gradient-border {
    border-radius: clamp(5px, calc(0.3vw + 0.1rem), 200px);
    overflow: hidden;
}

.ConnectYourBroker .brokers .broker-box {
    width: 100%;
    background: rgba(32, 32, 32, 1);
    border-radius: 5px;
    padding: clamp(5px, calc(0.3vw + 0.1rem), 200px);
    cursor: pointer;
}

.ConnectYourBroker .brokers .broker-box img {
    width: 100%;
}

.ConnectYourBroker h6 {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 600px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;

}

.ConnectYourBroker p {
    font-family: Poppins;
    font-size: clamp(8px, calc(0.7vw + 0.1rem), 600px);
    letter-spacing: 0em;
    text-align: center;

}

.ConnectYourBroker p>a {
    color: var(--Blue300);
    font-weight: 700;
}

.featured-stories {
    padding: clamp(16px, calc(1.5vw + 0.1rem), 700px);
    box-shadow: 0px 18.88236427307129px 29.67228889465332px 0px rgba(0, 0, 0, 0.25);
    border: 0.9px solid rgba(93, 93, 93, 1);
    border-radius: clamp(10px, calc(0.5vw + 0.1rem), 600px);
    background: rgba(32, 32, 32, 1);

}

.featured-stories h5 {
    font-family: Poppins;
    font-size: clamp(12px, calc(1.1vw + 0.1rem), 700px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: clamp(12px, calc(1vw + 0.1rem), 700px);
}

.featured-stories .story {
    background: rgba(93, 93, 93, 0.16);
    padding: clamp(10px, calc(1vw + 0.1rem), 700px);
    border-radius: clamp(15px, calc(0.8vw + 0.1rem), 600px);
    margin-bottom: clamp(10px, calc(1vw + 0.1rem), 700px);
}

.featured-stories .story p {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.7vw + 0.1rem), 600px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
}

.featured-stories .story a {
    color: var(--Blue300);
    font-family: Poppins;
    font-size: clamp(8px, calc(0.6vw + 0.1rem), 60px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: right;
    display: block;
    margin-top: clamp(10px, calc(1vw + 0.1rem), 600px);
}

.featured-stories .story .avatar {
    margin-bottom: clamp(10px, calc(0.75vw + 0.1rem), 600px);
    gap: clamp(10px, calc(0.75vw + 0.1rem), 600px);
}

.featured-stories .story .avatar img {
    width: clamp(22px, calc(2.5vw + 0.1rem), 1400px);
    height: clamp(22px, calc(2.5vw + 0.1rem), 1400px);
}

.featured-stories .story .avatar .user-info h6 {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.75vw + 0.1rem), 600px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.featured-stories .story .avatar .user-info p {
    font-family: Poppins;
    font-size: clamp(8px, calc(0.65vw + 0.1rem), 600px);
    font-weight: 300;
    letter-spacing: 0px;
    text-align: left;

}

.leaderBoard-wrapper .Ranking-tap .collapse-item {
    box-shadow: 0px 23.89561653137207px 37.550254821777344px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: clamp(16px, calc(0.8vw + 0.1rem), 60px);
}

.leaderBoard-wrapper .Ranking-tap .collapse-item .collapse-header {
    background-color: #202020;
    border-radius: var(--12px);
}
.leaderBoard-wrapper .Ranking-tap .collapse-item .collapse-header:hover {
   background: linear-gradient(98.09deg, #1D5182 -96.22%, rgba(32, 32, 32, 0.77) 94.23%);
}

.leaderBoard-wrapper .Ranking-tap .collapse-item .collapse-header {
    padding: clamp(12px, calc(1.2vw + 0.1rem), 600px) clamp(20px, calc(2vw + 0.1rem), 1000px);
    gap: clamp(16px, calc(1.5vw + 0.1rem), 700px);
}

.leaderBoard-wrapper .Ranking-tap .collapse-item .collapse-header .rank-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding-right: clamp(16px, calc(2vw + 0.1rem), 700px);
}

.Ranking-tap .collapse-item .collapse-header .rank-details p {
    font-family: Poppins;
    font-size: clamp(8px, calc(0.7vw + 0.1rem), 600px);
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;

}

.leaderBoard-wrapper .Ranking-tap .collapse-item .collapse-header .rank-details p svg {
    transform: rotateX(0deg);
}

.Ranking-tap .collapse-item .collapse-header .avatar .user-info p {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.7vw + 0.1rem), 600px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(93, 93, 93, 1);
}

.interactiveBroker-modal .modal-body {
    background-color: #202020;
    padding: clamp(16px, calc(2vw + 0.1rem), 700px);
}

.interactiveBroker-modal .modal-body .modal-header {
    padding: 0 clamp(10px, calc(1vw + 0.1rem), 700px);
    gap: clamp(10px, calc(0.9vw + 0.1rem), 700px);
}

.interactiveBroker-modal .modal-body .modal-header img {
    width: 30%;
}

.interactiveBroker-modal .modal-body .modal-header h4 {
    font-family: Poppins;
    font-size: clamp(16px, calc(1.2vw + 0.1rem), 700px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.interactiveBroker-modal .modal-body .modal-header .broker-info {
    gap: clamp(10px, calc(0.4vw + 0.1rem), 700px);
}

.interactiveBroker-modal .modal-body .modal-header span {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.9vw + 0.1rem), 700px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(164, 164, 164, 1);

}

.interactiveBroker-modal .modal-body .modal-header+p {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.9vw + 0.1rem), 700px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    padding: clamp(10px, calc(1vw + 0.1rem), 700px);

}

.interactiveBroker-modal .modal-body .modal-header+p>a {
    color: var(--Blue300);
    border: none;
    outline: none;
}

.interactiveBroker-modal .modal-body .line {
    width: 100%;
    height: 1px;
    background: rgba(151, 151, 151, 1);
    margin: clamp(10px, calc(1vw + 0.1rem), 700px) 0;
}


.interactiveBroker-modal .modal-body .input-box {
    padding: clamp(10px, calc(1vw + 0.1rem), 700px) clamp(10px, calc(1vw + 0.1rem), 700px) 0 clamp(10px, calc(1vw + 0.1rem), 700px);
}

.interactiveBroker-modal .modal-body .input-box label {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.9vw + 0.1rem), 700px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(151, 151, 151, 1);
    margin-bottom: clamp(10px, calc(0.6vw + 0.1rem), 700px);
}

.interactiveBroker-modal .modal-body .input-box input {
    background: rgba(65, 64, 64, 1);
    padding: clamp(8px, calc(0.5vw + 0.1rem), 700px) clamp(10px, calc(1vw + 0.1rem), 700px);
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 700px);
    color: #fff;
    outline: none;
    border: none;
    box-shadow: none;
    border-radius: clamp(5px, calc(0.3vw + 0.1rem), 100px);
}

.interactiveBroker-modal .modal-body .form-group {
    padding: clamp(10px, calc(1vw + 0.1rem), 700px) clamp(10px, calc(1vw + 0.1rem), 700px) 0 clamp(10px, calc(1vw + 0.1rem), 700px);
}

.interactiveBroker-modal .modal-body .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.interactiveBroker-modal .modal-body .form-group label {
    position: relative;
    cursor: pointer;
    font-size: clamp(10px, calc(0.7vw + 0.1rem), 700px);
}

.interactiveBroker-modal .modal-body .form-group label::before {
    content: '';
    background-color: transparent;
    border: 1px solid rgba(151, 151, 151, 1);
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: clamp(10px, calc(0.7vw + 0.1rem), 700px);
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: clamp(5px, calc(0.4vw + 0.1rem), 700px);
    transition: all 0.2s;
}

.interactiveBroker-modal .modal-body .form-group input:checked+label::before {
    border: clamp(1px, calc(0.005vw + 0.01rem), 5px) solid rgba(83, 172, 255, 1);

}

.interactiveBroker-modal .modal-body .form-group input:checked+label::after {
    content: '';
    display: block;
    position: absolute;
    top: clamp(7px, calc(0.1vw + 0.1rem), 700px);
    left: clamp(10px, calc(0.5vw + 0.1rem), 700px);
    width: clamp(7px, calc(0.4vw + 0.1rem), 350px);
    height: clamp(14px, calc(0.9vw + 0.1rem), 700px);
    border: solid rgba(83, 172, 255, 1);
    border-width: 0 clamp(2px, calc(0.01vw + 0.1rem), 10px) clamp(2px, calc(0.01vw + 0.1rem), 10px) 0;
    transform: rotate(45deg);
    transition: all 0.2s;
}

.interactiveBroker-modal .modal-body .notic {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 700px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    padding: clamp(16px, calc(1vw + 0.1rem), 700px) clamp(10px, calc(0.9vw + 0.1rem), 700px) clamp(10px, calc(0.9vw + 0.1rem), 700px);
}

.interactiveBroker-modal .modal-body .notic>a {
    color: var(--Blue300);
}

.interactiveBroker-modal .modal-body .brokerConnect-btn {
    letter-spacing: 0em;
    text-align: left;
    padding: clamp(10px, calc(0.6vw + 0.1rem), 700px) clamp(32px, calc(3vw + 0.1rem), 700px);
    background: var(--Blue300);
    font-size: clamp(12px, calc(0.9vw + 0.1rem), 700px);
    border-radius: clamp(5px, calc(0.3vw + 0.1rem), 100px);
    color: #fff;
    margin-top: clamp(16px, calc(1vw + 0.1rem), 700px);
}

.side-trader {
    display: flex;
    flex-direction: column;
    gap: clamp(10px, calc(1vw + 0.1rem), 700px);
}

.side-trader .trader-types button.toggle-side-fiter {
    position: absolute;
    top: 86px;
    right: 100%;
    transform: translate(1px, -50%);
    background: rgba(32, 32, 32, 1);
    border: 1px solid rgba(93, 93, 93, 1);
    border-right-color: transparent;
    padding: 1rem 0.4rem;
    border-radius: 4px 0 0 4px;
}

.slick-dots {
    bottom: -15px;
}

.slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    content: '';
    text-align: center;
    background: rgba(65, 64, 64, 1);
    transition: all 0.3s;
}

.slick-dots li.slick-active button:before {
    background: var(--Blue300);
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0px 5px;
    padding: 0;
    cursor: pointer;
}

.interactiveBroker-modal .overlay {
    backdrop-filter: blur(5px);
}

@media only screen and (max-width: 1024px) {

    .ranking-tabs .tabs-nav li.selected {
        font-size: 10px;
        font-weight: 700;
        line-height: 15px;
    }

    .ranking-tabs .tabs-nav li {
        font-size: 8px;
        font-weight: 400;
        line-height: 10.08px;
        text-align: center;
    }

    .ranking-tabs .tabs-nav li a {
        padding: 0.6rem;
    }

    .leaderBoard-wrapper .tabs-content {
        padding: 1rem;
    }

    .leaderBoard-wrapper .collapse-item .collapse-header {
        padding: 0.6rem 1rem;
        gap: 1rem;
    }

    .leaderBoard-wrapper .collapse-item .collapse-header span {
        font-size: 10px;
    }

    .ranking-container .avatar .user-info h6 {
        font-size: 9px;
        font-weight: 700;
        line-height: 13.5px;
        text-align: left;
    }

    .leaderBoard-wrapper .ranking-container .avatar img {
        width: 30px;
        height: 30px;
    }

    .ranking-container .avatar .user-info p {
        font-size: 8px;
        font-weight: 400;
        line-height: 10px;
    }

    .leaderBoard-wrapper .collapse-item .collapse-body {
        padding: 2rem 1rem 1rem 1rem;
    }

    .leaderBoard-wrapper .collapse-item .collapse-body p {
        font-size: 10px;
        line-height: 15px;
    }

    .ranking-tabs .tabs-nav li.not-selected {
        font-size: 10px;
    }

    .leaderBoard-wrapper .ranking-container .Ranking-tap .avatar img {
        width: 30px;
        height: 30px;
        display: none;
    }

    .leaderBoard-wrapper .ranking-container .Ranking-tap .collapse-item .collapse-header .avatar .user-info p {
        display: none;
    }

    .Ranking-tap .collapse-item .collapse-header .rank-details p {
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
    }

    .Ranking-tap .collapse-item .collapse-header .rank-details p {
        font-size: 7px;
        font-weight: 400;
        line-height: 8.82px;
        color: rgba(188, 188, 188, 1);
    }

    .leaderBoard-wrapper .Ranking-tap .collapse-item .collapse-header {
        padding: 0.5rem 1rem;
        gap: 0.5rem;
    }

    .leaderBoard-wrapper .Ranking-tap .collapse-item .collapse-header .rank-details {
        display: flex;
        padding-right: 0rem;
        gap: 0.3rem;
    }

    .leaderBoard-wrapper .collapse-item .collapse-header svg {
        width: 11.63px;
        height: 11.67px;
    }

    .side-trader .trader-types {
        border-right: none;
        background: rgba(32, 32, 32, 1);
        position: fixed;
        top: 25%;
        right: -300px;
        z-index: 999999;
        border-radius: 10px 0 0 10px;
        max-width: 300px;
        margin-bottom: 0;
        transition: all 0.4s;
    }

    .side-trader .trader-types.open {
        right: 0px;
    }

    .trader-types .check-trade-during label {
        font-size: 10px;
    }

    .trader-types .leaderBoard-guid h5 {
        font-family: Poppins;
        font-size: 17px;
        font-weight: 700;
        line-height: 20px;
    }

    .trader-types .leaderBoard-guid p {
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
    }

    .trader-types .check-leaderBoard-guid label {
        width: 100%;
        padding: 0.5rem 1rem;
        height: 30px;
        background: transparent;
        border-radius: 5px;
        color: #fff;
        position: relative;
        font-family: Poppins;
        font-size: 11px;
    }

    .featured-stories .story .avatar img {
        width: 40px;
    }

    .featured-stories .story .avatar .user-info h6 {
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
    }

    .leaderBoard-wrapper .featured-stories {
        padding: 1rem 1rem 1.5rem;
    }

    .leaderBoard-wrapper .featured-stories h5 {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 0rem;
        padding-left: 8px;
    }

}
