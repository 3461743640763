.divForImgLg ,.divForImgSm,.divNextToTwoDivs,.divForBlackBg ,.rightBorderDiv,.lastInLeftImg,.chinaImgDiv{
    background-image: linear-gradient(180deg, rgb(35, 49, 61) 60%, rgb(41, 62, 80) 100%);
    box-shadow:0px 0px 10px 0px rgb(34, 33, 33) inset;
}

.divForImgLg{
    border-radius: 1.5rem;
}
.divForImgSm{
    border-radius: 0.6rem;
    /* height: clamp(110px, calc(10vw + 0.1rem),600px); */
    /* width: clamp(181px, calc(20vw + 0.1rem),900px); */
}
.largeHeader h1{
    font-size: clamp(20px, calc(3.7vw + 0.5rem),140px);
    font-weight: 700;
}
.headerFotTxtSec h3{
    font-size: clamp(14px, calc(2.2vw + 0.5rem),140px);
    font-weight: 700;
    padding-bottom: 0.5rem;
}
.txtSection p{
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0 0 1rem;
}
.bluecolor h6{
    font-size: clamp(10px, calc( 0.4vw + 0.5rem), 60px);
    font-weight: 400;
}

.lastExample{
    border-bottom: 0 ;
}
@media only screen and (max-width:676px) {
    .largeHeader h1{
        line-height: 1.9rem;
        font-weight: 700;
    }
    .divForImgLg{
        border-radius: 0.6rem;
    }
    .headerFotTxtSec h3{
        line-height: 1.3125rem;
        font-weight: 700;
        padding-bottom: 0.2rem;
    }
    .txtSection p{
        line-height: 1.144rem;
        font-weight: 400;
        padding: 0.6rem 0;
    }

    .bluecolor h6{
        line-height: 0.94rem;
        font-weight: 400;
    }
    .lastExample{
        border-bottom: 1px solid #979797 ;
    }

}