.AnnoncmentSlider {
    background-color: var(--Blue300);
    padding: clamp(10px, calc(0.5vw + 0.1rem), 80px) 0;
    text-align: center;
}

.AnnoncmentSlider h6 {
    color: var(--Black200);
    font-family: Poppins;
    font-size: clamp(10px, calc(1vw + 0.1rem), 80px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;

}

.AnnoncmentSlider .slick-slider .slick-list, .slick-slider .slick-track {
    padding: 0;
}