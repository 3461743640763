footer {
    background: #25252580;
    padding-top: clamp(14px, calc(4.5vw + 0.1rem), 2000px);
    position: relative;
    z-index: 0;
    box-shadow: 0px -11px 14.600000381469727px 0px rgba(0, 0, 0, 0.336);
}

footer .gradient-line {
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0;
    background: linear-gradient(144deg, rgba(153, 153, 153, 1) 0%, rgba(52, 52, 52, 1) 71%, rgba(153, 153, 153, 1) 100%);
}

footer .footer-container {
    padding: clamp(14px, calc(2.5vw + 0.1rem), 1000px);
    border-top: 1px solid #fff;
    position: relative;
}

footer .footer-container h5 {
    font-family: Poppins;
    font-size: clamp(14px, calc(1.5vw + 0.1rem), 1000px);
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #232323;
    padding: clamp(14px, calc(0.5vw + 0.1rem), 1000px) clamp(14px, calc(2.5vw + 0.1rem), 1000px);
}

footer .footer-container p {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 1000px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    width: 70%;
    margin: auto;
}

footer .footer-container .line {
    width: 50%;
    height: 1px;
    background: #5D5D5D;
    margin: clamp(20px, calc(2.5vw + 0.1rem), 1000px) auto;
}

footer .footer-container .footer-links {
    display: flex;
    justify-content: space-around;
    gap: clamp(10px, calc(0.8vw + 0.1rem), 1000px);
    margin-bottom: clamp(10px, calc(1.2vw + 0.1rem), 1000px);
}

footer .footer-container .footer-links .links-col h6 {
    font-family: Poppins;
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 1000px);
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
    margin-bottom: clamp(10px, calc(0.6vw + 0.1rem), 1000px);
}

footer .footer-container .footer-links .links-col a {
    color: #979797;
    font-family: Poppins;
    font-size: clamp(10px, calc(0.8vw + 0.1rem), 1000px);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    transition: all 0.3s;
}

footer .footer-container .footer-links .links-col a:hover {
    color: var(--Blue300);
}

footer .footer-container .footer-links .links-col ul li {
    display: block;
    margin-bottom: clamp(10px, calc(1.2vw + 0.1rem), 1000px);
}

.copyright {
    color: #979797;
}



@media only screen and (max-width: 600px) {

    footer .footer-container {
        padding: 2rem;
    }
    footer .footer-container h5 {
        font-size: 16px;
        padding: 1rem 2rem;
    }
    footer .footer-container p {
        font-size: 10px;
        line-height: 16px;
        width: 100%;
    }

    footer .footer-container .line {
        width: 50%;
        height: 1px;
        background: #5D5D5D;
        margin: 2rem auto;
    }
    footer .footer-container .footer-links{
        flex-wrap: wrap;
    }
    footer .footer-container .links-col{
        width: 45%;
    }
    footer .footer-container .footer-links .links-col ul li {
        margin-bottom: 0.7rem;
    }
    footer .footer-container .footer-links .links-col a {
        font-size: 10px;
        line-height: 10px;
    }
    footer .footer-container .footer-links .links-col h6 {
        font-size: 10px;
        line-height: 10px;
        margin-bottom: 0.6rem;
    }
}